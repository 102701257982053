import { LiveEditor, LiveParser, LiveProvider } from "@improdis/core";
import "@improdis/core/dist/core.cjs.development.css";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { useFormik } from "formik";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import staticScope from "../../../helpers/custom-tile/staticScope";
import { AppThunkDispatch } from "../../../store";
import {
  createCustomTabAsync,
  selectCustomTabComponents,
  updateCustomTabAsync,
} from "../../../store/common/customTabsSlice";
import {
  editableTabDialogClosed,
  selectEditableTab,
  tabRefreshed,
} from "../../../store/common/dialogs/editableTabDialog/editableTabSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { selectAssetsLogons } from "../../../store/main/asset-logons/assetsLogonsSlice";
import { selectTaskExecs } from "../../../store/main/task-execs/taskExecsSlice";
import { selectUtilizationExecs } from "../../../store/main/util-execs/utilExecsSlice";
import {
  AvailableAsset,
  selectAvailableFlatAssets,
} from "../../../store/user/assets-selection/assetsSelectionSlice";
import ErrorFallback, { ErrorLevel } from "../error-fallbacks/ErrorFallback";

const TitleIcon = styled(AddCircleOutlineIcon)(({ theme }) => ({
  fill: "#FFFFFF",
  width: 30,
  height: 30,
  marginRight: "0.5rem",
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  maxWidth: "8rem",
  width: "50%",
  marginTop: "0.5rem",
  "@media (min-width:1100px)": {
    paddingLeft: "2.2rem",
  },
}));

const Wrapper = styled("div")(({ theme }) => ({
  height: "100%",
  width: "100%",
  overflow: "auto",
  display: "grid",
  gridTemplate: "1fr / 1fr 1fr",
}));

const ListItemAction = styled("div")(({ theme }) => ({
  minWidth: "50%",
  width: "100%",
  marginTop: "0.5rem",
}));

const SelectContent = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginLeft: "0.2rem",
}));

const ReasonBar = styled("div")(({ theme }) => ({
  width: "0.4rem",
  height: "1.6rem",
  marginRight: "0.4rem",
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  gap: "0.5rem",
}));

interface EditableTabDialogComponentProps {}

const EditableTabDialogComponent: FunctionComponent<
  EditableTabDialogComponentProps
> = () => {
  const dispatch = useAppDispatch() as AppThunkDispatch;
  const { t } = useTranslation();
  const availableEnts = useAppSelector(selectAvailableFlatAssets);
  const customTabs = useAppSelector(selectCustomTabComponents);
  const dialog = useAppSelector(selectEditableTab);
  const utilExecs = useAppSelector(selectUtilizationExecs);
  const taskExecs = useAppSelector(selectTaskExecs);
  const [asset, setAsset] = useState<AvailableAsset>();
  const assetLogons = useAppSelector(selectAssetsLogons);
  const [code, setCode] = useState<string>(dialog.code);

  //temporary quick fix for bug with empty code
  useEffect(() => {
    setCode(dialog.code);
  }, [dialog.code]);

  useEffect(() => {
    setAsset(availableEnts[0]);
  }, [availableEnts]);

  const [initialData, setInitialValues] = useState({
    titlePl: "",
    titleEn: "",
    attrName: "",
    path: "",
  });

  useEffect(() => {
    setInitialValues({
      titlePl: dialog.titlePl,
      titleEn: dialog.titleEn,
      attrName: dialog.attrName,
      path: dialog.path,
    });
  }, [dialog]);

  const { values, handleSubmit, errors, handleChange, setValues } = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: initialData,
    validationSchema: Yup.object().shape({
      titlePl: Yup.string().required("Required"),
      attrName: Yup.string().required("Required"),
      path: Yup.string().required("Required").matches(/^[/]/),
    }),
    onSubmit: async (values, action) => {
      const tab = {
        titlePl: values.titlePl,
        titleEn: values.titleEn ?? t(values.titlePl),
        attrName: values.attrName,
        path: values.path,
        code: code,
      };
      let result;
      if (dialog.isNew) {
        result = await dispatch(createCustomTabAsync(tab));
      } else {
        result = await dispatch(updateCustomTabAsync(tab));
      }
      dispatch(tabRefreshed(true));
      if (result.meta.requestStatus === "fulfilled") {
        action.setValues({ ...initialData });
      }
    },
  });

  const handleOpenDocumentation = () => {
    var win = window.open();
    win &&
      win.document.write(
        '<iframe width="100%" height="100%" src="../custom_components_instruction.pdf" frameborder="0" allowfullscreen></iframe>'
      );
  };

  const handleCloseDialog = () => {
    dispatch(editableTabDialogClosed());
    if (dialog.isNew) {
      setValues({ ...initialData });
    }
  };

  const existingTab = useMemo(() => {
    const tab = customTabs.some(
      (e) => e.attrName === values.attrName || e.path === values.path
    );
    return tab;
  }, [customTabs, values.attrName, values.path]);

  const handleComponentCodeChange = (code: string) => {
    setCode(code);
  };

  const selectedAssetUtilExec = useMemo(
    () => asset && utilExecs.find((ue) => ue.assetId === asset.id),
    [utilExecs, asset]
  );

  const selectedAssetTaskExecs = useMemo(
    () => asset && taskExecs.filter((je) => je.assetId === asset.id),
    [taskExecs, asset]
  );

  const selectedAssetLogons = useMemo(
    () => asset && assetLogons.filter((al) => al.assetId === asset.id),
    [assetLogons, asset]
  );

  const dynamicScope = useMemo(
    () => ({
      asset: asset,
      utilExec: selectedAssetUtilExec,
      taskExecs: selectedAssetTaskExecs,
      assetLogons: selectedAssetLogons,
    }),
    [asset, selectedAssetUtilExec, selectedAssetTaskExecs, selectedAssetLogons]
  );

  return (
    <Dialog fullScreen open={dialog.open} onClose={() => {}} fullWidth>
      <DialogTitle>
        <TitleIcon />
        <div>{t("Add new tab")}</div>
      </DialogTitle>
      <List style={{ display: "flex" }} disablePadding>
        <ListItem>
          <StyledListItemIcon>{t("Title PL")}</StyledListItemIcon>
          <ListItemAction>
            <TextField
              size="small"
              variant="outlined"
              id="titlePl"
              fullWidth
              value={values.titlePl}
              onChange={handleChange}
              error={errors.titlePl !== undefined}
            />
          </ListItemAction>
        </ListItem>
        <ListItem>
          <StyledListItemIcon>{t("Title EN")}</StyledListItemIcon>
          <ListItemAction>
            <TextField
              size="small"
              variant="outlined"
              id="titleEn"
              fullWidth
              value={values.titleEn}
              onChange={handleChange}
              // disabled
            />
          </ListItemAction>
        </ListItem>
        <ListItem></ListItem>
      </List>
      <List style={{ display: "flex" }} disablePadding>
        <ListItem>
          <StyledListItemIcon>{t("ATTR NAME")}</StyledListItemIcon>
          <ListItemAction>
            <TextField
              size="small"
              variant="outlined"
              id="attrName"
              fullWidth
              helperText="AttrName => AttrName_tab"
              value={values.attrName}
              onChange={handleChange}
              error={errors.attrName !== undefined}
              disabled={!dialog.isNew}
              type="string"
            />
          </ListItemAction>
        </ListItem>
        <ListItem>
          <StyledListItemIcon>{t("URL PATH")}</StyledListItemIcon>
          <ListItemAction>
            <TextField
              size="small"
              variant="outlined"
              id="path"
              fullWidth
              helperText="Name => /name"
              value={values.path}
              onChange={handleChange}
              error={errors.path !== undefined}
              disabled={!dialog.isNew}
              type="string"
            />
          </ListItemAction>
        </ListItem>
        <ListItem>
          <StyledListItemIcon>{t("Preview Asset")}</StyledListItemIcon>
          <ListItemAction>
            <Select
              name="entId"
              size="small"
              style={{ width: "100%" }}
              variant="outlined"
              value={asset?.id === undefined ? "" : asset?.id}
              onChange={(e) => {
                setAsset(availableEnts.find((ae) => ae.id === e.target.value));
              }}
              input={<OutlinedInput />}
              disabled={availableEnts.length === 0}
            >
              {availableEnts &&
                availableEnts.map(
                  (i) =>
                    i?.id && (
                      <MenuItem key={i?.id} value={i?.id}>
                        <SelectContent>
                          <ReasonBar
                            style={{
                              backgroundColor:
                                utilExecs.find((ue) => ue.assetId === i.id)
                                  ?.utilStateColor ?? "",
                            }}
                          ></ReasonBar>
                          <Typography>{i.name && t(i.name)}</Typography>
                        </SelectContent>
                      </MenuItem>
                    )
                )}
            </Select>
          </ListItemAction>
        </ListItem>
      </List>
      <DialogContent>
        <LiveProvider
          staticScope={staticScope}
          dynamicScope={dynamicScope}
          code={dialog.code}
          onChange={handleComponentCodeChange}
          ErrorFallbackComponent={(props) => (
            <ErrorFallback {...props} level={ErrorLevel.Component} />
          )}
        >
          <Wrapper>
            <Box
              id="code-editor"
              overflow="auto"
              padding="0.5rem"
              boxSizing="border-box"
            >
              <LiveEditor />
            </Box>
            <Box
              display="grid"
              gridRow="1fr"
              overflow="auto"
              padding="0.5rem"
              boxSizing="border-box"
            >
              <LiveParser />
            </Box>
          </Wrapper>
        </LiveProvider>
      </DialogContent>
      <StyledDialogActions>
        <Button variant="contained" onClick={handleOpenDocumentation}>
          {t("Documentation")}
        </Button>
        <Tooltip
          open={existingTab && dialog.isNew}
          title={t(
            "Save Button is diabled becasue tab with such attr or path name already exists"
          )}
        >
          <div>
            <Button
              variant="contained"
              color="success"
              onClick={() => handleSubmit()}
              disabled={existingTab && dialog.isNew}
            >
              {t("Save")}
            </Button>
          </div>
        </Tooltip>
        <Button variant="contained" color="error" onClick={handleCloseDialog}>
          {t("Close")}
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
};
export default EditableTabDialogComponent;
