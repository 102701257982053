import { env } from "../../env";
import { handleExceptionMessage } from "../../helpers/handleExceptionMessage";
import { instance } from "../axiosInstance";
import { ExtensionClient } from "../httpService";

const http = new ExtensionClient(env.REACT_APP_MES_API_URL, instance);

export const executeProcedure = async <T>(
  procedureName: string,
  query?: {
    [key: string]: string;
  }
): Promise<Array<T> | undefined> => {
  try {
    const result = (await http.executeProcedure(
      procedureName,
      query
    )) as Array<T>;
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
  }
};
