import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import assetsSelectionReducer from "./assets-selection/assetsSelectionSlice";
import tabSelectionReducer from "./tab-selection/tabSelectionSlice";

export default combineReducers({
  auth: authReducer,
  selectedAssets: assetsSelectionReducer,
  selectedTab: tabSelectionReducer,
});
