import { env } from "../../env";
import { handleExceptionMessage } from "../../helpers/handleExceptionMessage";
import i18n from "../../i18n";
import MQTT, { Packet } from "mqtt/dist/mqtt";

function IsJsonString(str: string) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

export const tryGetMqttValue = (
  topicName: string,
  message: string
): string | number | undefined => {
  try {
    if (IsJsonString(message)) {
      handleExceptionMessage(
        "Topic " + topicName + " has unexpected JSON value."
      );
    } else {
      return message;
    }
  } catch (ex) {
    if (typeof ex === "string") {
      handleExceptionMessage("Topic " + topicName + ": " + ex);
    } else if (ex instanceof Error) {
      handleExceptionMessage("Topic " + topicName + ": " + ex.message);
    }
    return;
  }
};

export interface MqttService {
  connect(
    onConnect: () => void,
    onDisconnect: () => void,
    onReconnect: () => void,
    onError: (err: Error) => void,
    onMessage: (topic: string, payload: Buffer, packet: Packet) => void
  ): void;
  disconnect(): void;
  subscribe(topic: string): void;
  unsubscribe(topic: string): void;
}

export class MQTTService implements MqttService {
  public client: MQTT.MqttClient | undefined;

  connect(
    onConnect: () => void,
    onDisconnect: () => void,
    onReconnect: () => void,
    onError: (err: Error) => void,
    onMessage: (topic: string, payload: Buffer, packet: Packet) => void
  ) {
    this.client = MQTT.connect(env.REACT_APP_MQTT_URL, {
      username: env.REACT_APP_MQTT_USER,
      password: env.REACT_APP_MQTT_PASSWORD,
      reconnectPeriod: 5000,
    });

    this.client?.on("connect", onConnect);
    this.client?.on("disconnect", onDisconnect);
    this.client?.on("error", onError);
    this.client?.on("reconnect", onReconnect);
    this.client?.on("message", onMessage);
  }

  disconnect() {
    this.client && this.client.end();
  }

  subscribe(topic: string) {
    const callBack = (err: Error) => {
      if (err) {
        handleExceptionMessage(
          i18n.t("MQTT.SubscribeFailed", { topic, message: err.message }),
          "console"
        );
      }
    };
    this.client &&
      !this.client.disconnected &&
      !this.client.disconnecting &&
      this.client.subscribe(topic, callBack);
  }

  unsubscribe = (topic: string) => {
    const callBack = (err: Error) => {
      if (err) {
        handleExceptionMessage(
          i18n.t("MQTT.UnsubscribeFailed", { topic, message: err.message }),
          "console"
        );
      }
    };
    this.client &&
      !this.client.disconnected &&
      !this.client.disconnecting &&
      this.client.unsubscribe(topic, callBack);
  };

  publish = (topic: string, value: string | Buffer, retain: boolean = true) => {
    const callBack = (err: Error | undefined) => {
      if (err) {
        handleExceptionMessage(
          i18n.t("MQTT.PublishFailed", { topic, value, message: err.message }),
          "console"
        );
      }
    };
    this.client &&
      !this.client.disconnected &&
      !this.client.disconnecting &&
      this.client.publish(topic, value, { retain: retain }, callBack);
  };
}
