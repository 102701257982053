import { Drawer, styled } from "@mui/material";
import React from "react";
import SimpleBar from "simplebar-react";
import {
  assetsNavigationDialogClosed,
  selectAssetNavigationDialogOpen,
} from "../../../store/common/dialogs/assetNavigationSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import TreeViewComponent from "./TreeViewComponent";

const Container = styled(SimpleBar)(({ theme }) => ({
  maxHeight: "100%",
  minWidth: "17rem",
  maxWidth: "40rem",
  overflow: "auto",
  "& .simplebar-content": {
    width: "fit-content",
    overflow: "auto",
  },
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    backgroundColor: theme.palette.background.paper,
  },
  "& .MuiBackdrop-root": {
    marginTop: "3.125rem",
  },
}));

interface AssetsNavigationProps {}

const AssetsNavigation: React.FC<AssetsNavigationProps> = () => {
  const dispatch = useAppDispatch();
  const openAssetNavigation = useAppSelector(selectAssetNavigationDialogOpen);

  const handleClose = () => {
    dispatch(assetsNavigationDialogClosed());
  };

  return (
    <StyledDrawer
      open={openAssetNavigation}
      slotProps={{
        backdrop: { invisible: false },
      }}
      onClose={handleClose}
    >
      <Container>
        <TreeViewComponent />
      </Container>
    </StyledDrawer>
  );
};
export default AssetsNavigation;
