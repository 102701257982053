import { env } from "../../env";
import { handleExceptionMessage } from "../../helpers/handleExceptionMessage";
import { instance } from "../axiosInstance";
import { UtilStateDto, UtilizationStatesClient } from "../httpService";

const http = new UtilizationStatesClient(env.REACT_APP_MES_API_URL, instance);

export const getAllUtilStates = async (
  name?: string,
  isActive?: boolean,
  throwError: boolean = true
): Promise<Array<UtilStateDto> | undefined> => {
  try {
    const result = await http.getAllUtilStates(name, isActive);
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const getUtilStateById = async (
  utilStateId: string,
  throwError: boolean = true
): Promise<UtilStateDto | undefined> => {
  try {
    const result = await http.getUtilStateById(utilStateId);
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};
