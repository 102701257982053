import { env } from "../../env";
import { handleExceptionMessage } from "../../helpers/handleExceptionMessage";
import { instance } from "../axiosInstance";
import {
  AssetDto,
  AssetsClient,
  ChangeAssetStateRequest,
  GetAllTaskExecsQueryDto,
  GetAllUtilExecsQueryDto,
  GetNestedAssetsQueryDto,
  GetTaskExecByTaskIdQueryDto,
  GetUtilExecByAssetIdDto,
} from "../httpService";

const http = new AssetsClient(env.REACT_APP_MES_API_URL, instance);

export const getAllAssets = async (
  name: string | undefined,
  showAll: boolean | undefined,
  throwError: boolean = true
): Promise<Array<AssetDto> | undefined> => {
  try {
    const result = await http.getAllAssets(name, showAll);
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const getAssetsUtilizationExecs = async (
  throwError: boolean = true
): Promise<Array<GetAllUtilExecsQueryDto> | undefined> => {
  try {
    const result = await http.getAllUtilExecs();
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const getUtilizationExecByAsset = async (
  assetId: string,
  throwError: boolean = true
): Promise<GetUtilExecByAssetIdDto | undefined> => {
  try {
    const result = await http.getUtilExecByAssetId(assetId);
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const getAssetsTaskExecs = async (
  throwError: boolean = true
): Promise<Array<GetAllTaskExecsQueryDto> | undefined> => {
  try {
    const result = await http.getAllTaskExecs();
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const getTaskExecByTaskId = async (
  taskId: string,
  throwError: boolean = true
): Promise<GetTaskExecByTaskIdQueryDto | undefined> => {
  try {
    const result = await http.getTaskExecByTaskId(taskId);
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const getAllNestedAssets = async (
  showAll?: boolean | undefined,
  throwError: boolean = true
): Promise<Array<GetNestedAssetsQueryDto> | undefined> => {
  try {
    const result = await http.getNestedAssets(showAll);
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const changeAssetUtilizationState = async (
  assetId: string,
  utilRawId: string,
  comment: string | null,
  throwError: boolean = true
): Promise<void> => {
  try {
    const request = new ChangeAssetStateRequest({ utilRawId, comment });
    await http.changeAssetState(assetId, request);
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};
