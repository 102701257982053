import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";

export interface SetupState {
  appStarted: boolean;
  kpiView: boolean;
  tableView: boolean;
  tabCarousel: boolean;
  assetViewCarousel: boolean;
}

export const initialState = {
  appStarted: false,
  kpiView: false,
  tableView: false,
  tabCarousel: false,
  assetViewCarousel: false,
} as SetupState;

export const setupSlice = createSlice({
  name: "setup",
  initialState,
  reducers: {
    appStarted: (state, action: PayloadAction<boolean>) => {
      state.appStarted = true;
    },
    kpiViewSelected: (state, action: PayloadAction<boolean>) => {
      state.kpiView = action.payload;
    },
    tableViewSelected: (state, action: PayloadAction<boolean>) => {
      state.tableView = action.payload;
    },
    tabCarouselSelected: (state, action: PayloadAction<boolean>) => {
      state.tabCarousel = action.payload;
    },
    assetViewCarouselSelected: (state, action: PayloadAction<boolean>) => {
      state.assetViewCarousel = action.payload;
    },
  },
});

export const {
  appStarted,
  kpiViewSelected,
  tableViewSelected,
  tabCarouselSelected,
  assetViewCarouselSelected,
} = setupSlice.actions;

export const selectAppStarted = createSelector(
  (state: RootState) => state.setup.appStarted,
  (appStarted) => appStarted
);

export const selectKpiView = createSelector(
  (state: RootState) => state.setup.kpiView,
  (kpiView) => kpiView
);

export const selectTableView = createSelector(
  (state: RootState) => state.setup.tableView,
  (tableView) => tableView
);

export const selectTabCarousel = createSelector(
  (state: RootState) => state.setup.tabCarousel,
  (tabCarousel) => tabCarousel
);

export const selectAssetViewCarousel = createSelector(
  (state: RootState) => state.setup.assetViewCarousel,
  (assetViewCarousel) => assetViewCarousel
);

export default setupSlice.reducer;

export type SetupReducerState = ReturnType<typeof setupSlice.reducer>;
