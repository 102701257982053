import { ChartData } from "chart.js";
import appTheme from "../../../../themes/appTheme";
import { Point, determineArrowColor } from "./KPIsHelpersFunctions";
import ArrowRight from "../../../../icons/ArrowRight";
import ArrowUpRight from "../../../../icons/ArrowUpRight";
import ArrowDownRight from "../../../../icons/ArrowDownRight";

interface ArrowComponentProps {
  values: ChartData<"line", Array<Point>>;
}

export const ArrowComponent: React.FC<ArrowComponentProps> = ({ values }) => {
  const data = values?.datasets[0].data.map((d) => d.y);
  const len = data?.length;
  const averageValue = values?.datasets[1].data[0].y;
  const targetValue = values?.datasets[2].data[0].y;
  const defaultArrowColor = appTheme.palette.divider;
  const color = determineArrowColor(
    averageValue,
    targetValue,
    appTheme.palette.chart?.warning,
    appTheme.palette.error.light,
    appTheme.palette.chart?.success
  );

  if (len > 2) {
    if (data !== null && len !== null) {
      const diff: number = Number(data[len - 1]) - Number(data[len - 2]);
      if (diff > 0) {
        return <ArrowUpRight color={color} />;
      } else if (diff < 0) {
        return <ArrowDownRight color={color} />;
      } else {
        return <ArrowRight color={color} />;
      }
    }
  }

  return <ArrowRight color={defaultArrowColor} />;
};
