import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  selectCustomTabComponents,
  selectCustomTabsLoaded,
} from "../../../store/common/customTabsSlice";
import { useAppSelector } from "../../../store/hooks";
import CustomTabComponent from "./CustomTabComponent";

interface CustomPageProps {}

const CustomPage: React.FC<CustomPageProps> = () => {
  const customTabs = useAppSelector(selectCustomTabComponents);
  const tabsLoaded = useAppSelector(selectCustomTabsLoaded);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      tabsLoaded &&
      !(
        !isEmpty(customTabs) &&
        customTabs.find((p) => p.path === location.pathname)
      )
    ) {
      navigate("/assets");
    }
  }, [tabsLoaded, customTabs, location, navigate]);

  return (
    <>
      {!isEmpty(customTabs) &&
        customTabs.find((p) => p.path === location.pathname) && (
          <CustomTabComponent />
        )}
    </>
  );
};

export default CustomPage;
