import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={588.905}
    height={601.201}
    viewBox="0 0 588 601"
    {...props}
  >
    <g data-name="Group 10709">
      <g data-name="Group 78">
        <g data-name="Path 4285" fill="#00c1de">
          <path d="M73.35 600.701c-5.88 0-11.468-.485-16.611-1.443-5.018-.935-9.755-2.345-14.079-4.193a56.63 56.63 0 0 1-11.722-6.744 56.867 56.867 0 0 1-9.547-9.108c-4.443-5.335-8.219-11.783-11.222-19.163-2.858-7.022-5.107-15.112-6.686-24.047-1.489-8.427-2.43-17.889-2.799-28.122-.343-9.536-.2-20.092.425-31.375 2.418-43.654 11.595-93.482 20.47-141.669 9.304-50.521 18.093-98.241 18.093-133.674 0-15.419 1.064-30.252 3.163-44.088 2.112-13.923 5.323-27.17 9.543-39.372 4.255-12.302 9.638-23.84 16-34.295 6.428-10.56 14.012-20.273 22.542-28.866 8.633-8.698 18.45-16.466 29.175-23.087 10.874-6.713 22.956-12.415 35.909-16.948 13.15-4.603 27.53-8.116 42.741-10.443C214.205 1.699 230.915.5 248.412.5c35.282 0 63.054 3.673 84.901 11.228 21.16 7.317 37.03 18.395 48.515 33.865a97.116 97.116 0 0 1 7.612 12.056c2.24 4.19 4.297 8.737 6.113 13.516 3.52 9.266 6.243 19.554 8.324 31.451 1.924 10.996 3.27 23.226 4.114 37.389.743 12.447 1.104 26.633 1.104 43.368 0 17.53 8.407 41.833 18.14 69.975 5.712 16.51 12.186 35.225 17.485 54.33 6.06 21.843 9.744 41.151 11.265 59.027.885 10.414 1.037 20.543.45 30.106-.634 10.344-2.155 20.397-4.518 29.88-2.528 10.138-6.102 19.961-10.625 29.196-4.796 9.793-10.809 19.234-17.873 28.061-5.056 6.32-10.688 12.488-16.74 18.334-5.843 5.646-12.236 11.145-19 16.343-12.798 9.834-27.413 18.97-43.44 27.155-14.76 7.538-31.058 14.443-48.441 20.523-15.828 5.536-32.898 10.51-50.735 14.78-30.248 7.243-63.057 12.596-97.518 15.913-31.82 3.062-57.91 3.705-74.194 3.705Z" />
          <path d="M248.412 1c-17.472 0-34.157 1.197-49.591 3.558-15.18 2.322-29.53 5.828-42.652 10.42-12.92 4.522-24.968 10.209-35.811 16.903-10.693 6.6-20.477 14.343-29.083 23.013-8.502 8.566-16.062 18.247-22.47 28.774-6.343 10.424-11.712 21.93-15.954 34.199-4.21 12.174-7.414 25.39-9.522 39.283-2.095 13.811-3.157 28.62-3.157 44.013 0 35.478-8.793 83.22-18.102 133.765C13.2 383.099 4.025 432.911 1.608 476.534.984 487.8.84 498.342 1.184 507.863c.367 10.21 1.306 19.648 2.791 28.053 1.573 8.9 3.813 16.956 6.657 23.946 2.984 7.332 6.733 13.735 11.143 19.031a56.374 56.374 0 0 0 9.463 9.028 56.13 56.13 0 0 0 11.619 6.684c4.29 1.833 8.991 3.234 13.973 4.161 5.113.952 10.671 1.435 16.52 1.435 16.273 0 42.346-.642 74.147-3.703 34.438-3.314 67.224-8.664 97.45-15.901 17.82-4.267 34.873-9.235 50.686-14.766 17.362-6.073 33.639-12.969 48.379-20.496 16-8.17 30.588-17.29 43.362-27.107 6.75-5.186 13.128-10.672 18.958-16.306 6.036-5.831 11.654-11.983 16.697-18.286 7.04-8.799 13.034-18.209 17.814-27.97 4.507-9.202 8.07-18.992 10.588-29.096 2.357-9.454 3.872-19.476 4.505-29.79.585-9.538.434-19.642-.45-30.032-1.517-17.845-5.196-37.123-11.248-58.936-5.295-19.09-11.766-37.797-17.475-54.3-9.749-28.185-18.168-52.526-18.168-70.139 0-16.725-.361-30.901-1.103-43.339-.843-14.143-2.187-26.355-4.107-37.332-2.076-11.865-4.79-22.123-8.3-31.36-1.807-4.759-3.855-9.287-6.086-13.458a96.616 96.616 0 0 0-7.572-11.993c-11.423-15.387-27.215-26.407-48.277-33.69C311.356 4.663 283.638 1 248.412 1m0-1c141.937 0 161.183 59.661 161.183 183.373 0 59.382 97.091 197.32 14.215 300.888C340.933 587.829 147.158 601.2 73.35 601.2c-141.936 0-34.178-276.327-34.178-400.038C39.172 77.45 106.475 0 248.412 0Z" />
        </g>
      </g>
      <g data-name="Group 12007">
        <g data-name="Group 11962">
          <g data-name="Group 11961">
            <g data-name="Group 11955">
              <g data-name="Group 11953">
                <g data-name="Group 11924">
                  <path
                    data-name="Path 9928"
                    d="M480.556 524.808V411.739l7.332-4.631v113.068Z"
                    fill="#fff"
                    stroke="#003aa9"
                    strokeMiterlimit={10}
                    strokeWidth={0.3}
                  />
                </g>
                <g data-name="Group 11925">
                  <path
                    data-name="Path 9929"
                    d="M480.556 524.808V411.739l-7.718-4.631v113.068Z"
                    fill="#fff"
                    stroke="#003aa9"
                    strokeMiterlimit={10}
                    strokeWidth={0.3}
                  />
                </g>
                <g
                  data-name="Group 11926"
                  fill="#fff"
                  stroke="#003aa9"
                  strokeMiterlimit={10}
                  strokeWidth={0.3}
                >
                  <path
                    data-name="Path 9930"
                    d="M201.939 361.186V248.505l-7.332-4.631v113.068Z"
                  />
                  <path
                    data-name="Path 9931"
                    d="M201.939 361.187V248.505l7.718 4.245v104.192Z"
                  />
                </g>
                <g
                  data-name="Group 11927"
                  fill="#fff"
                  stroke="#003aa9"
                  strokeMiterlimit={10}
                  strokeWidth={0.3}
                >
                  <path
                    data-name="Path 9932"
                    d="M581.275 465.379v-111.91l-7.718 4.245v103.42Z"
                  />
                  <path
                    data-name="Path 9933"
                    d="M581.275 465.379v-111.91l7.332-4.245v111.91Z"
                  />
                </g>
                <g
                  data-name="Group 11929"
                  fill="#fff"
                  stroke="#003aa9"
                  strokeMiterlimit={10}
                  strokeWidth={0.3}
                >
                  <path
                    data-name="Path 9934"
                    d="m303.044 173.641 285.563 167.865-108.051 62.515-285.949-167.865Z"
                  />
                  <g data-name="Group 11928">
                    <path
                      data-name="Path 9935"
                      d="M480.556 404.021v7.718L194.607 243.874v-7.718Z"
                    />
                    <path
                      data-name="Path 9936"
                      d="M588.607 341.506v7.718l-108.051 62.515v-7.718Z"
                    />
                  </g>
                </g>
                <g data-name="Group 11952">
                  <g data-name="Group 11935">
                    <g data-name="Group 11931">
                      <path
                        data-name="Path 9937"
                        d="M380.995 226.122v6.56a13.63 13.63 0 0 1-7.718 10.805 40.133 40.133 0 0 1-37.432 0 13.63 13.63 0 0 1-7.718-10.805v-6.56Z"
                        fill="#fff"
                        stroke="#003aa9"
                        strokeMiterlimit={10}
                        strokeWidth={0.3}
                      />
                      <g data-name="Group 11930">
                        <path
                          data-name="Path 9938"
                          d="M373.277 215.317a13.63 13.63 0 0 1 7.718 10.805 13.63 13.63 0 0 1-7.718 10.805 40.133 40.133 0 0 1-37.432 0c-10.419-6.174-10.419-15.822 0-21.61a40.133 40.133 0 0 1 37.432 0Z"
                          fill="#fff"
                          stroke="#003aa9"
                          strokeMiterlimit={10}
                          strokeWidth={0.3}
                        />
                      </g>
                    </g>
                    <g data-name="Group 11933">
                      <g data-name="Group 11932">
                        <path
                          data-name="Path 9939"
                          d="M359.771 207.6a3.215 3.215 0 0 1 0 6.174 12.291 12.291 0 0 1-10.419 0 3.215 3.215 0 0 1 0-6.174 10.1 10.1 0 0 1 10.419 0Z"
                          fill="#fff"
                          stroke="#003aa9"
                          strokeMiterlimit={10}
                          strokeWidth={0.3}
                        />
                      </g>
                      <path
                        data-name="Path 9940"
                        d="M362.086 210.301v15.436a3.62 3.62 0 0 1-2.315 3.087 12.291 12.291 0 0 1-10.419 0 3.62 3.62 0 0 1-2.316-3.087v-15.436"
                        fill="#fff"
                        stroke="#003aa9"
                        strokeMiterlimit={10}
                        strokeWidth={0.3}
                      />
                    </g>
                    <g
                      data-name="Group 11934"
                      fill="#fff"
                      stroke="#003aa9"
                      strokeMiterlimit={10}
                      strokeWidth={0.3}
                    >
                      <path
                        data-name="Path 9941"
                        d="M418.813 155.504v98.018l-127.346-73.706V81.798Z"
                      />
                      <path
                        data-name="Path 9942"
                        d="M414.954 158.205v81.038l-119.628-68.689V89.13Z"
                      />
                      <path
                        data-name="Path 9943"
                        d="M428.075 150.101v98.4l-9.262 5.02v-98.02Z"
                      />
                      <path
                        data-name="Path 9944"
                        d="m300.343 76.395 127.731 73.706-9.262 5.4-127.345-73.706Z"
                      />
                    </g>
                  </g>
                  <g data-name="Group 11941">
                    <g data-name="Group 11937">
                      <path
                        data-name="Path 9945"
                        d="M508.727 299.828v6.56a13.63 13.63 0 0 1-7.718 10.805 40.133 40.133 0 0 1-37.432 0 13.63 13.63 0 0 1-7.718-10.805v-6.56Z"
                        fill="#fff"
                        stroke="#003aa9"
                        strokeMiterlimit={10}
                        strokeWidth={0.3}
                      />
                      <g data-name="Group 11936">
                        <path
                          data-name="Path 9946"
                          d="M501.009 289.023a13.63 13.63 0 0 1 7.718 10.805 13.63 13.63 0 0 1-7.718 10.805 40.133 40.133 0 0 1-37.432 0c-10.419-6.174-10.419-15.822 0-21.61a40.133 40.133 0 0 1 37.432 0Z"
                          fill="#fff"
                          stroke="#003aa9"
                          strokeMiterlimit={10}
                          strokeWidth={0.3}
                        />
                      </g>
                    </g>
                    <g data-name="Group 11939">
                      <g data-name="Group 11938">
                        <path
                          data-name="Path 9947"
                          d="M487.503 280.919a3.215 3.215 0 0 1 0 6.174 12.291 12.291 0 0 1-10.419 0 3.215 3.215 0 0 1 0-6.174 12.291 12.291 0 0 1 10.419 0Z"
                          fill="#fff"
                          stroke="#003aa9"
                          strokeMiterlimit={10}
                          strokeWidth={0.3}
                        />
                      </g>
                      <path
                        data-name="Path 9948"
                        d="M489.432 284.007v15.436a3.62 3.62 0 0 1-2.315 3.087 12.291 12.291 0 0 1-10.419 0 3.62 3.62 0 0 1-2.316-3.087v-15.436"
                        fill="#fff"
                        stroke="#003aa9"
                        strokeMiterlimit={10}
                        strokeWidth={0.3}
                      />
                    </g>
                    <g
                      data-name="Group 11940"
                      fill="#fff"
                      stroke="#003aa9"
                      strokeMiterlimit={10}
                      strokeWidth={0.3}
                    >
                      <path
                        data-name="Path 9949"
                        d="M546.545 228.824v98.4l-127.732-73.702v-98.018Z"
                      />
                      <path
                        data-name="Path 9950"
                        d="M542.3 231.526v81.424l-119.242-69.076v-81.038Z"
                      />
                      <path
                        data-name="Path 9951"
                        d="M555.807 223.807v98.018l-9.262 5.4v-98.4Z"
                      />
                      <path
                        data-name="Path 9952"
                        d="m428.074 150.101 127.732 73.706-9.262 5.017-127.731-73.323Z"
                      />
                    </g>
                  </g>
                  <g data-name="Group 11951">
                    <path
                      data-name="Path 9953"
                      d="m357.841 274.36 60.972 35.117-30.872 18.137-60.972-35.117Z"
                      fill="#fff"
                      stroke="#003aa9"
                      strokeMiterlimit={10}
                      strokeWidth={0.3}
                    />
                    <g data-name="Group 11950">
                      <g data-name="Group 11949">
                        <g data-name="Group 11948">
                          <g data-name="Group 11943">
                            <g data-name="Group 11942">
                              <path
                                data-name="Path 9954"
                                d="m387.94 321.825 3.473-2.315-50.552-29.328-3.473 2.315Z"
                                fill="#fff"
                                stroke="#003aa9"
                                strokeMiterlimit={10}
                                strokeWidth={0.3}
                              />
                            </g>
                          </g>
                          <g data-name="Group 11944">
                            <path
                              data-name="Path 9955"
                              d="m393.728 318.352 3.473-2.315-50.552-29.328-3.473 2.315Z"
                              fill="#fff"
                              stroke="#003aa9"
                              strokeMiterlimit={10}
                              strokeWidth={0.3}
                            />
                          </g>
                          <g data-name="Group 11945">
                            <path
                              data-name="Path 9956"
                              d="m399.517 314.879 3.859-2.315-50.552-29.328-3.859 2.315Z"
                              fill="#fff"
                              stroke="#003aa9"
                              strokeMiterlimit={10}
                              strokeWidth={0.3}
                            />
                          </g>
                          <g data-name="Group 11947">
                            <g data-name="Group 11946">
                              <path
                                data-name="Path 9957"
                                d="m405.691 311.406 3.473-2.315-50.552-29.328-3.473 1.929Z"
                                fill="#fff"
                                stroke="#003aa9"
                                strokeMiterlimit={10}
                                strokeWidth={0.3}
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g
                data-name="Group 11954"
                fill="#fff"
                stroke="#003aa9"
                strokeMiterlimit={10}
                strokeWidth={0.3}
              >
                <path
                  data-name="Path 9958"
                  d="m532.652 353.854-38.976-22.768-50.552 30.872L482.1 384.34Z"
                />
                <path
                  data-name="Path 9959"
                  d="m532.652 350.381-38.976-22.768-50.552 30.872 38.976 22.382Z"
                />
                <path
                  data-name="Path 9960"
                  d="m532.652 346.522-38.976-22.382-50.552 30.486 38.976 22.382Z"
                />
                <path
                  data-name="Path 9961"
                  d="m532.652 343.049-38.976-22.768-50.552 30.872 38.976 22.382Z"
                />
                <path
                  data-name="Path 9962"
                  d="m532.652 339.19-38.976-22.382-50.552 30.486 38.976 22.768Z"
                />
              </g>
            </g>
            <g
              data-name="Group 11960"
              fill="#fff"
              stroke="#003aa9"
              strokeMiterlimit={10}
              strokeWidth={0.3}
            >
              <g data-name="Group 11956">
                <path
                  data-name="Path 9963"
                  d="m274.105 468.466 37.043-21.224a5.159 5.159 0 0 0 2.7 3.087.386.386 0 0 1 .386.386 20.9 20.9 0 0 0 5.788 1.544l-33.185 19.294.386.386a2.315 2.315 0 0 1 1.158 2.315 7.174 7.174 0 0 1-3.476 5.788 2.836 2.836 0 0 1-2.315.386l-3.085-1.543c-.386 0-.386-.386-.772-.386v-.386h-.386a4.893 4.893 0 0 1-2.315.386 4.75 4.75 0 0 1-3.859-1.929c-.768-4.245-.386-6.946 1.932-8.104Z"
                />
                <path
                  data-name="Path 9964"
                  d="m367.874 424.087-1.158.772 1.158.772a4.04 4.04 0 0 1 1.158 2.315 7.174 7.174 0 0 1-3.473 5.788 4.076 4.076 0 0 1-2.315 0l-3.087-1.544c-.386 0-.386-.386-.772-.386v-.386l-28.557 16.596v-3.86a4.685 4.685 0 0 0-3.087-4.24l35.5-20.457a4.164 4.164 0 0 1 6.174 4.631c.003-1.544-.769-.774-1.541-.001Z"
                />
                <path
                  data-name="Path 9965"
                  d="M327.355 450.329a6.3 6.3 0 0 0 3.088-3.473l37.046 21.224c1.158.386 1.544 1.544 1.929 2.7a4.1 4.1 0 0 1-.386 3.087 4.357 4.357 0 0 1-5.788 1.544l-.386-.386v.386c0 .386-.386.386-.772.386l-3.087 1.544a2.836 2.836 0 0 1-2.315-.386 7.78 7.78 0 0 1-3.473-5.788 2.315 2.315 0 0 1 1.156-2.311l.386-.386-33.186-19.299a4.488 4.488 0 0 0 5.788 1.158Z"
                />
                <path
                  data-name="Path 9966"
                  d="M310.762 441.453v3.859l-28.556-16.593v.386c0 .386-.386.386-.772.386l-3.087 4.245a2.836 2.836 0 0 1-2.315-.386 7.78 7.78 0 0 1-3.473-5.788 4.04 4.04 0 0 1 1.159-2.32l1.158-.772-1.158-.768a4.245 4.245 0 0 1 4.245-7.332l35.5 20.453a5.966 5.966 0 0 0-2.701 4.63Z"
                />
              </g>
              <path
                data-name="Path 9967"
                d="M313.463 445.312a.386.386 0 0 1 .386.386 16.482 16.482 0 0 0 13.506 0h.386a5.4 5.4 0 0 0 3.087-4.245V447.625a5.159 5.159 0 0 1-2.7 3.087h-.386a12.885 12.885 0 0 1-5.788 1.158h-1.931a10.033 10.033 0 0 1-5.788-1.544.386.386 0 0 1-.386-.386 5.159 5.159 0 0 1-2.7-3.087V441.067a3.89 3.89 0 0 0 2.314 4.245Z"
              />
              <path
                data-name="Path 9968"
                d="M310.762 441.454a5.4 5.4 0 0 1 3.087-4.245 5.484 5.484 0 0 1 2.313-.772v4.631c.386.386.386.772 1.158 1.158a6.63 6.63 0 0 0 6.56 0q1.158-.579 1.158-1.158v-4.631c.772.386 1.929.386 2.7 1.158a5.4 5.4 0 0 1 3.091 4.242 5.4 5.4 0 0 1-3.087 4.245h-.386a16.482 16.482 0 0 1-13.506 0 .386.386 0 0 1-.386-.386c-1.929-1.544-2.7-2.7-2.7-4.245Z"
              />
              <g data-name="Group 11957">
                <path
                  data-name="Path 9969"
                  d="M325.426 405.179v35.5l-1.158 1.158a7.27 7.27 0 0 1-3.087.772v-36.658a5.943 5.943 0 0 0 4.245-.772Z"
                />
                <path
                  data-name="Path 9970"
                  d="M320.795 406.337v36.66a11.266 11.266 0 0 1-3.473-.772q-1.158-.579-1.158-1.16v-35.5a19.375 19.375 0 0 0 4.631.772Z"
                />
              </g>
              <path
                data-name="Path 9971"
                d="m262.525 362.345 1.543-.772 50.941-29.328a11.542 11.542 0 0 1 11.191 0l49.395 28.942 2.7 1.544a3.708 3.708 0 0 1 2.315 3.473 4.666 4.666 0 0 1-2.315 3.473l-52.1 29.714a1.347 1.347 0 0 0-.772.386 12.781 12.781 0 0 1-9.262 0c-.386 0-.772-.386-1.158-.386l-52.478-30.486a3.709 3.709 0 0 1-2.315-3.473 3.62 3.62 0 0 1 2.315-3.087Z"
              />
              <g data-name="Group 11958">
                <path
                  data-name="Path 9972"
                  d="m309.604 296.742-30.1-17.366a10.087 10.087 0 0 0-3.088-1.157h-2.7l5.017-2.7a6.973 6.973 0 0 1 6.56.772l30.1 17.365a20.2 20.2 0 0 1 6.174 6.56l-5.788 3.473a18.222 18.222 0 0 0-6.174-6.946Z"
                />
                <path
                  data-name="Path 9973"
                  d="m315.779 303.302 5.788-3.473a18.909 18.909 0 0 1 3.087 9.647l3.859 63.673c0 2.315-3.087 5.4-6.56 7.332a16.98 16.98 0 0 0 .772-3.859l-3.473-63.673a24.369 24.369 0 0 0-3.473-9.647Z"
                />
              </g>
              <path
                data-name="Path 9974"
                d="M270.243 284.779a7.232 7.232 0 0 1 2.7-6.174l.772-.386h2.7a6.174 6.174 0 0 1 3.087 1.158l30.1 17.365a21.46 21.46 0 0 1 9.262 16.208l3.475 63.669a13.711 13.711 0 0 1-.772 3.859.386.386 0 0 0-.386.386 9.211 9.211 0 0 1-8.1 1.158l-37.435-21.603a21.46 21.46 0 0 1-9.262-16.212Z"
              />
              <path
                data-name="Path 9975"
                d="m283.749 380.096 13.892 8.1v-51.324l-13.892-8.1Z"
              />
              <g data-name="Group 11959">
                <path
                  data-name="Path 9976"
                  d="M325.426 398.619c.386 0 .772 0 .772-.386l52.867-30.486a3.709 3.709 0 0 0 2.316-3.473v6.56a4.665 4.665 0 0 1-2.315 3.473l-52.871 30.486a1.347 1.347 0 0 0-.772.386 9.79 9.79 0 0 1-4.631.772v-6.56a6.641 6.641 0 0 0 4.631-.772Z"
                />
                <path
                  data-name="Path 9977"
                  d="m262.525 367.747 52.484 30.486a1.416 1.416 0 0 0 1.158.386 19.368 19.368 0 0 0 4.631.772v6.56a19.369 19.369 0 0 1-4.631-.772c-.386 0-.772-.386-1.158-.386l-52.484-30.486a3.709 3.709 0 0 1-2.316-3.473v-6.56a3.708 3.708 0 0 0 2.315 3.473Z"
                />
              </g>
            </g>
          </g>
          <path
            data-name="Path 9978"
            d="m555.42 223.807.386 98.018-9.262 5.4-255.077-147.41v-98.02l8.876-5.4Z"
            fill="none"
            stroke="#003aa9"
            strokeMiterlimit={10}
            strokeWidth={0.3}
          />
        </g>
        <path
          data-name="Line 3823"
          fill="#fff"
          stroke="#003aa9"
          strokeMiterlimit={10}
          strokeWidth={0.3}
          d="m295.326 125.79 119.628 68.69"
        />
        <path
          data-name="Line 3824"
          fill="none"
          stroke="#003aa9"
          strokeMiterlimit={10}
          strokeWidth={0.3}
          d="M336.231 113.441v36.274"
        />
        <path
          data-name="Line 3825"
          fill="none"
          stroke="#003aa9"
          strokeMiterlimit={10}
          strokeWidth={0.3}
          d="M375.978 136.209v36.274"
        />
        <g
          data-name="Group 11963"
          fill="#fff"
          stroke="#003aa9"
          strokeMiterlimit={10}
          strokeWidth={0.1}
        >
          <path data-name="Line 3826" d="m428.46 170.168 109.209 63.287" />
          <path data-name="Line 3827" d="m428.46 175.956 109.209 62.901" />
          <path data-name="Line 3828" d="m428.46 181.359 109.209 63.287" />
          <path data-name="Line 3829" d="m428.46 187.147 109.209 63.287" />
          <path data-name="Line 3830" d="m428.46 192.936 109.209 62.901" />
          <path data-name="Line 3831" d="m428.46 198.338 109.209 63.287" />
          <path data-name="Line 3832" d="m428.46 204.127 109.209 63.287" />
          <path data-name="Line 3833" d="m428.46 209.529 109.209 63.287" />
          <path data-name="Line 3834" d="m428.46 215.318 109.209 63.287" />
          <path data-name="Line 3835" d="m428.46 221.106 109.209 62.901" />
          <path data-name="Line 3836" d="m428.46 226.509 109.209 63.287" />
          <path data-name="Line 3837" d="m428.46 232.297 109.209 63.287" />
          <path data-name="Line 3838" d="m428.46 238.086 109.209 62.901" />
          <path data-name="Line 3839" d="m428.46 243.488 109.209 63.287" />
        </g>
        <g
          data-name="Group 11964"
          stroke="#003aa9"
          strokeMiterlimit={10}
          strokeWidth={0.1}
        >
          <path data-name="Line 3840" fill="#fff" d="M436.95 175.184v73.32" />
          <path data-name="Line 3841" fill="#fff" d="M445.44 179.815v73.32" />
          <path data-name="Line 3842" fill="#fff" d="M453.544 184.831v73.32" />
          <path data-name="Line 3843" fill="#fff" d="M462.033 189.462v73.706" />
          <path data-name="Line 3844" fill="#fff" d="M470.523 194.479v73.32" />
          <path data-name="Line 3845" fill="#fff" d="M479.013 199.495v73.32" />
          <path data-name="Line 3846" fill="#fff" d="M487.502 204.126v73.32" />
          <path data-name="Line 3847" fill="#fff" d="M495.606 209.143v73.32" />
          <path data-name="Line 3848" fill="#fff" d="M504.096 213.774v73.32" />
          <path data-name="Line 3849" fill="#fff" d="M512.586 218.79v73.32" />
          <path data-name="Line 3850" fill="#fff" d="M521.075 223.807v73.32" />
          <path data-name="Line 3851" fill="#fff" d="M529.565 228.438v73.32" />
          <path data-name="Line 3852" fill="none" d="M537.669 233.454v73.32" />
        </g>
        <path
          data-name="Line 3853"
          fill="#fff"
          stroke="#003aa9"
          strokeMiterlimit={10}
          strokeWidth={0.1}
          d="M428.46 170.168v73.32"
        />
        <path
          data-name="Line 3854"
          fill="none"
          stroke="#e84044"
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={0.4}
          d="m512.971 231.525 24.697 75.25"
        />
        <path
          data-name="Path 9979"
          d="m428.46 243.488 7.718-6.56 8.876 4.631 16.979-6.946 8.1 5.017 8.879-12.35 16.979 10.419 8.1-6.946 8.876.772"
          fill="none"
          stroke="#003aa9"
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={0.4}
        />
        <g data-name="Group 11965">
          <path
            data-name="Path 9980"
            d="M310.376 116.914c.386 0 .386.386.386.772s0 .386-.386.386l-2.7-1.158c-.386 0-.386-.386-.386-.772s0-.386.386-.386Z"
            fill="#003aa9"
          />
        </g>
        <path
          data-name="Path 9981"
          d="m323.497 124.632 2.7 1.929c.386.386.386.386.386.772s-.386.386-.386 0l-3.087-1.929c-.386 0-.386-.386-.386-.772.387-.385.387-.385.773 0Z"
          fill="#003aa9"
        />
        <path
          data-name="Path 9982"
          d="m313.845 107.653.386.386.772 3.473c0 .386 0 .386-.386.386l-.386-.386-.772-3.473c-.381-.386.004-.386.386-.386Z"
          fill="#003aa9"
        />
        <path
          data-name="Path 9983"
          d="M320.024 111.126a1.2 1.2 0 0 1 .386.772l-.772 2.315h-.386a1.2 1.2 0 0 1-.386-.772l.772-2.315Z"
          fill="#003aa9"
        />
        <path
          data-name="Path 9984"
          d="M318.48 126.175c0 1.158-.772 1.544-1.929 1.158a4.345 4.345 0 0 1-1.93-3.087l1.158-9.262c0-.386.386-.386.386 0 .386 0 .386.386.386.772Zm-1.544 0c.386.386.772 0 .772-.386l-.772-6.946-.772 6.174c-.386.386 0 1.158.772 1.158"
          fill="#003aa9"
        />
        <path
          data-name="Path 9985"
          d="M356.298 131.578c.386 0 .386.386.386.772v3.087a.386.386 0 0 1-.386.386c-.386 0-.386-.386-.386-.772v-3.091c0-.382 0-.767.386-.382Z"
          fill="#003aa9"
        />
        <path
          data-name="Path 9986"
          d="m353.21 130.42.386.386.772 3.473c0 .386 0 .386-.386.386l-.386-.386-.772-3.473c0-.386 0-.386.386-.386Z"
          fill="#003aa9"
        />
        <path
          data-name="Path 9987"
          d="M365.559 145.471c.386 0 .386.386.386.772s0 .386-.386.386l-2.7-1.158c-.386 0-.386-.386-.386-.772s0-.386.386-.386Z"
          fill="#003aa9"
        />
        <path
          data-name="Path 9988"
          d="m362.858 147.399 2.7 1.929c.386.386.386.386.386.772s-.386.386-.386 0l-2.7-1.929c-.386 0-.386-.386-.386-.772s0-.385.386 0Z"
          fill="#003aa9"
        />
        <path
          data-name="Path 9989"
          d="M349.737 139.682c.386 0 .386.386.386.772s0 .386-.386.386l-2.7-1.158c-.386 0-.386-.386-.386-.772s0-.386.386-.386Z"
          fill="#003aa9"
        />
        <path
          data-name="Path 9990"
          d="M360.157 140.84c.386.386.386.386.386.772l-1.929 8.49c-.386.772-.772.772-1.544.386-.386 0-.386-.386-.772-.386a3.5 3.5 0 0 1-1.158-2.7v-.772l4.245-6.176a1.2 1.2 0 0 1 .772.386Zm-2.7 8.49 1.158-5.788-2.7 4.245v.386c0 .386.386.772.386 1.158l.386.386c.386 0 .772 0 .772-.386"
          fill="#003aa9"
        />
        <path
          data-name="Path 9991"
          d="M362.471 137.753v.772l-1.544 1.158h-.771v-.772l1.929-1.544Z"
          fill="#003aa9"
        />
        <path
          data-name="Path 9992"
          d="m364.4 141.611.386.386v.772l-2.315.386h-.386l-.386-.386v-.772l2.7-.386Z"
          fill="#003aa9"
        />
        <g data-name="Group 11966">
          <path
            data-name="Path 9993"
            d="M356.298 130.035a24.153 24.153 0 0 1 11.191 19.294c0 6.946-5.017 10.033-11.191 6.174a24.74 24.74 0 0 1-11.191-19.294c0-6.946 5.017-9.647 11.191-6.174Zm7.332 25.08a7.251 7.251 0 0 0 3.087-6.56 20.233 20.233 0 0 0-3.087-10.033 20.2 20.2 0 0 0-7.332-7.718 7.374 7.374 0 0 0-7.332-.772 7.251 7.251 0 0 0-3.087 6.56 20.232 20.232 0 0 0 3.087 10.033 23.385 23.385 0 0 0 7.332 7.718 7.375 7.375 0 0 0 7.332.772"
            fill="#003aa9"
          />
        </g>
        <g data-name="Group 11967">
          <path
            data-name="Path 9994"
            d="m347.036 135.051 2.7 1.929c.386 0 .386.386.386.772s-.386.386-.386 0l-2.7-1.929c-.386 0-.386-.386-.386-.772Z"
            fill="#003aa9"
          />
        </g>
        <g data-name="Group 11968">
          <path
            data-name="Path 9995"
            d="m348.193 132.35 2.7 3.087c.386.386.386.772 0 .772h-.772l-1.929-3.087c-.386-.386-.386-.772 0-.772-.384-.385.001-.385.001 0Z"
            fill="#003aa9"
          />
        </g>
        <g data-name="Group 11969">
          <path
            data-name="Path 9996"
            d="m350.123 130.42.386.386 1.929 3.473v.772l-.772-.386-1.543-3.473Z"
            fill="#003aa9"
          />
        </g>
        <path
          data-name="Path 9997"
          d="M359.385 133.894a1.2 1.2 0 0 1 .386.772l-.772 2.315h-.386a1.2 1.2 0 0 1-.386-.772l.772-2.315Z"
          fill="#003aa9"
        />
        <path
          data-name="Path 9998"
          d="M395.659 154.346c.386 0 .386.386.386.772v3.087a.386.386 0 0 1-.386.386c-.386 0-.386-.386-.386-.772v-3.091c0-.382 0-.767.386-.382Z"
          fill="#003aa9"
        />
        <path
          data-name="Path 9999"
          d="m392.572 153.188.386.386.772 3.473c0 .386 0 .386-.386.386l-.386-.386-.772-3.477c0-.382 0-.767.386-.382Z"
          fill="#003aa9"
        />
        <path
          data-name="Path 10000"
          d="m387.555 155.117 2.701 3.086c.386.386.386.772 0 .772h-.772l-2.315-2.7c0-.772 0-1.158.386-1.158-.386-.385 0-.385 0 0Z"
          fill="#003aa9"
        />
        <path
          data-name="Path 10001"
          d="m386.398 157.819 2.7 1.929c.386 0 .386.386.386.772s-.386.386-.386 0l-2.7-1.929c-.386 0-.386-.386-.386-.772Z"
          fill="#003aa9"
        />
        <path
          data-name="Path 10002"
          d="m389.485 153.188.386.386 1.929 3.473v.772l-.772-.772-1.543-3.087Z"
          fill="#003aa9"
        />
        <path
          data-name="Path 10003"
          d="M401.833 160.52v.772l-1.544 1.158-.772-.386v-.386l1.93-1.544Z"
          fill="#003aa9"
        />
        <g data-name="Group 11970">
          <path
            data-name="Path 10004"
            d="m403.377 169.782.386.386v.772l-.386.386-.772.386-4.631 2.7h-1.158c-.386-.386-1.158-.772-1.544-1.544a2.315 2.315 0 0 1-.386-1.544c0-.772.386-1.158.772-1.158Zm-6.174 3.473 4.245-2.315h-5.018c-.386 0-.386.386-.386.772v.77a.83.83 0 0 0 .772.772h.386"
            fill="#003aa9"
          />
        </g>
        <g data-name="Group 11971">
          <path
            data-name="Path 10005"
            d="M404.92 168.239c.386 0 .386.386.386.772s0 .386-.386.386l-2.7-1.158a.386.386 0 0 1-.387-.386c0-.386 0-.386.386-.386Z"
            fill="#003aa9"
          />
        </g>
        <g data-name="Group 11972">
          <path
            data-name="Path 10006"
            d="m403.762 164.379.386.386v.772l-2.315.386h-.386l-.386-.386v-.772l2.7-.386c-.384-.385.001-.385.001 0Z"
            fill="#003aa9"
          />
        </g>
        <path
          data-name="Path 10007"
          d="m403.377 170.94 1.544 1.158c.386.386.386.386.386.772s-.386.386-.386 0l-1.93-1.544Z"
          fill="#003aa9"
        />
        <path
          data-name="Path 10008"
          d="M389.099 162.45c.386 0 .386.386.386.772s0 .386-.386.386l-2.7-1.158c-.386 0-.386-.386-.386-.772s0-.386.386-.386Z"
          fill="#003aa9"
        />
        <path
          data-name="Path 10009"
          d="M398.746 156.661a1.2 1.2 0 0 1 .386.772l-.772 2.7h-.386a1.2 1.2 0 0 1-.386-.772l.772-2.315a.386.386 0 0 1 .386-.385Z"
          fill="#003aa9"
        />
        <path
          data-name="Path 10010"
          d="M375.978 186.375a4.345 4.345 0 0 1 1.929 3.088l-1.157 11.576c0 .386-.386.386-.386 0s-.386-.386-.386-.772l-1.158-13.12c-.386-.772.386-1.158 1.158-.772Z"
          fill="#e84044"
        />
        <path
          data-name="Path 10011"
          d="M375.978 203.741a4.345 4.345 0 0 1 1.929 3.087c0 1.158-.772 1.544-1.929 1.158a4.345 4.345 0 0 1-1.929-3.087c.386-1.158 1.158-1.544 1.929-1.158Z"
          fill="#e84044"
        />
        <g data-name="Group 11973">
          <path
            data-name="Path 10012"
            d="m376.364 177.886 17.751 45.15v.386h-.386l-35.116-20.453-.772-.386v-.383l17.751-24.7h.386Z"
            fill="none"
            stroke="#e84044"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={0.4}
          />
        </g>
        <g data-name="Group 11974">
          <path
            data-name="Path 10013"
            d="M395.659 152.802a24.153 24.153 0 0 1 11.191 19.295c0 6.946-5.017 10.033-11.191 6.174a24.153 24.153 0 0 1-11.191-19.294c0-6.946 5.017-10.034 11.191-6.175Zm7.332 25.083a7.251 7.251 0 0 0 3.087-6.56 20.233 20.233 0 0 0-3.087-10.033 20.2 20.2 0 0 0-7.332-7.718 7.374 7.374 0 0 0-7.332-.772 7.251 7.251 0 0 0-3.087 6.56 20.233 20.233 0 0 0 3.087 10.033 23.385 23.385 0 0 0 7.332 7.718 7.374 7.374 0 0 0 7.332.772"
            fill="#003aa9"
          />
        </g>
        <path
          data-name="Path 10014"
          d="M316.936 108.81c.386 0 .386.386.386.772v3.087a.386.386 0 0 1-.386.386c-.386 0-.386-.386-.386-.772v-3.091c0-.382 0-.767.386-.382Z"
          fill="#003aa9"
        />
        <path
          data-name="Path 10015"
          d="M316.936 107.266a24.153 24.153 0 0 1 11.191 19.295c0 6.946-5.017 10.033-11.191 6.174a24.153 24.153 0 0 1-11.191-19.294c0-6.946 5.017-10.034 11.191-6.175Zm6.946 25.083a7.251 7.251 0 0 0 3.087-6.56 20.233 20.233 0 0 0-3.087-10.033 20.2 20.2 0 0 0-7.332-7.718 7.374 7.374 0 0 0-7.332-.772 7.251 7.251 0 0 0-3.087 6.56 20.233 20.233 0 0 0 3.087 10.033 23.385 23.385 0 0 0 7.332 7.718c3.087 1.544 5.788 1.929 7.332.772"
          fill="#003aa9"
        />
        <path
          data-name="Path 10016"
          d="M325.812 122.703c.386 0 .386.386.386.772s0 .386-.386.386l-2.7-1.158c-.386 0-.386-.386-.386-.772s0-.386.386-.386Z"
          fill="#003aa9"
        />
        <path
          data-name="Path 10017"
          d="m324.653 118.844.386.386v.772l-2.315.386h-.386l-.386-.386v-.772l2.701-.386Z"
          fill="#003aa9"
        />
        <g data-name="Group 11975">
          <path
            data-name="Path 10018"
            d="m307.675 112.283 2.7 1.929c.386 0 .386.386.386.772s-.386.386-.386 0l-2.7-1.929c-.386 0-.386-.386-.386-.772Z"
            fill="#003aa9"
          />
        </g>
        <g data-name="Group 11976">
          <path
            data-name="Path 10019"
            d="m308.832 109.582 2.315 3.087c.386.386.386.772 0 .772h-.772l-2.315-2.7c.386-.773 0-1.159.772-1.159Z"
            fill="#003aa9"
          />
        </g>
        <path
          data-name="Path 10020"
          d="m310.376 107.653.772.386 1.544 3.473v.772l-.772-.386-1.544-3.473Z"
          fill="#003aa9"
        />
        <path
          data-name="Path 10021"
          d="M322.724 114.985v.772l-1.544 1.158h-.771v-.772l1.929-1.544Z"
          fill="#003aa9"
        />
        <g data-name="Group 11977">
          <path
            data-name="Path 10022"
            d="M350.895 175.185c.386 0 .386.386.386.772a.386.386 0 0 1-.386.386l-20.452-11.963c-.386 0-.386-.386-.386-.772a.386.386 0 0 1 .386-.386Z"
            fill="#003aa9"
          />
        </g>
        <g data-name="Group 11978">
          <path
            data-name="Path 10023"
            d="M350.895 196.795c.386 0 .386.386.386.772a.386.386 0 0 1-.386.386l-20.452-11.963c-.386 0-.386-.386-.386-.772a.386.386 0 0 1 .386-.386Z"
            fill="#e84044"
          />
        </g>
        <g data-name="Group 11986">
          <g data-name="Group 11979">
            <path
              data-name="Path 10024"
              d="M334.688 179.428c.386 0 .386.386.386.772a.386.386 0 0 1-.386.386l-4.245-2.7c-.386 0-.386-.386-.386-.772a.386.386 0 0 1 .386-.386Z"
              fill="#003aa9"
            />
          </g>
          <g data-name="Group 11980">
            <path
              data-name="Path 10025"
              d="M350.895 194.094c.386 0 .386.386.386.772a.386.386 0 0 1-.386.386l-20.452-11.963c-.386 0-.386-.386-.386-.772a.386.386 0 0 1 .386-.386Z"
              fill="#e84044"
            />
          </g>
          <g data-name="Group 11981">
            <path
              data-name="Path 10026"
              d="M350.895 191.392c.386 0 .386.386.386.772a.386.386 0 0 1-.386.386l-20.452-11.963c-.386 0-.386-.386-.386-.772a.386.386 0 0 1 .386-.386Z"
              fill="#e84044"
            />
          </g>
          <g data-name="Group 11982">
            <path
              data-name="Path 10027"
              d="M350.895 185.99c.386 0 .386.386.386.772a.386.386 0 0 1-.386.386l-20.452-11.963c-.386 0-.386-.386-.386-.772a.386.386 0 0 1 .386-.386Z"
              fill="#003aa9"
            />
          </g>
          <g data-name="Group 11983">
            <path
              data-name="Path 10028"
              d="M345.493 174.799c.386 0 .386.386.386.772a.386.386 0 0 1-.386.386l-15.05-8.876c-.386 0-.386-.386-.386-.772a.386.386 0 0 1 .386-.386Z"
              fill="#003aa9"
            />
          </g>
          <g data-name="Group 11984">
            <path
              data-name="Path 10029"
              d="M350.895 183.289c.386 0 .386.386.386.772a.386.386 0 0 1-.386.386l-20.452-11.963c-.386 0-.386-.386-.386-.772a.386.386 0 0 1 .386-.386Z"
              fill="#003aa9"
            />
          </g>
          <g data-name="Group 11985">
            <path
              data-name="Path 10030"
              d="M350.895 180.587c.386 0 .386.386.386.772a.386.386 0 0 1-.386.386l-20.452-11.963c-.386 0-.386-.386-.386-.772a.386.386 0 0 1 .386-.386Z"
              fill="#003aa9"
            />
          </g>
        </g>
        <g data-name="Group 11990">
          <g data-name="Group 11987">
            <path
              data-name="Path 10031"
              d="M350.895 172.483c.386 0 .386.386.386.772a.386.386 0 0 1-.386.386l-20.452-11.963c-.386 0-.386-.386-.386-.772a.386.386 0 0 1 .386-.386Z"
              fill="#003aa9"
            />
          </g>
          <g data-name="Group 11988">
            <path
              data-name="Path 10032"
              d="M350.895 169.782c.386 0 .386.386.386.772a.386.386 0 0 1-.386.386l-20.452-11.963c-.386 0-.386-.386-.386-.772a.386.386 0 0 1 .386-.386Z"
              fill="#003aa9"
            />
          </g>
          <g data-name="Group 11989">
            <path
              data-name="Path 10033"
              d="M350.895 161.678c.386 0 .386.386.386.772a.386.386 0 0 1-.386.386l-20.452-11.963c-.386 0-.386-.386-.386-.772a.386.386 0 0 1 .386-.386Z"
              fill="#003aa9"
            />
          </g>
        </g>
        <g data-name="Group 11991">
          <path
            data-name="Path 10034"
            d="M340.09 160.906c.386 0 .386.386.386.772a.386.386 0 0 1-.386.386l-9.647-5.788c-.386 0-.386-.386-.386-.772a.386.386 0 0 1 .386-.386Z"
            fill="#003aa9"
          />
        </g>
        <g data-name="Group 11992">
          <path
            data-name="Path 10035"
            d="M350.895 164.38c.386 0 .386.386.386.772a.386.386 0 0 1-.386.386l-20.452-11.963c-.386 0-.386-.386-.386-.772a.386.386 0 0 1 .386-.386Z"
            fill="#003aa9"
          />
        </g>
        <g data-name="Group 11993">
          <path
            data-name="Path 10036"
            d="M325.811 147.4c.386 0 .386.386.386.772a.386.386 0 0 1-.386.386l-20.452-11.963c-.386 0-.386-.386-.386-.772a.386.386 0 0 1 .386-.386Z"
            fill="#003aa9"
          />
        </g>
        <g data-name="Group 11994">
          <path
            data-name="Path 10037"
            d="M325.425 171.712c.386 0 .386.386.386.772a.386.386 0 0 1-.386.386l-20.452-11.963c-.386 0-.386-.386-.386-.772a.386.386 0 0 1 .386-.386Z"
            fill="#003aa9"
          />
        </g>
        <g data-name="Group 11995">
          <path
            data-name="Path 10038"
            d="M325.425 182.517c.386 0 .386.386.386.772a.386.386 0 0 1-.386.386l-20.452-11.963c-.386 0-.386-.386-.386-.772a.386.386 0 0 1 .386-.386Z"
            fill="#003aa9"
          />
        </g>
        <g data-name="Group 11996">
          <path
            data-name="Path 10039"
            d="M325.425 177.114c.386 0 .386.386.386.772a.386.386 0 0 1-.386.386l-20.452-11.963c-.386 0-.386-.386-.386-.772a.386.386 0 0 1 .386-.386Z"
            fill="#003aa9"
          />
        </g>
        <g data-name="Group 11997">
          <path
            data-name="Path 10040"
            d="M309.604 165.15c.386 0 .386.386.386.772a.386.386 0 0 1-.386.386l-4.245-2.7c-.386 0-.386-.386-.386-.772a.386.386 0 0 1 .386-.386Z"
            fill="#003aa9"
          />
        </g>
        <g data-name="Group 11998">
          <path
            data-name="Path 10041"
            d="M325.425 179.815c.386 0 .386.386.386.772a.386.386 0 0 1-.386.386l-20.452-11.963c-.386 0-.386-.386-.386-.772a.386.386 0 0 1 .386-.386Z"
            fill="#003aa9"
          />
        </g>
        <g data-name="Group 11999">
          <path
            data-name="Path 10042"
            d="M320.023 160.521c.386 0 .386.386.386.772a.386.386 0 0 1-.386.386l-15.05-8.876c-.386 0-.386-.386-.386-.772a.386.386 0 0 1 .386-.386Z"
            fill="#003aa9"
          />
        </g>
        <g data-name="Group 12000">
          <path
            data-name="Path 10043"
            d="M325.425 169.01c.386 0 .386.386.386.772a.386.386 0 0 1-.386.386l-20.452-11.963c-.386 0-.386-.386-.386-.772a.386.386 0 0 1 .386-.386Z"
            fill="#003aa9"
          />
        </g>
        <g data-name="Group 12001">
          <path
            data-name="Path 10044"
            d="M325.425 166.309c.386 0 .386.386.386.772a.386.386 0 0 1-.386.386l-20.452-11.963c-.386 0-.386-.386-.386-.772a.386.386 0 0 1 .386-.386Z"
            fill="#003aa9"
          />
        </g>
        <g data-name="Group 12002">
          <path
            data-name="Path 10045"
            d="M315.006 146.628c.386 0 .386.386.386.772a.386.386 0 0 1-.386.386l-9.647-5.788c-.386 0-.386-.386-.386-.772a.386.386 0 0 1 .386-.386Z"
            fill="#003aa9"
          />
        </g>
        <g data-name="Group 12003">
          <path
            data-name="Path 10046"
            d="M325.425 158.205c.386 0 .386.386.386.772a.386.386 0 0 1-.386.386L304.973 147.4c-.386 0-.386-.386-.386-.772a.386.386 0 0 1 .386-.386Z"
            fill="#003aa9"
          />
        </g>
        <g data-name="Group 12004">
          <path
            data-name="Path 10047"
            d="M325.425 155.504c.386 0 .386.386.386.772a.386.386 0 0 1-.386.386l-20.452-11.963c-.386 0-.386-.386-.386-.772a.386.386 0 0 1 .386-.386Z"
            fill="#003aa9"
          />
        </g>
        <g data-name="Group 12005">
          <path
            data-name="Path 10048"
            d="M325.426 150.101c.386 0 .386.386.386.772a.386.386 0 0 1-.386.386l-20.067-11.963c-.386 0-.386-.386-.386-.772a.386.386 0 0 1 .386-.386Z"
            fill="#003aa9"
          />
        </g>
        <g data-name="Group 12006">
          <path
            data-name="Path 10049"
            d="M325.811 160.906c.386 0 .386.386.386.772a.386.386 0 0 1-.386.386l-20.452-11.963c-.386 0-.386-.386-.386-.772a.386.386 0 0 1 .386-.386Z"
            fill="#003aa9"
          />
        </g>
        <path
          data-name="Path 10050"
          d="m300.343 76.395-9.262 5.4v98.02l255.077 147.413 9.261-5.4v-98.4Z"
          fill="none"
          stroke="#003aa9"
          strokeLinecap="square"
          strokeLinejoin="bevel"
          strokeMiterlimit={10}
          strokeWidth={0.75}
        />
        <path
          data-name="Path 10051"
          d="m428.075 150.101-9.262 5.4.772 97.632"
          fill="none"
          stroke="#003aa9"
          strokeLinecap="square"
          strokeLinejoin="bevel"
          strokeMiterlimit={10}
          strokeWidth={0.75}
        />
      </g>
    </g>
  </svg>
);

export default SvgComponent;
