import { EntityIcon, JobsIcon, OperatorsIcon } from "@improdis/core";
import { Box, Paper, Tooltip, Typography } from "@mui/material";
import { t } from "i18next";
import React, { FunctionComponent, useState } from "react";
import {
  getAssetStateText,
  getDateText,
  getTaskNameText,
  getUserData,
} from "../../../helpers/tileDisplayFunctions";
import { selectUsers } from "../../../store/definitions/usersSlice";
import { useAppSelector } from "../../../store/hooks";
import { TaskExec } from "../../../store/main/task-execs/taskExecsSlice";
import { TaskLogon } from "../../../store/main/task-logons/taskLogonsSlice";
import { selectUtilizationExecsByAssetId } from "../../../store/main/util-execs/utilExecsSlice";
import { AvailableAsset } from "../../../store/user/assets-selection/assetsSelectionSlice";
import { StyledLinesEllipsis } from "../../common/LineEllipsis";
import ProgressBar from "../../common/ProgressBar";
import { IconContainer } from "../../common/tiles/IconContainer";
import { InfoBorderContainer } from "../../common/tiles/InfoBorderContainer";
import { StatusBar } from "../../common/tiles/StatusBar";
import { TileContent } from "../../common/tiles/TileContent";
import { TileHeader } from "../../common/tiles/TileHeader";
import { ValueContainer } from "../../common/tiles/ValueContainer";
import { InfoContainer } from "./../../common/tiles/InfoContainer";
import { env } from "../../../env";

interface OrderTileProps {
  order: TaskExec;
  asset: AvailableAsset | undefined;
  taskLogons: Array<TaskLogon> | undefined;
}

const OrderTile: FunctionComponent<OrderTileProps> = ({
  order,
  asset,
  taskLogons,
}) => {
  const utilExec = useAppSelector((state) =>
    selectUtilizationExecsByAssetId(state, order.assetId)
  );
  const users = useAppSelector(selectUsers);

  const [isPrimaryView, setIsPrimaryView] = useState<boolean>(true);
  React.useEffect(() => {
    const timer = setTimeout(
      () => setIsPrimaryView((val) => !val),
      env.REACT_APP_TILE_INTERVAL_TIME
    );
    return () => clearTimeout(timer);
  }, [isPrimaryView]);

  return (
    <Paper elevation={2} square>
      <TileContent>
        <TileHeader>
          <Typography variant="h6">{order.currentTaskName}</Typography>
        </TileHeader>
        <InfoContainer>
          <Box height="100%" minHeight="inherit">
            <>
              <StatusBar color={order.currentTaskStateColor ?? ""} />{" "}
              <IconContainer>
                <JobsIcon />
              </IconContainer>
              <Tooltip key={order.assetPosition} title={getTaskNameText(order)}>
                <div>
                  <StyledLinesEllipsis
                    text={getTaskNameText(order)}
                    maxLine="3"
                    ellipsis="..."
                    trimRight
                    basedOn="letters"
                  />
                </div>
              </Tooltip>
            </>
            {isPrimaryView ? (
              <ProgressBar
                key={order.assetPosition}
                total={Number(order.currentTaskQuantityRequired)}
                ended={Number(order.currentTaskQuantityProduced)}
              />
            ) : (
              <ValueContainer>
                <Typography variant="body2" key={order.assetPosition}>
                  {order.currentTaskQuantityProduced}/
                  {order.currentTaskQuantityRequired}
                </Typography>
              </ValueContainer>
            )}
          </Box>
        </InfoContainer>
        <InfoContainer>
          {utilExec && asset && (
            <Box>
              <>
                <StatusBar color={utilExec.utilStateColor ?? ""} />
                <IconContainer>
                  <EntityIcon />
                </IconContainer>
                <Tooltip title={getAssetStateText(asset, utilExec)}>
                  <div>
                    <StyledLinesEllipsis
                      text={getAssetStateText(asset, utilExec)}
                      maxLine="3"
                      ellipsis="..."
                      trimRight
                      basedOn="letters"
                    />
                  </div>
                </Tooltip>
              </>
              <ValueContainer>
                <Typography variant="body2">
                  {utilExec?.currentUtilRawStartedAt && (
                    <span>
                      {getDateText(
                        isPrimaryView,
                        utilExec?.currentUtilRawStartedAt
                      )}
                    </span>
                  )}
                </Typography>
              </ValueContainer>
            </Box>
          )}
        </InfoContainer>
        <InfoBorderContainer>
          {taskLogons && taskLogons?.length > 0 ? (
            taskLogons?.map(
              (tl) =>
                tl.userId && (
                  <Box key={tl.userId}>
                    <>
                      <StatusBar color="success" />
                      <IconContainer>
                        <OperatorsIcon />
                      </IconContainer>
                      <Typography variant="body2">
                        {getUserData(users, tl.userId)}
                      </Typography>
                    </>
                    <ValueContainer>
                      <Typography variant="body2">
                        <span>{getDateText(isPrimaryView, tl.startedAt)}</span>
                      </Typography>
                    </ValueContainer>
                  </Box>
                )
            )
          ) : (
            <Box>
              <StatusBar />
              <IconContainer>
                <OperatorsIcon />
              </IconContainer>
              <Typography variant="body2">{t("No operators")}</Typography>
            </Box>
          )}
        </InfoBorderContainer>
      </TileContent>
    </Paper>
  );
};

export default OrderTile;
