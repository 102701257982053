import React, { useEffect, useMemo } from "react";
import { selectCustomTabComponents } from "../../../store/common/customTabsSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  selectRefreshTab,
  tabRefreshed,
} from "../../../store/common/dialogs/editableTabDialog/editableTabSlice";
import { selectAvailableFlatAssets } from "../../../store/user/assets-selection/assetsSelectionSlice";
import { useLocation } from "react-router-dom";
import ReadonlyCustomTileComponent from "../../common/custom-tile/ReadonlyCustomTileComponent";
import { TilesContainer } from "../../common/tiles/TilesContainer";

interface CustomTabComponentProps {}

const CustomTabComponent: React.FC<CustomTabComponentProps> = () => {
  const dispatch = useAppDispatch();
  const customTabs = useAppSelector(selectCustomTabComponents);
  const availableAssets = useAppSelector(selectAvailableFlatAssets);
  const refreshTab = useAppSelector(selectRefreshTab);
  const location = useLocation();

  const tileItem = useMemo(
    () => customTabs.find((c) => c.path === location.pathname),
    [customTabs, location.pathname]
  );

  useEffect(() => {
    if (refreshTab) {
      dispatch(tabRefreshed(false));
    }
  }, [dispatch, refreshTab]);

  return (
    <TilesContainer>
      {!refreshTab &&
        availableAssets.map(
          (o) =>
            tileItem && (
              <ReadonlyCustomTileComponent
                key={tileItem.path + "-" + o.id}
                code={tileItem.code}
                asset={o}
              />
            )
        )}
    </TilesContainer>
  );
};

export default CustomTabComponent;
