import WarningIcon from "@mui/icons-material/Warning";
import { Box, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import TopicType from "../../../helpers/enums/topicType";
import {
  AlarmOptions,
  GaugeOptions,
  MqttTopicValue,
  ProgressOptions,
  TopicDefinition,
} from "../../../store/common/mqttSlice";
import AlarmComponent from "./AlarmComponent";
import GaugeComponent from "./GaugeComponent";
import ProgressBarComponent from "./ProgressBarComponent";
import { env } from "../../../env";

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: "3rem",
  paddingLeft: "0.5rem",
  paddingRight: "0.5rem",
}));

const ValueTypography = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  "&.MuiTypography-body2": {
    marginRight: "0.5rem",
  },
}));

const StyledIcon = styled("img")(({ theme }) => ({
  marginRight: "0.3rem",
  width: 20,
  height: 20,
}));

interface ParameterProps {
  topicDefinition: TopicDefinition | undefined;
  topic: MqttTopicValue | undefined;
  assetParameters: Array<MqttTopicValue> | undefined;
}

const ParameterComponent: React.FC<ParameterProps> = ({
  topicDefinition,
  topic,
  assetParameters,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {topicDefinition?.type !== TopicType.HIDDEN && (
        <Root>
          <Box
            display="flex"
            alignItems="center"
            maxHeight="35px"
            height="35px"
          >
            {topicDefinition?.icon && (
              <StyledIcon
                src={`${env.REACT_APP_ICONS_URL}/${topicDefinition?.icon}`}
                alt=""
              />
            )}
            <Typography variant="body2">
              {t(topicDefinition?.name || "")}
            </Typography>
          </Box>
          {topicDefinition?.errors !== undefined ? (
            <Tooltip
              title={t("Validation error") + ": " + topicDefinition.errors}
              arrow
            >
              <WarningIcon color="error" fontSize="large"></WarningIcon>
            </Tooltip>
          ) : (
            <>
              {topic?.value !== undefined && (
                <>
                  {topicDefinition?.type === TopicType.TEXT && (
                    <ValueTypography variant="body2" gutterBottom align="right">
                      {topic?.value}
                    </ValueTypography>
                  )}
                  {topicDefinition?.type === TopicType.GAUGE &&
                    topicDefinition?.options &&
                    (topicDefinition?.options as GaugeOptions).max !==
                      undefined && (
                      <GaugeComponent
                        value={Number(topic?.value)}
                        options={topicDefinition.options as GaugeOptions}
                        assetParameters={assetParameters}
                      ></GaugeComponent>
                    )}
                  {topicDefinition?.type === TopicType.PROGRESS &&
                    topicDefinition?.options &&
                    (topicDefinition?.options as ProgressOptions).max !==
                      undefined && (
                      <ProgressBarComponent
                        value={Number(topic?.value)}
                        options={topicDefinition.options as ProgressOptions}
                        assetParameters={assetParameters}
                      ></ProgressBarComponent>
                    )}
                  {topicDefinition?.type === TopicType.ALARM &&
                    topicDefinition?.options &&
                    (topicDefinition?.options as AlarmOptions).alarmValue !==
                      undefined && (
                      <AlarmComponent
                        value={Number(topic?.value)}
                        options={topicDefinition.options as AlarmOptions}
                      ></AlarmComponent>
                    )}
                </>
              )}
            </>
          )}
        </Root>
      )}
    </>
  );
};

export default ParameterComponent;
