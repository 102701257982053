import { env } from "../env";
import "@mui/lab/themeAugmentation";
import { createAppTheme } from "@improdis/core";

const appTheme = createAppTheme(env.REACT_APP_THEME_TYPE);

export const chartBackgroundColor = appTheme.palette.chart?.background;
export const chartGoodColor = appTheme.palette.chart?.success;
export const chartBadColor = appTheme.palette.chart?.error;
export const chartWarningColor = appTheme.palette.chart?.warning;
export const chartAlphaValue = appTheme.palette.chart?.alpha;

// colors for AssetKpisComponent
export const oeeBorderColor: string = "rgba(0, 193, 222, 1)";
export const oeeBackgroundColor: string = "rgba(0, 193, 222, 0.3)";
export const availabilityBorderColor: string = "rgba(0, 37, 154, 1)";
export const availabilityBackgroundColor: string = "rgba(0, 37, 154, 0.3)";
export const performanceBorderColor: string = "rgba(187, 107, 217, 1)";
export const performanceBackgroundColor: string = "rgba(187, 107, 217, 0.3)";
export const qualityBorderColor: string = "rgba(39, 174, 96, 1)";
export const qualityBackgroundColor: string = "rgba(39, 174, 96, 0.4)";

export default appTheme;
