import { HubConnectionState } from "@microsoft/signalr";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { AssetStateChangedSocketDto } from "../../../services/sockets/hub-services/utilization/assetsAllService";

export interface AssetsState {
  connectionStatus: HubConnectionState;
}

export const initialState: AssetsState = {
  connectionStatus: HubConnectionState.Disconnected,
};

export const assetsSlice = createSlice({
  name: "sockets",
  initialState,
  reducers: {
    assetsConnectionStatusChanged: (
      state,
      action: PayloadAction<HubConnectionState>
    ) => {
      state.connectionStatus = action.payload;
    },
    assetStateChangedSocketEvent: (
      state,
      action: PayloadAction<AssetStateChangedSocketDto>
    ) => {},
  },
});

export const { assetsConnectionStatusChanged, assetStateChangedSocketEvent } =
  assetsSlice.actions;

export const selectAssetsConnectionStatus = createSelector(
  (state: RootState) => state.sockets.utilization.assets.connectionStatus,
  (connectionStatus) => connectionStatus
);

export default assetsSlice.reducer;
