import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={48}
      viewBox="0 0 20 20"
      width={48}
      {...props}
    >
      <path fill="none" d="M0 0h20v20H0z" />
      <path d="M10 4v1h6v10h-6v1h6c.55 0 1-.45 1-1V5c0-.55-.45-1-1-1h-6z" />
      <path d="M9.5 6.5l-.71.71 2.3 2.29H3v1h8.09l-2.3 2.29.71.71L13 10z" />
    </svg>
  );
}

export default SvgComponent;
