import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AsyncValue, RootState } from "../..";
import { GetUtilLogsByAssetIdQueryDto } from "../../../services/httpService";
import { getCurrentShiftUtilEventsByAssetId } from "../../../services/main/utilizationEventsService";
// import { selectSelectedAssetId } from "../../user/assetSelectionSlice";
import _ from "lodash";
import OeeDefinition from "../../../helpers/enums/oeeDefinitionEnum";

export interface UtilEvent extends GetUtilLogsByAssetIdQueryDto {}
export type UtilEventStateSummary = Pick<
  GetUtilLogsByAssetIdQueryDto,
  "duration" | "utilStateName" | "utilStateColor"
>;
export type UtilEventOverTime = Pick<
  GetUtilLogsByAssetIdQueryDto,
  | "startedAt"
  | "finishedAt"
  | "duration"
  | "utilRawName"
  | "utilStateName"
  | "utilStateColor"
>;
export interface UtilEventDowntime
  extends Pick<
    GetUtilLogsByAssetIdQueryDto,
    "duration" | "utilRawName" | "utilStateName" | "utilStateColor"
  > {
  count: number;
}

export interface UtilEventsState {
  utilEvents: AsyncValue<Array<UtilEvent>>;
  selectedUtilEventLogId: string | null; //logId
  pendingAdd: boolean;
  pendingSplit: boolean;
  pendingCorrect: boolean;
}

export const initialState: UtilEventsState = {
  utilEvents: {
    value: [],
    pending: false,
  },
  selectedUtilEventLogId: null,
  pendingAdd: false,
  pendingSplit: false,
  pendingCorrect: false,
};

interface LoadUtilEventParams {
  assetId: string;
}

export const loadUtilEventsForCurrentShiftAsync = createAsyncThunk(
  "utilEvents/loadUtilEventsForCurrentShiftAsync",
  async (params: LoadUtilEventParams, { getState, dispatch }) => {
    const result = await getCurrentShiftUtilEventsByAssetId(
      params.assetId,
      true
    );
    return result;
  }
);

export const utilEventsSlice = createSlice({
  name: "utilEvents",
  initialState,
  reducers: {
    utilEventSelected: (state, action: PayloadAction<string>) => {
      state.selectedUtilEventLogId = action.payload;
    },
    utilEventsSelectionCleared: (state, action: PayloadAction<void>) => {
      state.selectedUtilEventLogId = null;
    },
    currentUtilEventDurationUpdated: (state, action: PayloadAction<number>) => {
      const seconds = action.payload;
      state.utilEvents.value = state.utilEvents.value.map((ue) =>
        !ue.finishedAt ? { ...ue, duration: ue.duration + seconds } : ue
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadUtilEventsForCurrentShiftAsync.pending, (state, action) => {
        state.utilEvents.pending = true;
      })
      .addCase(
        loadUtilEventsForCurrentShiftAsync.fulfilled,
        (state, action) => {
          state.utilEvents.value = action.payload!;
          state.utilEvents.pending = false;
        }
      )
      .addCase(loadUtilEventsForCurrentShiftAsync.rejected, (state, action) => {
        state.utilEvents.value = [];
        state.utilEvents.pending = false;
      });
  },
});

export const {
  utilEventSelected,
  utilEventsSelectionCleared,
  currentUtilEventDurationUpdated,
} = utilEventsSlice.actions;

export const selectUtilEvents = createSelector(
  (state: RootState) => state.main.utilizationEvents.utilEvents.value,
  (utilEvents) => utilEvents
);

export const selectUtilEventsPending = createSelector(
  (state: RootState) => state.main.utilizationEvents.utilEvents.pending,
  (utilEventsPending) => utilEventsPending
);

export const selectSelectedUtilEventLogId = createSelector(
  (state: RootState) => state.main.utilizationEvents.selectedUtilEventLogId,
  (selectedUtilEventLogId) => selectedUtilEventLogId
);

export const selectSelectedUtilEvent = createSelector(
  (state: RootState) => state.main.utilizationEvents.utilEvents,
  selectSelectedUtilEventLogId,
  (utilHistories, selectedUtilEventLogId) =>
    utilHistories.value.find((uh) => uh.logId === selectedUtilEventLogId)
);

export const selectUtilEventsPendingAdd = createSelector(
  (state: RootState) => state.main.utilizationEvents.pendingAdd,
  (pendingAdd) => pendingAdd
);

export const selectUtilEventsPendingCorrect = createSelector(
  (state: RootState) => state.main.utilizationEvents.pendingCorrect,
  (pendingCorrect) => pendingCorrect
);

export const selectUtilEventsPendingSplit = createSelector(
  (state: RootState) => state.main.utilizationEvents.pendingSplit,
  (pendingSplit) => pendingSplit
);

//CHARTS

export const selectUtilEventsStateSummary = createSelector(
  selectUtilEvents,
  (utilEvents) => {
    const utilEventsDowntimes = _.uniqBy(utilEvents, "utilStateName").map(
      (v) =>
        ({
          utilStateName: v.utilStateName,
          utilStateColor: v.utilStateColor,
          duration: _.sumBy(
            utilEvents.filter((d) => d.utilStateName === v.utilStateName),
            "duration"
          ),
        } as UtilEventStateSummary)
    );
    return utilEventsDowntimes;
  }
);

export const selectUtilEventsOverTime = createSelector(
  selectUtilEvents,
  (utilEvents) => utilEvents.map((ue) => ({ ...ue } as UtilEventOverTime))
);

export const selectUtilEventsDowntimes = createSelector(
  selectUtilEvents,
  (utilEvents) => {
    const downtimes = utilEvents.filter(
      (ue) => Number(ue.oeeDefinition) === OeeDefinition.Downtime
    );
    const utilEventsDowntimes = _.uniqBy(downtimes, "utilRawName").map(
      (v) =>
        ({
          utilRawName: v.utilRawName,
          utilStateName: v.utilStateName,
          utilStateColor: v.utilStateColor,
          duration: _.sumBy(
            downtimes.filter((d) => d.utilRawName === v.utilRawName),
            "duration"
          ),
          count: downtimes.filter((d) => d.utilRawName === v.utilRawName)
            .length,
        } as UtilEventDowntime)
    );
    return utilEventsDowntimes;
  }
);

export default utilEventsSlice.reducer;
