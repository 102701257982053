import * as React from "react";

function LogoWithSignature(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={177.2686}
      height={255.984}
      viewBox="0 0 147.739 213.32"
      {...props}
    >
      <defs>
        <style>
          {`.im_viewer__a{fill:#00259a}.im_viewer__b{fill:#00c1de}`}
        </style>
      </defs>
      <path
        className="im_viewer__a"
        d="M52.136 206.184L46.568 190.6h-4.512l8 22.4h4.16l8-22.4H57.7zM68.808 190.6h-4.224v3.744h4.224zm0 5.888h-4.224V213h4.224zm18.528 9.7v-2.56c0-4.576-2.624-7.584-7.36-7.584s-7.36 3.008-7.36 7.584v2.112c0 4.572 2.624 7.58 7.36 7.58 4.032 0 6.528-2.176 7.2-5.632h-4.288a2.881 2.881 0 01-2.912 1.824 2.935 2.935 0 01-3.136-3.232v-.1zm-7.36-6.336a2.838 2.838 0 013.1 2.816v.032H76.84v-.032a2.9 2.9 0 013.136-2.82zM103.112 213h4.288l4.352-16.512h-4.384l-2.468 10.464-2.4-10.464h-4.16l-2.4 10.464-2.46-10.464H89.1L93.448 213h4.288l2.688-11.744zm25.152-6.816v-2.56c0-4.576-2.624-7.584-7.36-7.584s-7.36 3.008-7.36 7.584v2.112c0 4.576 2.624 7.584 7.356 7.584 4.032 0 6.528-2.176 7.2-5.632h-4.284a2.881 2.881 0 01-2.916 1.824 2.935 2.935 0 01-3.132-3.232v-.1zm-7.364-6.336a2.838 2.838 0 013.1 2.816v.032h-6.24v-.032a2.9 2.9 0 013.14-2.816zm15.36 3.68a2.966 2.966 0 013.136-3.2h1.984V196.2h-1.28a4.088 4.088 0 00-3.84 2.08v-1.792h-4.22V213h4.224z"
      />
      <path
        className="im_viewer__b"
        d="M10.6 190.6H6.312V213H10.6zm19.008 12.768a2.961 2.961 0 013.04-3.2 2.961 2.961 0 013.04 3.2V213h4.224v-9.792c0-4.832-3.072-7.168-7.264-7.168a6.268 6.268 0 00-5.148 2.176 5.309 5.309 0 00-4.448-2.176 4.732 4.732 0 00-3.752 1.824v-1.376h-4.22V213h4.22v-9.632a2.961 2.961 0 013.04-3.2 2.961 2.961 0 013.04 3.2V213h4.224z"
      />
      <path
        className="im_viewer__a"
        d="M74.7 147.6h-2.061l-50.324-20.866-1.485-1.487L0 74.811v-2.064l20.837-50.4 1.485-1.487L72.637 0H74.7l50.322 20.868 1.485 1.487 20.837 50.4v2.064l-20.844 50.39-1.485 1.487zm-49.288-25.488l48.254 20.017 48.261-20.015 19.987-48.335-19.987-48.288-48.261-20.06-48.254 20.06-19.99 48.287z"
      />
      <path
        className="im_viewer__a"
        d="M101.041 100.725H29.903l-2.589-2.568V49.291l2.589-2.528h87.567l2.558 2.528v32.6h-5.141V51.863H32.455v43.761h68.587z"
      />
      <path
        className="im_viewer__a"
        d="M103.711 60.141v26.756H90.333v-4.459h8.919V64.6h-8.919v-4.459z"
      />
      <path
        className="im_viewer__a"
        d="M90.333 60.141v26.756h13.378v-4.459h-8.919V64.6h8.919v-4.459z"
      />
      <path
        className="im_viewer__b"
        d="M85.722 55.682v35.675h-8.919v-4.459h4.459V60.142h-4.459v-4.46zM63.325 55.682v35.675h13.378v-4.459h-8.919V60.142h8.919v-4.46z"
      />
      <path
        className="im_viewer__a"
        d="M45.319 60.141v26.756h13.378v-4.459h-8.919V64.6h8.919v-4.459z"
      />
      <path
        className="im_viewer__a"
        d="M58.697 60.141v26.756H45.319v-4.459h8.919V64.6h-8.919v-4.459z"
      />
    </svg>
  );
}

export default LogoWithSignature;
