import { env } from "../../env";
import { handleExceptionMessage } from "../../helpers/handleExceptionMessage";
import { instance } from "../axiosInstance";
import {
  LogOnTaskRequest,
  LogOffTaskRequest,
  TaskLogonDTO,
  TaskLogonsClient,
} from "../httpService";

const http = new TaskLogonsClient(env.REACT_APP_MES_API_URL, instance);

export const getTaskLogons = async (
  taskId?: string,
  userId?: string,
  isOngoing?: boolean,
  startedAtFrom?: Date,
  startedAtTo?: Date,
  finishedAtFrom?: Date,
  finishedAtTo?: Date,
  throwError: boolean = true
): Promise<Array<TaskLogonDTO> | undefined> => {
  try {
    const result = await http.getTaskLogons(
      taskId,
      userId,
      isOngoing,
      startedAtFrom,
      startedAtTo,
      finishedAtFrom,
      finishedAtTo
    );
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const logOffTask = async (
  taskId: string,
  throwError: boolean = true
): Promise<void> => {
  try {
    await http.logOffTask(new LogOffTaskRequest({ taskId: taskId }));
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const logOnTask = async (
  taskId: string,
  throwError: boolean = true
): Promise<void> => {
  try {
    await http.logOnTask(new LogOnTaskRequest({ taskId: taskId }));
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};
