import * as React from "react";
import { UtilExec } from "../../../../store/main/util-execs/utilExecsSlice";
import UtilStateChartComponent from "./UtilStateChartComponent";
import AssetKPIsComponent from "./AssetKPIsComponent";

export interface KpiAssetTileComponentProps {
  utilExec: UtilExec | undefined;
}

const KpiAssetTileComponent: React.FC<KpiAssetTileComponentProps> = ({
  utilExec,
}) => {
  return (
    <>
      <AssetKPIsComponent assetId={utilExec?.assetId} />
      <UtilStateChartComponent assetId={utilExec?.assetId} />
    </>
  );
};

export default KpiAssetTileComponent;
