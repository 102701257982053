import i18n from "../../i18n";
import { WorkOrderIcon, OperatorsIcon, EntitiesIcon } from "@improdis/core";

export interface TabOption {
  label: string;
  path: string;
  Icon: any;
}

export const baseTabs = [
  {
    label: i18n.t("Assets"),
    path: "/assets",
    Icon: EntitiesIcon,
  },
  {
    label: i18n.t("Orders"),
    path: "/orders",
    Icon: WorkOrderIcon,
  },
  {
    label: i18n.t("Operators"),
    path: "/operators",
    Icon: OperatorsIcon,
  },
] as Array<TabOption>;
