import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  JsonHubProtocol,
} from "@microsoft/signalr";
import { AnyAction, Dispatch } from "redux";
import { env } from "../../../../env";
import {
  assetLogOffRegisteredSocketEvent,
  assetLogOnRegisteredSocketEvent,
  assetLogonConnectionStatusChanged,
} from "../../../../store/sockets/access-management/assetLogonsSlice";
import convertStringDates from "../../dateParser";

export interface AssetLogonRegisteredSocketDto {
  assetId: string;
  userId: string;
  connectionId: string;
  startTime: Date;
}

export interface AssetLogoffRegisteredSocketDto {
  assetId: string;
  userId: string;
  connectionId: string;
  finishedAt: Date;
}

export interface IAssetLogonAllService {
  init(): void;
  connect(dispatch: Dispatch<AnyAction>, getState: any): void;
  disconnect(): void;
  subscribe(dispatch: Dispatch<AnyAction>, getState: any): void;
  unsubscribe(): void;
  leaveAllGroup(): void;
  joinAllGroup(dispatch: Dispatch<AnyAction>): void;
}

const assetLogonHubUrl = `${env.REACT_APP_MES_API_URL}/access-management/asset-logons`;

const protocol = new JsonHubProtocol();

const transport = HttpTransportType.WebSockets | HttpTransportType.LongPolling;

const options = {
  transport,
};

export class AssetLogonAllService implements IAssetLogonAllService {
  public connectionAssetLogonHub: HubConnection | undefined;
  private curAssetId: string | undefined;

  constructor() {
    this.init();
  }

  init() {
    this.connectionAssetLogonHub = new HubConnectionBuilder()
      .withUrl(assetLogonHubUrl, options)
      .withHubProtocol(protocol)
      .withAutomaticReconnect()
      .build();
  }

  async start(dispatch: Dispatch<AnyAction>) {
    try {
      await this.connectionAssetLogonHub?.start().then(async (a) => {
        dispatch(
          assetLogonConnectionStatusChanged(HubConnectionState.Connected)
        );
      });
    } catch (error) {
      console.error(error);
    }
  }

  async connect(dispatch: Dispatch<AnyAction>, getState: any) {
    if (
      this.connectionAssetLogonHub?.state !== HubConnectionState.Disconnected
    ) {
      this.unsubscribe();
      this.init();
      this.subscribe(dispatch, getState());
    }
    await this.start(dispatch);

    this.connectionAssetLogonHub?.onreconnecting((error) => {
      console.error(error);
      dispatch(
        assetLogonConnectionStatusChanged(HubConnectionState.Reconnecting)
      );
    });

    this.connectionAssetLogonHub?.onreconnected((connectionId) => {
      dispatch(assetLogonConnectionStatusChanged(HubConnectionState.Connected));
    });

    this.connectionAssetLogonHub?.onclose((error) => {
      console.error(error);
      dispatch(
        assetLogonConnectionStatusChanged(HubConnectionState.Disconnected)
      );
    });
  }

  disconnect() {
    this.unsubscribe();
    this.connectionAssetLogonHub?.stop();
  }

  subscribe(dispatch: Dispatch<AnyAction>, getState: any) {
    this.connectionAssetLogonHub?.on("asset-logon-registered", (message) => {
      if (message != null) {
        convertStringDates(message, ["startTime"]);
        dispatch(assetLogOnRegisteredSocketEvent(message));
      }
    });

    this.connectionAssetLogonHub?.on("asset-logoff-registered", (message) => {
      if (message != null) {
        convertStringDates(message, ["finishedAt"]);
        dispatch(assetLogOffRegisteredSocketEvent(message));
      }
    });
  }

  async joinAllGroup(): Promise<void> {
    if (this.connectionAssetLogonHub?.state === HubConnectionState.Connected) {
      this.connectionAssetLogonHub?.invoke("JoinAllGroup");
    }
  }

  leaveAllGroup = () => {
    if (this.connectionAssetLogonHub?.state === HubConnectionState.Connected) {
      this.connectionAssetLogonHub?.invoke("LeaveAllGroup");
    }
  };

  unsubscribe = () => {
    this.connectionAssetLogonHub?.off("asset-logon-registered");
    this.connectionAssetLogonHub?.off("asset-logoff-registered");
  };
}
