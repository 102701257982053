import { EntityIcon, JobsIcon, OperatorsIcon } from "@improdis/core";
import { Box, Tooltip, Typography } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getDateText,
  getUserData,
} from "../../../../helpers/tileDisplayFunctions";
import { selectUsers } from "../../../../store/definitions/usersSlice";
import { useAppSelector } from "../../../../store/hooks";
import { AssetLogon } from "../../../../store/main/asset-logons/assetsLogonsSlice";
import { TaskExec } from "../../../../store/main/task-execs/taskExecsSlice";
import { UtilExec } from "../../../../store/main/util-execs/utilExecsSlice";
import { StyledLinesEllipsis } from "../../../common/LineEllipsis";
import ProgressBar from "../../../common/ProgressBar";
import { IconContainer } from "../../../common/tiles/IconContainer";
import { InfoBorderContainer } from "../../../common/tiles/InfoBorderContainer";
import { ReasonContainer } from "../../../common/tiles/ReasonContainer";
import { StatusBar } from "../../../common/tiles/StatusBar";
import { ValueContainer } from "../../../common/tiles/ValueContainer";
import { selectMqttMode } from "../../../../store/common/mqttSlice";
import AssetMqttComponent from "./AssetMqttComponent";
import { env } from "../../../../env";

export interface BasicAssetTileComponentProps {
  assetId: string;
  utilExec: UtilExec | undefined;
  taskExecs: Array<TaskExec>;
  assetLogons: Array<AssetLogon>;
}

const BasicAssetTileComponent: React.FC<BasicAssetTileComponentProps> = ({
  assetId,
  utilExec,
  taskExecs,
  assetLogons,
}) => {
  const { t } = useTranslation();
  const [isPrimaryView, setIsPrimaryView] = useState<boolean>(true);
  const users = useAppSelector(selectUsers);
  const mqttMode = useAppSelector(selectMqttMode);
  React.useEffect(() => {
    const timer = setTimeout(
      () => setIsPrimaryView((val) => !val),
      env.REACT_APP_TILE_INTERVAL_TIME
    );
    return () => clearTimeout(timer);
  }, [isPrimaryView]);

  return (
    <>
      <ReasonContainer>
        {utilExec?.utilStateName ? (
          <Box>
            <StatusBar color={utilExec.utilStateColor ?? ""} />
            <IconContainer>
              <EntityIcon />
            </IconContainer>
            <Typography variant="body2">
              {t(utilExec.utilRawName || "")}
            </Typography>
            <ValueContainer>
              <Typography variant="body2">
                {utilExec.currentUtilRawStartedAt && (
                  <span>
                    {getDateText(
                      isPrimaryView,
                      utilExec.currentUtilRawStartedAt
                    )}
                  </span>
                )}
              </Typography>
            </ValueContainer>
          </Box>
        ) : (
          <Typography variant="body2">{t("No State")}</Typography>
        )}
      </ReasonContainer>
      <InfoBorderContainer>
        {taskExecs.length === 0 ? (
          <Box>
            <StatusBar />
            <IconContainer>
              <JobsIcon />
            </IconContainer>
            <Typography variant="body2">{t("No running jobs")}</Typography>
          </Box>
        ) : (
          taskExecs.map((te) => (
            <Box display="flex" minHeight="60px" key={te.currentTaskId}>
              <>
                <StatusBar color="success" />
                <IconContainer>
                  <JobsIcon />
                </IconContainer>
                <Tooltip
                  key={te.assetPosition}
                  title={`${te?.currentTaskName} > ${te?.currentTaskItemName} `}
                >
                  <div>
                    <StyledLinesEllipsis
                      text={`${te?.currentTaskName} > ${te?.currentTaskItemName} `}
                      maxLine="3"
                      ellipsis="..."
                      trimRight
                      basedOn="letters"
                    />
                  </div>
                </Tooltip>
              </>
              {isPrimaryView ? (
                <ProgressBar
                  key={te.assetPosition}
                  total={Number(te?.currentTaskQuantityRequired)}
                  ended={Number(te?.currentTaskQuantityProduced)}
                />
              ) : (
                <ValueContainer>
                  <Typography variant="body2" key={te.assetPosition}>
                    {te?.currentTaskQuantityProduced}/
                    {te?.currentTaskQuantityRequired}
                  </Typography>
                </ValueContainer>
              )}
            </Box>
          ))
        )}
      </InfoBorderContainer>
      <InfoBorderContainer>
        {assetLogons.length === 0 ? (
          <Box>
            <StatusBar />
            <IconContainer>
              <OperatorsIcon />
            </IconContainer>
            <Typography variant="body2">{t("No operators")}</Typography>
          </Box>
        ) : (
          assetLogons.map((al) => (
            <Box key={al.userId + "|" + al.connectionId}>
              <StatusBar color="success" />
              <IconContainer>
                <OperatorsIcon />
              </IconContainer>
              <Typography variant="body2">
                {getUserData(users, al.userId)}
              </Typography>
              <ValueContainer>
                <Typography variant="body2">
                  {al.startedAt && (
                    <span>{getDateText(isPrimaryView, al.startedAt)}</span>
                  )}
                </Typography>
              </ValueContainer>
            </Box>
          ))
        )}
      </InfoBorderContainer>
      {mqttMode && <AssetMqttComponent assetId={assetId}></AssetMqttComponent>}
    </>
  );
};

export default BasicAssetTileComponent;
