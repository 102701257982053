import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  JsonHubProtocol,
} from "@microsoft/signalr";
import { AnyAction, Dispatch } from "redux";
import { env } from "../../../../env";
import {
  utilizationEventCorrectedSocketEvent,
  utilizationEventsConnectionStatusChanged,
  utilizationEventSplittedSocketEvent,
} from "../../../../store/sockets/utilization/utilizationEventsSlice";
import { selectIsAuthorized } from "../../../../store/user/authSlice";
import convertStringDates from "../../dateParser";
import RetryPolicy from "../../retryPolicy";

export interface UtilizationEventFinishedSocketDto {
  assetId: string;
  logId: string;
  finishedAt: Date;
}

export interface UtilizationEventCorrectedSocketDto {
  utilLogId: string;
  newUtilLogId: string;
  assetId: string;
  utilStateId: string;
  utilRawId: string;
  startedAt: Date;
  finishedAt: Date | null;
  comment: string | null;
  sourceLogId: string;
  isFinal: boolean;
  createdBy: string;
}

export interface UtilizationEventSplittedSocketDto {
  logId: string;
  olderUtilLog: UtilizationEventSplittedUtilLogDto;
  newerUtilLog: UtilizationEventSplittedUtilLogDto;
}

interface UtilizationEventSplittedUtilLogDto {
  assetId: string;
  utilStateId: string;
  utilRawId: string;
  startedAt: Date;
  finishedAt: Date | null;
  comment: string | null;
  sourceLogId: string;
  isFinal: boolean;
  createdBy: string;
}

interface IUtilizationEventsAllService {
  init(): void;
  connect(dispatch: Dispatch<AnyAction>, getState: any): void;
  disconnect(): void;
  subscribe(dispatch: Dispatch<AnyAction>, getState: any): void;
  unsubscribe(): void;
  joinAllGroup(): void;
  leaveAllGroup(): void;
}

const assetsHubUrl = `${env.REACT_APP_MES_API_URL}/utilization/utilization-events`;

const retryPolicy = new RetryPolicy();

const protocol = new JsonHubProtocol();

const transport = HttpTransportType.WebSockets | HttpTransportType.LongPolling;

const options = {
  transport,
};

export class UtilizationEventsAllService
  implements IUtilizationEventsAllService
{
  public connectionUtilizationEventsHub: HubConnection | undefined;

  constructor() {
    this.init();
  }

  init() {
    this.connectionUtilizationEventsHub = new HubConnectionBuilder()
      .withUrl(assetsHubUrl, options)
      .withHubProtocol(protocol)
      .withAutomaticReconnect(retryPolicy)
      .build();
  }

  async start(dispatch: Dispatch<AnyAction>) {
    try {
      await this.connectionUtilizationEventsHub?.start().then(async (a) => {
        dispatch(
          utilizationEventsConnectionStatusChanged(HubConnectionState.Connected)
        );
      });
    } catch (error) {
      console.error(error);
    }
  }

  async connect(dispatch: Dispatch<AnyAction>, getState: any) {
    if (
      this.connectionUtilizationEventsHub?.state !==
      HubConnectionState.Disconnected
    ) {
      this.unsubscribe();
      this.init();
      this.subscribe(dispatch, getState());
    }

    await this.start(dispatch);

    this.connectionUtilizationEventsHub?.onreconnecting((error) => {
      console.error(error);
      dispatch(
        utilizationEventsConnectionStatusChanged(
          HubConnectionState.Reconnecting
        )
      );
    });

    this.connectionUtilizationEventsHub?.onreconnected((connectionId) => {
      dispatch(
        utilizationEventsConnectionStatusChanged(HubConnectionState.Connected)
      );
    });

    this.connectionUtilizationEventsHub?.onclose(async (error) => {
      error && console.error(error);
      const isAuthorized = selectIsAuthorized(getState());
      if (isAuthorized) {
        //STILL LOGGED IN
        await this.start(dispatch);
      } else {
        dispatch(
          utilizationEventsConnectionStatusChanged(
            HubConnectionState.Disconnected
          )
        );
      }
    });
  }

  disconnect() {
    this.unsubscribe();
    this.connectionUtilizationEventsHub?.stop();
  }

  subscribe(dispatch: Dispatch<AnyAction>, getState: any) {
    this.connectionUtilizationEventsHub?.on(
      "utilization-event-corrected",
      (message) => {
        if (message != null) {
          convertStringDates(message, ["startedAt", "finishedAt"]);
          dispatch(utilizationEventCorrectedSocketEvent(message));
        }
      }
    );
    this.connectionUtilizationEventsHub?.on(
      "utilization-event-splitted",
      (message) => {
        if (message != null) {
          convertStringDates(message.olderUtilLog, ["startedAt", "finishedAt"]);
          convertStringDates(message.newerUtilLog, ["startedAt", "finishedAt"]);
          dispatch(utilizationEventSplittedSocketEvent(message));
        }
      }
    );
  }
  async joinAllGroup(): Promise<void> {
    if (
      this.connectionUtilizationEventsHub?.state ===
      HubConnectionState.Connected
    ) {
      this.connectionUtilizationEventsHub?.invoke("JoinAllGroup");
    }
  }

  leaveAllGroup = () => {
    if (
      this.connectionUtilizationEventsHub?.state ===
      HubConnectionState.Connected
    ) {
      this.connectionUtilizationEventsHub?.invoke("LeaveAllGroup");
    }
  };

  unsubscribe = () => {
    this.connectionUtilizationEventsHub?.off("utilization-event-finished");
    this.connectionUtilizationEventsHub?.off("utilization-event-corrected");
    this.connectionUtilizationEventsHub?.off("utilization-event-splitted");
  };
}
