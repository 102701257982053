import { createListenerMiddleware } from "@reduxjs/toolkit";
import { getNestedUtilizationEventHistory } from "../../../services/main/utilizationEventsService";
import { assetStateChangedSocketEvent } from "../../sockets/utilization/assetsSlice";
import { UtilExec, utilExecChanged } from "./utilExecsSlice";

const utilExecsListener = createListenerMiddleware();

utilExecsListener.startListening({
  actionCreator: assetStateChangedSocketEvent,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    const { assetId, utilLogId } = action.payload;
    const utilEvent = await getNestedUtilizationEventHistory(utilLogId);
    utilEvent &&
      listenerApi.dispatch(
        utilExecChanged({
          assetId: assetId,
          comment: utilEvent.comment,
          createdByUserId: utilEvent.createdById,
          createdByUserFullName: utilEvent.createdByFullName,
          createdByUserName: utilEvent.createdByUserName,
          currentUtilRawStartedAt: utilEvent.startedAt,
          utilRawName: utilEvent.utilRawName,
          utilStateName: utilEvent.utilStateName,
          utilStateColor: utilEvent.utilStateColor,
        } as UtilExec)
      );
  },
});

export default utilExecsListener;
