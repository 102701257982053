import { ChartData } from "chart.js";
import { Moment } from "moment";
import appTheme from "../../../../themes/appTheme";

export interface Point {
  x: Moment;
  y: number | undefined;
}

export const generateChartData = (
  values: Array<Point>,
  backgroundColor: string,
  borderColor: string,
  targetValue: number | null | undefined
) => {
  const average = calculateAverage(values.map((v) => v.y));
  const targetDashedLineColor = appTheme.palette.error.light;
  const averageDashedLineColor = appTheme.palette.info.light;
  const startDate = values[0]?.x;
  const endDate = values[values.length - 1]?.x;

  return {
    datasets: [
      {
        label: "Results",
        data: values,
        pointRadius: 0,
        backgroundColor,
        borderColor,
        borderWidth: 1,
        // stepped: true,
      },
      {
        label: "Average",
        data: [
          { x: startDate, y: average },
          { x: endDate, y: average },
        ],
        borderDash: [5, 5],
        borderWidth: 1,
        pointRadius: 0,
        borderColor: averageDashedLineColor,
        fill: false,
      },
      {
        label: "Target",
        data: [
          { x: startDate, y: targetValue },
          { x: endDate, y: targetValue },
        ],
        borderDash: [5, 5],
        borderDashOffset: 5,
        borderWidth: 1,
        pointRadius: 0,
        borderColor: targetDashedLineColor,
        fill: false,
      },
    ],
  } as ChartData<"line", Array<Point>>;
};

export const determineArrowColor = (
  average: number | undefined | null,
  target: number | undefined | null,
  yellowArrowColor: string | undefined,
  redArrowColor: string | undefined,
  greenArrowColor: string | undefined
): string | undefined => {
  if (
    average !== null &&
    target !== null &&
    average !== undefined &&
    target !== undefined &&
    average > target
  ) {
    return greenArrowColor;
  } else if (
    average !== null &&
    target !== null &&
    average !== undefined &&
    target !== undefined &&
    average < target
  ) {
    return redArrowColor;
  }

  return yellowArrowColor;
};

export const calculateAverage = (arr: Array<number | undefined | null>) => {
  if (arr.length === 0) {
    return 0;
  } else if (arr.length === 1) {
    return arr[0];
  }
  const temp = arr.filter((e) => e !== undefined && e != null) as Array<number>;
  return temp.reduce((a: number, b: number) => a + b, 0) / temp.length;
};
