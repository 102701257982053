import { HubConnectionState } from "@microsoft/signalr";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AssetLogoffRegisteredSocketDto,
  AssetLogonRegisteredSocketDto,
} from "../../../services/sockets/hub-services/access-management/assetLogonAllService";

export interface AssetLogonsState {
  connectionStatus: HubConnectionState;
}

const initialState: AssetLogonsState = {
  connectionStatus: HubConnectionState.Disconnected,
};

export const assetLogonssSlice = createSlice({
  name: "sockets",
  initialState,
  reducers: {
    assetLogonConnectionStatusChanged: (
      state,
      action: PayloadAction<HubConnectionState>
    ) => {
      state.connectionStatus = action.payload;
    },
    assetLogOnRegisteredSocketEvent: (
      state,
      action: PayloadAction<AssetLogonRegisteredSocketDto>
    ) => {},
    assetLogOffRegisteredSocketEvent: (
      state,
      action: PayloadAction<AssetLogoffRegisteredSocketDto>
    ) => {},
  },
});

export const {
  assetLogonConnectionStatusChanged,
  assetLogOffRegisteredSocketEvent,
  assetLogOnRegisteredSocketEvent,
} = assetLogonssSlice.actions;

export default assetLogonssSlice.reducer;
