import { CircularProgress, styled } from "@mui/material";
import React, { FunctionComponent } from "react";

const Container = styled("div")(({ theme }) => ({
  height: "100vh",
  width: "100vw",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

interface AppSuspenseProps {}

const AppSuspense: FunctionComponent<AppSuspenseProps> = () => {
  return (
    <Container>
      <CircularProgress />
    </Container>
  );
};

export default AppSuspense;
