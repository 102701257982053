import { Paper, styled } from "@mui/material";
import React, { useState } from "react";
import LogoWithSignature from "../../helpers/logo/LogoWithSignature";
import LoginForm from "./forms/LoginForm";

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
}));

const Content = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "30rem",
  width: "25rem",
  gap: "1rem",
  padding: "1.5rem",
  boxSizing: "border-box",
}));

const Logo = styled(LogoWithSignature)(({ theme }) => ({
  width: "10rem",
  height: "10rem",
}));

interface LoginProps {}

const LoginPage: React.FC<LoginProps> = () => {
  const [pinLogInMode] = useState<boolean | undefined>(false);

  return (
    <Root>
      <Content elevation={3} square>
        <Logo />
        {pinLogInMode !== undefined && (
          <LoginForm pinLogInMode={pinLogInMode}></LoginForm>
        )}
      </Content>
    </Root>
  );
};

export default LoginPage;
