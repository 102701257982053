import { HubConnectionState } from "@microsoft/signalr";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  TaskLogoffRegisteredSocketDto,
  TaskLogonRegisteredSocketDto,
} from "../../../services/sockets/hub-services/access-management/taskLogonAllService";

export interface TaskLogonsState {
  connectionStatus: HubConnectionState;
}

const initialState: TaskLogonsState = {
  connectionStatus: HubConnectionState.Disconnected,
};

export const taskLogonssSlice = createSlice({
  name: "sockets",
  initialState,
  reducers: {
    taskLogonConnectionStatusChanged: (
      state,
      action: PayloadAction<HubConnectionState>
    ) => {
      state.connectionStatus = action.payload;
    },
    taskLogOnRegisteredSocketEvent: (
      state,
      action: PayloadAction<TaskLogonRegisteredSocketDto>
    ) => {},
    taskLogOffRegisteredSocketEvent: (
      state,
      action: PayloadAction<TaskLogoffRegisteredSocketDto>
    ) => {},
  },
});

export const {
  taskLogonConnectionStatusChanged,
  taskLogOffRegisteredSocketEvent,
  taskLogOnRegisteredSocketEvent,
} = taskLogonssSlice.actions;

export default taskLogonssSlice.reducer;
