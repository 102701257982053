import { EnglishIcon, PolishIcon } from "@improdis/core";
import { ListItemIcon, ListItemText, Menu, styled } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Typography from "@mui/material/Typography";
import React from "react";
import { useTranslation } from "react-i18next";

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  "> svg": {
    height: 20,
    width: 20,
  },
}));

export interface LoginAppBarLanguageMenuProps {}

const LoginAppBarLanguageMenu: React.FC<LoginAppBarLanguageMenuProps> = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { i18n } = useTranslation();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeLanguage = (
    event: React.MouseEvent<EventTarget>,
    lng: string
  ) => {
    handleClose();
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <IconButton onClick={handleMenu} color="inherit">
        <Typography variant="h6">
          {i18n.language.toLocaleUpperCase()}
        </Typography>
      </IconButton>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuList>
          <MenuItem onClick={(event) => handleChangeLanguage(event, "en")}>
            <StyledListItemIcon>
              <EnglishIcon />
            </StyledListItemIcon>
            <ListItemText>EN</ListItemText>
          </MenuItem>
          <MenuItem onClick={(event) => handleChangeLanguage(event, "pl")}>
            <StyledListItemIcon>
              <PolishIcon />
            </StyledListItemIcon>
            <ListItemText>PL</ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};

export default LoginAppBarLanguageMenu;
