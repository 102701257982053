import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "..";
import { env } from "../../env";
import MqttStatus from "../../helpers/enums/mqttStatus";
import TopicType from "../../helpers/enums/topicType";
import { mqttService } from "../../services/mqtt/mqttInstance";
import { CSSProperties } from "@mui/styles";

//TYPES

export enum OperatorTypes {
  GREATER_OR_EQUAL = "ge",
  LOWER_OR_EQUAL = "le",
  GREATER = "g",
  LOWER = "l",
}

interface Level {
  value: number;
  color: CSSProperties["color"];
}

export interface GaugeOptions {
  max: string;
  levels: Array<Level>;
}

export interface AlarmOptions {
  operator: OperatorTypes;
  alarmValue: number;
}

export interface ProgressOptions {
  max: string;
  levels: Array<Level>;
}

export interface TopicDefinition {
  name: string;
  value: string;
  icon: string;
  errors?: Array<string>;
  type: TopicType;
  priority: number;
  options?: GaugeOptions | AlarmOptions | ProgressOptions;
}

export interface MqttAsset {
  assetId: string;
  parameters: Array<MqttTopicValue>;
}

export interface MqttTopicValue {
  name: string;
  value: any;
}

export interface AssetTopic {
  errors?: Array<string>;
  assetId: string;
  topics: Array<TopicDefinition>;
}

interface MqttState {
  status: MqttStatus;
  assets: Array<MqttAsset>;
  errors?: Array<string>;
  definitions: Array<AssetTopic>;
}

export const initialState: MqttState = {
  status: MqttStatus.DISCONNECTED,
  definitions: [] as Array<AssetTopic>,
  assets: [] as Array<MqttAsset>,
  errors: undefined,
};

interface LoadTopicsDefinitionsParams {
  definitions: Array<AssetTopic>;
  errors?: Array<string>;
}

interface TopicValueChangedParams {
  assetId: string;
  propName: string;
  value: any;
}

export const sendValueToMqtt =
  (entId: number, topic: string, value: string, retain?: boolean): AppThunk =>
  async (dispatch, getState) => {
    mqttService.publish(
      env.REACT_APP_MQTT_PREFIX + "/" + entId + topic,
      value,
      retain
    );
  };

export const mqttSlice = createSlice({
  name: "mqtt",
  initialState,
  reducers: {
    topicsDefinitionsLoaded: (
      state,
      action: PayloadAction<LoadTopicsDefinitionsParams>
    ) => {
      state.definitions = action.payload.definitions;
      state.errors = action.payload.errors;
    },
    connected: (state, action: PayloadAction<void>) => {
      state.status = MqttStatus.CONNECTED;
    },
    disconnected: (state, action: PayloadAction<void>) => {
      state.status = MqttStatus.DISCONNECTED;
    },
    reconnecting: (state, action: PayloadAction<void>) => {
      state.status = MqttStatus.RECONNECTING;
    },
    statusChanged: (state, action: PayloadAction<MqttStatus>) => {
      state.status = action.payload;
    },
    topicValueChanged: (
      state,
      action: PayloadAction<TopicValueChangedParams>
    ) => {
      const index = state.assets.findIndex(
        (e) => e.assetId === action.payload.assetId
      );
      if (index === -1) {
        //asset not found - push asset with array of one topic value
        state.assets.push({
          assetId: action.payload.assetId,
          parameters: [
            { name: action.payload.propName, value: action.payload.value },
          ],
        });
      } else {
        //asset found
        const topicIndex = state.assets[index].parameters.findIndex(
          (e) => e.name === action.payload.propName
        );
        if (topicIndex === -1) {
          //topic not found - push new one
          state.assets[index].parameters.push({
            name: action.payload.propName,
            value: action.payload.value,
          });
        } else {
          //topic found - update existing
          state.assets[index].parameters[topicIndex] = {
            name: action.payload.propName,
            value: action.payload.value,
          };
        }
      }
    },
  },
});

export const {
  topicsDefinitionsLoaded,
  connected: connectedToMqtt,
  disconnected: disconnectedFromMqtt,
  reconnecting: reconnectingMqtt,
  statusChanged: mqttStatusChanged,
  topicValueChanged: mqttTopicValueChanged,
} = mqttSlice.actions;

export const selectMqttMode = createSelector(
  (state: RootState) => state.mqtt.definitions,
  (definitions) => definitions.length > 0
);

export const selectMqttAssets = createSelector(
  (state: RootState) => state.mqtt.assets,
  (assets) => assets
);

export const selectMqttConnectionStatus = createSelector(
  (state: RootState) => state.mqtt.status,
  (status) => status
);

export const selectMqttErrors = createSelector(
  (state: RootState) => state.mqtt.errors,
  (errors) => errors
);

export const selectMqttDefinitions = createSelector(
  (state: RootState) => state.mqtt.definitions,
  (mqttDefinitions) => mqttDefinitions
);

export const selectMqttDefinitionsByAssetId = createSelector(
  [selectMqttDefinitions, (state: RootState, assetId: string) => assetId],
  (mqttDefinitions, assetId) =>
    mqttDefinitions.find(
      (def) => def.assetId?.toLowerCase() === assetId?.toLowerCase()
    )
);

export default mqttSlice.reducer;
