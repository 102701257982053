import moment from "moment";
import { TFunction } from "i18next";

export const FormatDate = (
  value: Date | string | undefined | null,
  t: TFunction
) => {
  return moment(value).format(t("DateFormat").toString());
};

export const GetDuration = (start: Date | string, t: TFunction) => {
  let duration: number | string =
    (moment().valueOf() - moment(new Date(start)).valueOf()) / 1000;
  const durationD: number = Math.floor(duration / 86400);
  const durationH: number = Math.floor((duration - durationD * 86400) / 3600);
  const durationM: number = Math.floor(
    (duration - durationH * 3600 - durationD * 86400) / 60
  );
  duration =
    duration >= 86400
      ? `${durationD}d ${durationH}${t("h")}  ${durationM}m`
      : duration >= 3600
      ? `${durationH}${t("h")} ${durationM}m`
      : duration >= 60
      ? `${durationM}m`
      : "0m";

  return duration;
};
