import { NestedSystemAttributeGroupDTO } from "../../services/httpService";
import { RootState } from "../../store";
import AppAttributeGroup from "../enums/attributes/appAttributeGroup";
import MainAttributeGroup from "../enums/attributes/mainAttributeGroup";
import SectionAttributeGroup from "../enums/attributes/sectionAttributeGroup";

export function getTabsAttributeGroup(
  state: RootState
): NestedSystemAttributeGroupDTO | undefined {
  return state.definitions.attributeGroups
    .find((g) => g.name === MainAttributeGroup.USER_INTERFACE)
    ?.systemAttributeGroups?.find(
      (uig) => uig.name === AppAttributeGroup.ImViewer
    )
    ?.systemAttributeGroups?.find(
      (og) =>
        og.name === AppAttributeGroup.ImViewer + SectionAttributeGroup.Tabs
    );
}
