import { HubConnectionState } from "@microsoft/signalr";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { TaskCancelledSocketDto } from "../../../services/sockets/hub-services/production-planning/tasksAllService";

export interface TasksState {
  connectionStatus: HubConnectionState;
}

export const initialState: TasksState = {
  connectionStatus: HubConnectionState.Disconnected,
};

export const tasksSlice = createSlice({
  name: "sockets",
  initialState,
  reducers: {
    tasksConnectionStatusChanged: (
      state,
      action: PayloadAction<HubConnectionState>
    ) => {
      state.connectionStatus = action.payload;
    },
    taskCancelledSocketEvent: (
      state,
      action: PayloadAction<TaskCancelledSocketDto>
    ) => {},
  },
});

export const { tasksConnectionStatusChanged, taskCancelledSocketEvent } =
  tasksSlice.actions;

export const selectProductionPlanningTasksConnectionStatus = createSelector(
  (state: RootState) =>
    state.sockets.manufacturingExecution.tasks.connectionStatus,
  (connectionStatus) => connectionStatus
);

export default tasksSlice.reducer;
