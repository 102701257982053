import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "..";
import SnackbarUtils from "../../helpers/snackbarHelper";
import { TabOption, baseTabs } from "../../helpers/tabs/baseTabs";
import i18n from "../../i18n";
import {
  changeSystemAttributeDetails,
  createSystemAttribute,
  deleteSystemAttribute,
  getSystemAttributesByGroupId,
} from "../../services/main/systemAttributesService";
import { getTabsAttributeGroup } from "../../helpers/tabs/getTabsAttributeGroup";

export interface CustomTabComponent {
  attrName: string;
  titlePl: string;
  titleEn: string;
  path: string;
  code: string;
  isNew?: boolean;
  id: string | null;
}

type CustomTabState = { value: Array<CustomTabComponent>; loaded: boolean };

export const initialState: CustomTabState = { value: [], loaded: false };

export const createCustomTabAsync = createAsyncThunk(
  "customTabs/createCustomTabAsync",
  async (
    {
      titlePl,
      titleEn,
      attrName,
      path,
      code,
    }: {
      titlePl: string;
      titleEn: string;
      attrName: string;
      path: string;
      code: string;
    },
    { getState, dispatch }
  ) => {
    const groupId = getTabsAttributeGroup(getState() as RootState)?.id;
    let tabId = (
      await createSystemAttribute(
        groupId!,
        attrName,
        "Tab created from ImViewer app",
        JSON.stringify({
          titlePl: titlePl,
          titleEn: titleEn,
          path: path,
          code: code,
        }),
        4
      )
    )?.id;

    return {
      id: tabId,
    };
  }
);

export const updateCustomTabAsync = createAsyncThunk(
  "customTabs/updateCustomTabAsync",
  async (
    {
      titlePl,
      titleEn,
      attrName,
      path,
      code,
    }: {
      titlePl: string;
      titleEn: string;
      attrName: string;
      path: string;
      code: string;
    },
    { getState, dispatch }
  ) => {
    const tab = (getState() as RootState).customTabs.value.find(
      (t) => t.attrName === attrName
    );
    if (tab !== undefined) {
      await changeSystemAttributeDetails(
        tab.id!,
        tab.attrName,
        "Tab changed from ImViewer app",
        JSON.stringify({
          titlePl: titlePl,
          titleEn: titleEn,
          path: tab.path,
          code: code,
        }),
        4
      );
    }

    return {
      id: tab?.id,
    };
  }
);

export const loadCustomTabsAsync = createAsyncThunk(
  "customTabs/loadCustomTabsAsync",
  async (_, { getState, dispatch }) => {
    const groupId = getTabsAttributeGroup(getState() as RootState)?.id;
    if (groupId) {
      const response = await getSystemAttributesByGroupId(groupId);
      return response;
    } else {
      return [];
    }
  }
);

export const deleteCustomTabAsync = createAsyncThunk(
  "customTabs/deleteCustomTabAsync",
  async ({ path }: { path: string }, { getState, dispatch }) => {
    const tab = (getState() as RootState).customTabs.value.find(
      (c) => c.path === path
    );
    if (tab !== undefined && tab.id !== null) {
      await deleteSystemAttribute(tab.id);
      return { path: path };
    }
  }
);

export const customTabSlice = createSlice({
  name: "customTabs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadCustomTabsAsync.fulfilled, (state, action) => {
        state.value = action
          .payload!.filter((e) => e.value !== null)
          .map(
            (e) =>
              ({
                attrName: e.name,
                id: e.id,
                ...JSON.parse(e.value!),
              } as CustomTabComponent)
          );
        state.loaded = true;
      })
      .addCase(loadCustomTabsAsync.rejected, (state, action) => {
        state.value = [];
      })
      .addCase(createCustomTabAsync.fulfilled, (state, action) => {
        SnackbarUtils.success(i18n.t("CreateCustomTabSuccess"));
        state.value = [
          ...state.value,
          {
            attrName: action.meta.arg.attrName,
            titlePl: action.meta.arg.titlePl,
            titleEn: action.meta.arg.titleEn,
            path: action.meta.arg.path,
            code: action.meta.arg.code,
            id: action.payload.id,
          } as CustomTabComponent,
        ];
      })
      .addCase(createCustomTabAsync.rejected, (state, action) => {
        SnackbarUtils.error(i18n.t("CreateCustomTabFailure"));
      })
      .addCase(updateCustomTabAsync.fulfilled, (state, action) => {
        SnackbarUtils.success(i18n.t("SaveCustomTabSuccess"));
        const index = state.value.findIndex((c) => c.id === action.payload.id);
        if (index !== -1) {
          state.value[index].titlePl = action.meta.arg.titlePl;
          state.value[index].titleEn = action.meta.arg.titleEn;
          state.value[index].code = action.meta.arg.code;
        }
      })
      .addCase(updateCustomTabAsync.rejected, (state, action) => {
        SnackbarUtils.error(i18n.t("SaveCustomTabFailure"));
      })
      .addCase(deleteCustomTabAsync.fulfilled, (state, action) => {
        SnackbarUtils.success(i18n.t("DeleteCustomTabSuccess"));
        state.value = state.value.filter(
          (c) => !(c.path === action.payload!.path)
        );
      })
      .addCase(deleteCustomTabAsync.rejected, (state, action) => {
        SnackbarUtils.error(i18n.t("DeleteCustomTabFailure"));
      });
  },
});

export const selectCustomTabComponents = createSelector(
  (state: RootState) => state.customTabs.value,
  (tabs) => tabs
);

export const selectCustomTabsLoaded = createSelector(
  (state: RootState) => state.customTabs.loaded,
  (tabs) => tabs
);

export const selectCustomTabPaths = createSelector(
  (state: RootState) => state.customTabs.value,
  (tabs) => tabs.map((e) => ({ path: e.path }))
);

export const selectAllTabsPaths = createSelector(
  (state: RootState) => state.customTabs.value,
  (customTabs) => {
    let tabs: Array<TabOption> = [];
    let preparedTabsPaths: Array<string> = [];
    const mapCustomTabs = customTabs.map((c) => {
      return {
        label: c.titlePl,
        path: c.path,
      } as TabOption;
    });

    tabs = baseTabs.concat(mapCustomTabs);
    tabs.forEach((e) => preparedTabsPaths.push(e.path));
    return preparedTabsPaths;
  }
);

export const selectTabsPaths = createSelector(
  (state: RootState) => state.customTabs.value,
  (customTabs) => {
    let tabs: Array<TabOption> = [];

    const mapCustomTabs = customTabs.map((c) => {
      return {
        label: c.titlePl,
        path: c.path,
      } as TabOption;
    });

    tabs = baseTabs.concat(mapCustomTabs);

    const preparedTabsPaths = tabs.map((e) => e.path);

    return preparedTabsPaths;
  }
);

export default customTabSlice.reducer;
