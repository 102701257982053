import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AsyncValue, RootState } from "../..";
import { GetAllTaskExecsQueryDto } from "../../../services/httpService";
import { getAssetsTaskExecs } from "../../../services/main/assetsService";

export type TaskExec = Omit<GetAllTaskExecsQueryDto, "assetName" | "duration">;

export type TaskExecsState = AsyncValue<Array<TaskExec>>;

export const initialState: TaskExecsState = {
  value: [],
  pending: false,
};

export const loadTaskExecsAsync = createAsyncThunk(
  "taskExecs/loadTaskExecsAsync",
  async (_, { getState, dispatch }) => {
    const result = await getAssetsTaskExecs();
    return result;
  }
);

export const taskExecsSlice = createSlice({
  name: "taskExecs",
  initialState,
  reducers: {
    taskExecChanged: (state, action: PayloadAction<TaskExec>) => {
      state.value = state.value.map((te) =>
        te.assetId === action.payload.assetId &&
        te.assetPosition === action.payload.assetPosition
          ? action.payload
          : te
      );
    },
    taskExecCleared: (state, action: PayloadAction<{ taskId: string }>) => {
      state.value = state.value.map((te) =>
        te.currentTaskId === action.payload.taskId
          ? {
              ...te,
              currentWorkOrderId: null,
              currentWorkOrderName: null,
              currentTaskId: null,
              currentTaskName: null,
              currentTaskItemName: null,
              currentTaskStartedAt: null,
              currentProductionLogId: null,
              currentTaskLogId: null,
              currentTaskStateColor: null,
              currentTaskStateId: null,
              currentTaskStateName: null,
            }
          : te
      );
    },
    taskExecQuantityProducedChanged: (
      state,
      action: PayloadAction<{ taskId: string; newTaskQuantity: number }>
    ) => {
      const { taskId, newTaskQuantity } = action.payload;
      state.value = state.value.map((te) =>
        te.currentTaskId === taskId
          ? {
              ...te,
              currentTaskQuantityProduced: newTaskQuantity,
            }
          : te
      );
    },
    taskExecQuantityRejectedChanged: (
      state,
      action: PayloadAction<{ taskId: string; newTaskQuantity: number }>
    ) => {
      const { taskId, newTaskQuantity } = action.payload;
      state.value = state.value.map((te) =>
        te.currentTaskId === taskId
          ? {
              ...te,
              currentTaskQuantityRejected: newTaskQuantity,
            }
          : te
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadTaskExecsAsync.pending, (state, action) => {
        state.pending = true;
      })
      .addCase(loadTaskExecsAsync.fulfilled, (state, action) => {
        state.value = action.payload!;
        state.pending = false;
      })
      .addCase(loadTaskExecsAsync.rejected, (state, action) => {
        state.value = [];
        state.pending = false;
      });
  },
});

export const {
  taskExecChanged,
  taskExecCleared,
  taskExecQuantityProducedChanged,
  taskExecQuantityRejectedChanged,
} = taskExecsSlice.actions;

export const selectTaskExecs = createSelector(
  (state: RootState) => state.main.taskExecs.value,
  (taskExecs) => taskExecs
);

export const selectRunningTaskExecs = createSelector(
  selectTaskExecs,
  (taskExecs) => taskExecs.filter((ue) => ue.currentTaskId !== null)
);

export const selectTaskExecsByAssetId = createSelector(
  [selectTaskExecs, (state, assetId: string) => assetId],
  (taskExecs, assetId) => taskExecs.filter((ue) => ue.assetId === assetId)
);

export const selectRunningTaskExecsByAssetId = createSelector(
  [selectTaskExecs, (state, assetId: string) => assetId],
  (taskExecs, assetId) =>
    taskExecs.filter((ue) => ue.assetId === assetId && ue.currentTaskId)
);

// export const selectSelectedAssetTaskExec = createSelector(
//   selectSelectedAssetsIds,
//   selectUtilizationExecs,
//   (selectedAssets, utilizationExecs) =>
//     utilizationExecs.filter((ue) =>
//       selectedAssets.find((a) => a === ue.assetId)
//     )
// );

export const selectTaskExecsPending = createSelector(
  (state: RootState) => state.main.taskExecs.pending,
  (taskExecsPending) => taskExecsPending
);

export default taskExecsSlice.reducer;
