import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "..";
import { ProductionReasonDto } from "../../services/httpService";
import { getAllProductionReasons } from "../../services/main/productionReasonsService";

export const initialState: Array<ProductionReasonDto> = [];

export const loadProductionReasonsAsync = createAsyncThunk(
  "definitions/loadproductionReasonsAsync",
  async (_, { getState, dispatch }) => {
    const result = await getAllProductionReasons();
    return result;
  }
);

export const productionReasons = createSlice({
  name: "definitions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadProductionReasonsAsync.fulfilled, (state, action) => {
        return action.payload;
      })
      .addCase(loadProductionReasonsAsync.rejected, (state, action) => {
        return [];
      });
  },
});

export const selectProductionReasons = createSelector(
  (state: RootState) => state.definitions.productionReasons,
  (productionReasons) => productionReasons
);

export default productionReasons.reducer;
