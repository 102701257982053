import { env } from "../../env";
import { handleExceptionMessage } from "../../helpers/handleExceptionMessage";
import { instance } from "../axiosInstance";
import { AssetLogonDTO, AssetLogonsClient } from "../httpService";

const http = new AssetLogonsClient(env.REACT_APP_MES_API_URL, instance);

export const getAllAssetsLogons = async (
  userId?: string,
  assetId?: string,
  isOngoing?: boolean,
  startedAtFrom?: Date,
  startedAtTo?: Date,
  finishedAtFrom?: Date,
  finishedAtTo?: Date,
  throwError: boolean = true
): Promise<Array<AssetLogonDTO> | undefined> => {
  try {
    const result = await http.getAllAssetLogons(
      userId,
      assetId,
      isOngoing,
      startedAtFrom,
      startedAtTo,
      finishedAtFrom,
      finishedAtTo
    );
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};
