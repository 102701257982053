import { env } from "../../env";
import { handleExceptionMessage } from "../../helpers/handleExceptionMessage";
import { instance } from "../axiosInstance";
import {
  AssignSystemAttributeToAssetDto,
  ChangeSystemAttributeDetailsRequest,
  CreateSystemAttributeDto,
  CreateSystemAttributeRequest,
  SystemAttributeDTO,
  SystemAttributesClient,
} from "../httpService";

const http = new SystemAttributesClient(env.REACT_APP_MES_API_URL, instance);

export const getAllSystemAttributes = async (
  throwError: boolean = true
): Promise<Array<SystemAttributeDTO> | undefined> => {
  try {
    const result = await http.getAllSystemAttributes();
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const getSystemAttributeById = async (
  id: string,
  throwError: boolean = true
): Promise<SystemAttributeDTO | undefined> => {
  try {
    const result = await http.getSystemAttributeById(id);
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const getSystemAttributesByGroupId = async (
  groupId: string,
  throwError: boolean = true
): Promise<Array<SystemAttributeDTO> | undefined> => {
  try {
    const result = await http.getSystemAttributesByGroupId(groupId);
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const assignSystemAttributeToAsset = async (
  id: string,
  assetId: string,
  throwError: boolean = true
): Promise<AssignSystemAttributeToAssetDto | undefined> => {
  try {
    const result = await http.assignSystemAttributeToAsset(id, assetId);
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const changeSystemAttributeDetails = async (
  systemAttributeId: string,
  name: string | null,
  description: string | null,
  value: string | null,
  valueType: number,
  throwError: boolean = true
): Promise<void> => {
  try {
    const request = new ChangeSystemAttributeDetailsRequest({
      name,
      description,
      value,
      valueType,
    });

    await http.changeSystemAttributeDetails(systemAttributeId, request);
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const createSystemAttribute = async (
  systemAttributeGroupId: string,
  name: string | null,
  description: string | null,
  value: string | null,
  valueType: number,
  throwError: boolean = true
): Promise<CreateSystemAttributeDto | undefined> => {
  try {
    const request = new CreateSystemAttributeRequest({
      systemAttributeGroupId,
      name,
      description,
      value,
      valueType,
    });

    return await http.createSystemAttribute(request);
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const deleteSystemAttribute = async (
  id: string,
  throwError: boolean = true
): Promise<void> => {
  try {
    return await http.deleteSystemAttribute(id);
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};
