import { styled } from "@mui/material";

interface StatusBarProps {
  color?: string;
}

export const StatusBar = styled("div")<StatusBarProps>(({ theme, color }) => ({
  minWidth: "0.4rem",
  minHeight: "1.5rem",
  height: "100%",
  backgroundColor:
    color === "success" ? theme.palette.chart.success : color ?? "#e5e5e5",
}));
