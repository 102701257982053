import * as React from "react";

function SvgComponent(props: any) {
  return (
    <svg
      data-name="Icon / Overview"
      width={20.5}
      height={20.5}
      viewBox="0 0 20.5 20.5"
      {...props}
    >
      <g data-name="DataPie / Line / M">
        <path
          data-name="Path 1288"
          d="M8.5 2.5a.753.753 0 01.75.75v8h8a.747.747 0 01.74.648l.01.1c0 4.971-4.03 8.5-9 8.5a9 9 0 01-9-9C0 6.529 3.53 2.5 8.5 2.5zm-.75 1.537l-.21.024C3.85 4.532 1.5 7.683 1.5 11.5A7.5 7.5 0 009 19c3.82 0 6.97-2.352 7.44-6.041l.02-.209H8.5a.747.747 0 01-.74-.648l-.01-.1zM11.5 0a9 9 0 019 9 .753.753 0 01-.75.75H11.5a.753.753 0 01-.75-.75V.75A.753.753 0 0111.5 0zm.75 1.537V8.25h6.71l-.02-.209a7.507 7.507 0 00-6.48-6.48z"
          fill="#212121"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
