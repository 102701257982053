import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import {
  taskCompletedSocketEvent,
  taskPausedSocketEvent,
  taskRegisteredBadProductionSocketEvent,
  taskRegisteredGoodProductionSocketEvent,
  taskStartedSocketEvent,
  taskUnregisteredBadProductionSocketEvent,
  taskUnregisteredGoodProductionSocketEvent,
} from "../../sockets/manufacturing-execution/tasksSlice";
import { getTaskExecByTaskId } from "../../../services/main/assetsService";
import {
  TaskExec,
  taskExecChanged,
  taskExecCleared,
  taskExecQuantityProducedChanged,
  taskExecQuantityRejectedChanged,
} from "./taskExecsSlice";
import { taskCancelledSocketEvent } from "../../sockets/production-planning/tasksSlice";

const taskExecsListener = createListenerMiddleware();

taskExecsListener.startListening({
  actionCreator: taskStartedSocketEvent,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    const { taskId } = action.payload;
    const taskExec = await getTaskExecByTaskId(taskId);
    taskExec && listenerApi.dispatch(taskExecChanged(taskExec as TaskExec));
  },
});

taskExecsListener.startListening({
  matcher: isAnyOf(
    taskPausedSocketEvent,
    taskCompletedSocketEvent,
    taskCancelledSocketEvent
  ),
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    const { taskId } = action.payload;
    listenerApi.dispatch(taskExecCleared({ taskId }));
  },
});

taskExecsListener.startListening({
  matcher: isAnyOf(
    taskRegisteredGoodProductionSocketEvent,
    taskUnregisteredGoodProductionSocketEvent
  ),
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    const { taskId, newTaskQuantity } = action.payload;
    listenerApi.dispatch(
      taskExecQuantityProducedChanged({ taskId, newTaskQuantity })
    );
  },
});

taskExecsListener.startListening({
  matcher: isAnyOf(
    taskRegisteredBadProductionSocketEvent,
    taskUnregisteredBadProductionSocketEvent
  ),
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    const { taskId, newTaskQuantity } = action.payload;
    listenerApi.dispatch(
      taskExecQuantityRejectedChanged({
        taskId,
        newTaskQuantity,
      })
    );
  },
});

export default taskExecsListener;
