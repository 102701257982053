import { createListenerMiddleware } from "@reduxjs/toolkit";
import { TaskLogoffRegisteredSocketDto } from "../../../services/sockets/hub-services/access-management/taskLogonAllService";
import {
  taskLogOffRegisteredSocketEvent,
  taskLogOnRegisteredSocketEvent,
} from "../../sockets/access-management/taskLogonsSlice";
import {
  TaskLogon,
  taskLogOffRegistered,
  taskLogOnRegistered,
} from "./taskLogonsSlice";

const taskLogonsListener = createListenerMiddleware();

taskLogonsListener.startListening({
  actionCreator: taskLogOnRegisteredSocketEvent,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    const { taskId, userId, connectionId, startTime } = action.payload;

    listenerApi.dispatch(
      taskLogOnRegistered({
        taskId: taskId,
        userId: userId,
        connectionId: connectionId,
        startedAt: startTime,
        finishedAt: null,
      } as TaskLogon)
    );
  },
});

taskLogonsListener.startListening({
  actionCreator: taskLogOffRegisteredSocketEvent,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    const { taskId, userId, connectionId } = action.payload;
    listenerApi.dispatch(
      taskLogOffRegistered({
        taskId: taskId,
        userId: userId,
        connectionId: connectionId,
      } as TaskLogoffRegisteredSocketDto)
    );
  },
});

export default taskLogonsListener;
