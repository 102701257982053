import {
  Box,
  Button,
  styled,
  Typography,
  TypographyProps,
} from "@mui/material";
import React, { FunctionComponent, useState } from "react";
import { FallbackProps } from "react-error-boundary";
import DeskIcon from "../../../icons/error-fallback/DeskIcon";
import ErrorIcon from "../../../icons/error-fallback/ErrorIcon";
import { t } from "i18next";
import ConfirmClearCacheDialogComponent from "../dialogs/ConfirmClearCacheDialogComponent";

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  position: "relative",
  color: theme.palette.primary.main,
  backgroundColor: "#FFF",
  margin: "0.5rem",
  boxSizing: "border-box",
}));

const DeskBackgroundIcon = styled(DeskIcon)(({ theme }) => ({
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(0, -50%)",
  height: "70%",
  width: "50%",
  padding: "1rem",
  boxSizing: "border-box",
  zIndex: 10,
}));

const Content = styled("div")(({ theme }) => ({
  position: "absolute",
  height: "100%",
  maxHeight: "100%",
  width: "50%",
  overflow: "hidden",
  display: "grid",
  grid: "min-content min-content auto / 1fr",
  alignContent: "center",
  justifyItems: "center",
  padding: "5%",
  boxSizing: "border-box",
}));

const BigErrorIcon = styled(ErrorIcon)(({ theme }) => ({
  height: "3.75rem",
  marginBottom: "1rem",
}));

const Error = styled(Typography)<TypographyProps>(({ theme }) => ({
  display: "-webkit-box",
  maxWidth: "100%",
  wordBreak: "break-word",
  overflow: "auto",
}));

interface ViewErrorProps extends FallbackProps {
  onClear: () => void;
}

const ViewError: FunctionComponent<ViewErrorProps> = ({
  error,
  resetErrorBoundary,
  onClear,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <Root>
        <DeskBackgroundIcon />
        <Content>
          <Box display="flex">
            <BigErrorIcon />
            <Typography variant="h2" component="h1">
              ERROR
            </Typography>
          </Box>
          <Typography variant="h6" component="h2" gutterBottom>
            Ooooops!
          </Typography>
          <Box overflow="auto">
            <Error variant="body2" gutterBottom>
              {error.message}
            </Error>
            <Error variant="body2" align="left">
              {error.stack}
            </Error>
          </Box>
          <Button
            variant="contained"
            onClick={() => {
              setOpen(true);
            }}
          >
            {t("Clear browser cache and log in again")}
          </Button>
        </Content>
      </Root>
      <ConfirmClearCacheDialogComponent
        open={open}
        handleConfirm={onClear}
        handleClose={() => setOpen(false)}
      />
    </>
  );
};

export default ViewError;
