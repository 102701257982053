import { useEffect, useState } from "react";
import { executeProcedure } from "../../services/main/extensionService";

/**
 
 * Gets data from database using stored procedure in MSSQL or function in PostgreSQL
 
 * 
 
 * @param {string} procedureName - Source of data. MSSQL - name of procedure, PostgreSQL - name of function. In Both cases schema 'extension' is required.
 
 * @param {Array<T>} initialState - Initial state for data before first get. Usually null, undefined or empty array.
 
 * @param {number | undefined} intervalInMilliseconds - Interval for cyclic refresh od data.

 * @param {{ [key: string]: string; }} parameters - Dictionary of parameters passed to the procedure/function. In case of PostgreSQL it is important to keep order of the parameters the same as the function have in database.

 * @returns {[Array<T>, boolean]} Data and flag indicating pending loading.
 
 * 
 
 * @example
 
 * const params = useMemo(() => ({userId: "admin"}), []);
 * const [data, pending] = useDatabaseData("imfactory_sp_S_GetAssets", [], 10000, params);

*/

const useDatabaseData = <T>(
  procedureName: string,
  initialState: Array<T>,
  intervalInMilliseconds?: number,
  parameters?: {
    [key: string]: string;
  }
): [Array<T>, boolean] => {
  const [data, setData] = useState<Array<T>>(initialState);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsSubmitting(true);
      const response = await executeProcedure<T>(procedureName, parameters);
      response !== undefined && isMount && setData(response);
      isMount && setIsSubmitting(false);
    };

    let isMount = true;
    if (isMount) {
      fetchData();
    }

    let interval: NodeJS.Timeout;
    if (intervalInMilliseconds !== undefined) {
      interval = setInterval(() => {
        if (isMount) {
          fetchData();
        }
      }, intervalInMilliseconds);
    }
    return () => {
      isMount = false;
      interval && clearInterval(interval);
    };
  }, [parameters, intervalInMilliseconds, procedureName]);

  return [data, isSubmitting];
};

export default useDatabaseData;
