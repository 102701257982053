import { combineReducers } from "@reduxjs/toolkit";
import assetsLogonsReducer from "./asset-logons/assetsLogonsSlice";
import productionEventsReducer from "./production-events/prodEventsSlice";
import taskExecsReducer from "./task-execs/taskExecsSlice";
import utilExecsReducer from "./util-execs/utilExecsSlice";
import utilizationEventsReducer from "./utilization-events/utilEventsSlice";
import taskLogonsReducer from "./task-logons/taskLogonsSlice";
import kpisReducer from "./kpis/kpisSlice";

const mainReducer = combineReducers({
  utilExecs: utilExecsReducer,
  taskExecs: taskExecsReducer,
  utilizationEvents: utilizationEventsReducer,
  productionEvents: productionEventsReducer,
  assetsLogons: assetsLogonsReducer,
  taskLogons: taskLogonsReducer,
  kpis: kpisReducer,
});

export default mainReducer;

export type MainState = ReturnType<typeof mainReducer>;
