import {
  Action,
  configureStore,
  ThunkAction,
  ThunkDispatch,
} from "@reduxjs/toolkit";
import { getPersistConfig } from "redux-deep-persist";
import {
  persistCombineReducers,
  PersistConfig,
  persistReducer,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import definitionsReducer from "./definitions/index";
import dialogsReducer from "./common/dialogs/index";
import mainReducer from "./main/index";
import authMiddleware from "./middlewares/authMiddleware";
import listenersMiddleware from "./middlewares/listenersMiddleware";
import signalRMiddleware from "./middlewares/signalRMiddleware";
import setupReducer, { SetupReducerState } from "./setup/setupSlice";
import userReducer from "./user/index";
import socketsReducer from "./sockets/index";
import mqttReducer from "./common/mqttSlice";
import mqttMiddleware from "./middlewares/mqttMiddleware";
import customTabsReducer from "./common/customTabsSlice";

export enum PersistConfigKey {
  Root = "root",
  Setup = "setup",
}

const setupPersistConfig: PersistConfig<SetupReducerState> = getPersistConfig({
  key: PersistConfigKey.Setup,
  storage: storage,
  blacklist: ["appStarted"],
  rootReducer: setupReducer,
});

const appReducer = persistCombineReducers(
  {
    key: PersistConfigKey.Root,
    storage: storage,
    whitelist: [],
  },
  {
    definitions: definitionsReducer,
    user: userReducer,
    dialogs: dialogsReducer,
    customTabs: customTabsReducer,
    mqtt: mqttReducer,
    main: mainReducer,
    setup: persistReducer(setupPersistConfig, setupReducer),
    sockets: socketsReducer,
  }
);

export const store = configureStore({
  reducer: appReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, //FIXME shouldn't be like that :(
    })
      .prepend(listenersMiddleware)
      // prepend and concat calls can be chained
      // .concat(logger)
      .concat(authMiddleware)
      .concat(signalRMiddleware)
      .concat(mqttMiddleware),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunkDispatch = ThunkDispatch<
  RootState,
  unknown,
  Action<string>
>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export type AsyncValue<R> = {
  value: R;
  pending: boolean;
};
