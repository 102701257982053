import { env } from "../../env";
import { handleExceptionMessage } from "../../helpers/handleExceptionMessage";
import { instance } from "../axiosInstance";
import {
  CorrectUtilLogDto,
  CorrectUtilLogRequest,
  GetNestedUtilLogsByLogIdDto,
  GetPlainUtilLogsByLogIdDto,
  GetUtilLogsByAssetIdQueryDto,
  SplitUtilLogDto,
  SplitUtilLogRequest,
  SplitUtilLogRequestRange,
  UtilizationSummaryDto,
  UtilizationEventsClient,
  GetUtilRawsGroupedByStatesByAssetIdQueryDto,
} from "../httpService";

const http = new UtilizationEventsClient(env.REACT_APP_MES_API_URL, instance);

export const getCurrentShiftUtilEventsByAssetId = async (
  assetId: string,
  throwError: boolean = true
): Promise<Array<GetUtilLogsByAssetIdQueryDto> | undefined> => {
  try {
    const result = await http.getUtilEventsByAssetId(assetId);
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const getUtilRawsGroupedByStatesByAssetId = async (
  assetId: string,
  throwError: boolean = true
): Promise<Array<GetUtilRawsGroupedByStatesByAssetIdQueryDto> | undefined> => {
  try {
    const result = await http.getUtilRawsGroupedByStatesByAssetId(assetId);
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const getUtilizationSummaryByAsset = async (
  assetId: string,
  throwError: boolean = true
): Promise<Array<UtilizationSummaryDto> | undefined> => {
  try {
    const result = await http.getUtilizationSummaryByAsset(assetId);
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const getPlainUtilizationEventHistory = async (
  logId: string,
  throwError: boolean = true
): Promise<Array<GetPlainUtilLogsByLogIdDto> | undefined> => {
  try {
    const result = await http.getPlainUtilizationEventHistory(logId);
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const getNestedUtilizationEventHistory = async (
  logId: string,
  throwError: boolean = true
): Promise<GetNestedUtilLogsByLogIdDto | undefined> => {
  try {
    const result = await http.getNestedUtilizationEventHistory(logId);
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const correctUtilEvent = async (
  logId: string,
  newUtilRawId: string,
  comment: string | null = null,
  throwError: boolean = true
): Promise<CorrectUtilLogDto | undefined> => {
  try {
    const request = new CorrectUtilLogRequest({ newUtilRawId, comment });
    const result = await http.correctHistoricalAssetState(logId, request);
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const splitUtilEvent = async (
  logId: string,
  olderRange: {
    utilRawId: string;
    comment: string | null;
  } | null,
  splitTime: Date,
  newerRange: {
    utilRawId: string;
    comment: string | null;
  } | null,
  throwError: boolean = true
): Promise<SplitUtilLogDto | undefined> => {
  try {
    const olderRangeRequest = olderRange
      ? new SplitUtilLogRequestRange({
          utilRawId: olderRange.utilRawId,
          comment: olderRange.comment,
        })
      : null;
    const newerRangeRequest = newerRange
      ? new SplitUtilLogRequestRange({
          utilRawId: newerRange.utilRawId,
          comment: newerRange.comment,
        })
      : null;

    const request = new SplitUtilLogRequest({
      olderRange: olderRangeRequest as any,
      splitTime,
      newerRange: newerRangeRequest as any,
    });
    const result = await http.splitHistoricalAssetState(logId, request);
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};
