import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import { getUtilizationExecByAsset } from "../../../services/main/assetsService";
import {
  utilizationEventCorrectedSocketEvent,
  utilizationEventSplittedSocketEvent,
} from "../../sockets/utilization/utilizationEventsSlice";
import { UtilExec, utilExecChanged } from "../util-execs/utilExecsSlice";
import {
  currentUtilEventDurationUpdated,
  loadUtilEventsForCurrentShiftAsync,
} from "./utilEventsSlice";

const utilEventsListener = createListenerMiddleware();

const updateIntervalInSeconds = 10000;

utilEventsListener.startListening({
  actionCreator: loadUtilEventsForCurrentShiftAsync.fulfilled,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    while (true) {
      const task = listenerApi.fork(async (forkApi) => {
        await forkApi.delay(updateIntervalInSeconds);
        listenerApi.dispatch(
          currentUtilEventDurationUpdated(updateIntervalInSeconds / 1000)
        );
      });
      await task.result;
    }
  },
});

utilEventsListener.startListening({
  matcher: isAnyOf(
    utilizationEventCorrectedSocketEvent,
    utilizationEventSplittedSocketEvent
  ),
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    const { assetId } = action.payload;
    const newUtilExec = await getUtilizationExecByAsset(assetId);
    newUtilExec &&
      listenerApi.dispatch(
        utilExecChanged({
          assetId: assetId,
          comment: newUtilExec.comment,
          createdBy: newUtilExec.createdByUserName,
          currentUtilRawStartedAt: newUtilExec.currentUtilRawStartedAt,
          utilRawName: newUtilExec.utilRawName,
          utilStateName: newUtilExec.utilStateName,
          utilStateColor: newUtilExec.utilStateColor,
        } as unknown as UtilExec)
      );
  },
});

export default utilEventsListener;
