import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { kpiViewSelected, selectKpiView } from "../../setup/setupSlice";
import {
  assetsSelected,
  selectAvailableFlatAssets,
} from "../../user/assets-selection/assetsSelectionSlice";
import { selectSelectedTab } from "../../user/tab-selection/tabSelectionSlice";
import {
  loadAssetUtilStatesAsync,
  loadSelectedAssetsShiftKpisAsync,
} from "./kpisSlice";

const kpisListener = createListenerMiddleware();

kpisListener.startListening({
  matcher: isAnyOf(assetsSelected, kpiViewSelected),
  effect: async (_, listenerApi) => {
    listenerApi.cancelActiveListeners();
    const availableAssets = selectAvailableFlatAssets(
      listenerApi.getState() as RootState
    );
    const kpiView = selectKpiView(listenerApi.getState() as RootState);
    const availableAssetsIds = availableAssets.map((a) => a.id);
    const selectedTab = selectSelectedTab(listenerApi.getState() as RootState);
    if (availableAssetsIds.length > 0 && kpiView && selectedTab === "/assets") {
      listenerApi.dispatch(loadSelectedAssetsShiftKpisAsync());
      // listenerApi.dispatch(loadSelectedAssetsCurrentKpisAsync());
      availableAssetsIds.map((id) =>
        listenerApi.dispatch(loadAssetUtilStatesAsync({ assetId: id }))
      );
    }
  },
});

export default kpisListener;
