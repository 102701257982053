import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../..";

export const initialState: string = "/assets";

export const tabSelectionSlice = createSlice({
  name: "selectedTab",
  initialState,
  reducers: {
    tabChanged: (state, action: PayloadAction<string>) => {
      return action.payload;
    },
  },
});

export const { tabChanged } = tabSelectionSlice.actions;

export const selectSelectedTab = createSelector(
  (state: RootState) => state.user.selectedTab,
  (tab) => tab
);

export default tabSelectionSlice.reducer;
