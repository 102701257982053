import "chart.js/auto";
import "chartjs-adapter-moment";
import moment from "moment";
import React, { ReactElement, useMemo } from "react";
import { useAppSelector } from "../../../../store/hooks";
import {
  selectCurrentKPIsfromIntervalsByAssetId,
  selectShiftKPIsByAssetId,
} from "../../../../store/main/kpis/kpisSlice";
import {
  availabilityBackgroundColor,
  availabilityBorderColor,
  oeeBackgroundColor,
  oeeBorderColor,
  performanceBackgroundColor,
  performanceBorderColor,
  qualityBackgroundColor,
  qualityBorderColor,
} from "../../../../themes/appTheme";
import { Point, generateChartData } from "./KPIsHelpersFunctions";
import { SingleIndicatorComponent } from "./SingleIndicatorComponent";
import { CircularProgress, styled } from "@mui/material";

const Root = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "5px",
  marginLeft: "8px",
}));

const Loading = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "140px",
}));

interface AssetKPIsInterface {
  assetId: string | undefined;
}

const AssetKPIsComponent: React.FC<AssetKPIsInterface> = ({
  assetId,
}): ReactElement => {
  // TO DO
  // const loading = useAppSelector(selectAssetsShiftKPIsPending);
  const loading = false;
  const assetIntervalsKPIs = useAppSelector((state) =>
    selectShiftKPIsByAssetId(state, assetId!)
  );
  const assetCurrentKPIs = useAppSelector((state) =>
    selectCurrentKPIsfromIntervalsByAssetId(state, assetId!)
  );
  // const assetCurrentKPIs = useAppSelector((state) =>
  //   selectCurrentKPIsByAssetId(state, assetId!)
  // );
  // console.log(assetCurrentKPIs);

  const oeeChartData = useMemo(() => {
    let oeeValues: Point[] = [
      {
        x: moment(assetIntervalsKPIs.availability[0]?.intervalStart),
        y: 100,
      } as Point,
    ];
    // eslint-disable-next-line
    assetIntervalsKPIs.availability.map((e, i) => {
      var availability = e.availability;
      var performance = assetIntervalsKPIs.performance[i].performance;
      var quality = assetIntervalsKPIs.quality[i].quality;
      oeeValues.push({
        x: moment(e.intervalEnd),
        y:
          availability != null && performance != null && quality != null
            ? (availability * performance * quality) / 10000
            : null,
      } as Point);
    });

    return generateChartData(
      oeeValues,
      oeeBackgroundColor,
      oeeBorderColor,
      assetCurrentKPIs?.targetOEE
    );
  }, [
    assetIntervalsKPIs.availability,
    assetIntervalsKPIs.performance,
    assetIntervalsKPIs.quality,
    assetCurrentKPIs?.targetOEE,
  ]);

  const availabilityChartData = useMemo(() => {
    let availabilityValues: Point[] = [
      {
        x: moment(assetIntervalsKPIs.availability[0]?.intervalStart),
        y: 100,
      } as Point,
    ];
    // eslint-disable-next-line
    assetIntervalsKPIs.availability.map((e) => {
      availabilityValues.push({
        x: moment(e.intervalEnd),
        y: e.availability,
      } as Point);
    });

    return generateChartData(
      availabilityValues,
      availabilityBackgroundColor,
      availabilityBorderColor,
      assetCurrentKPIs?.targetAvailability
    );
  }, [assetIntervalsKPIs.availability, assetCurrentKPIs?.targetAvailability]);

  const performaceChartData = useMemo(() => {
    let perfomanceValues: Point[] = [
      {
        x: moment(assetIntervalsKPIs.performance[0]?.intervalStart),
        y: 100,
      } as Point,
    ];
    // eslint-disable-next-line
    assetIntervalsKPIs.performance?.map((e) => {
      perfomanceValues.push({
        x: moment(e.intervalEnd),
        y: e.performance,
      } as Point);
    });

    return generateChartData(
      perfomanceValues,
      performanceBackgroundColor,
      performanceBorderColor,
      assetCurrentKPIs?.targetPerformance
    );
  }, [assetIntervalsKPIs.performance, assetCurrentKPIs?.targetPerformance]);

  const qualityChartData = useMemo(() => {
    let qualityValues: Point[] = [
      {
        x: moment(assetIntervalsKPIs.quality[0]?.intervalStart),
        y: 100,
      } as Point,
    ];
    // eslint-disable-next-line
    assetIntervalsKPIs.quality?.map((e) => {
      qualityValues.push({
        x: moment(e.intervalEnd),
        y: e.quality,
      } as Point);
    });

    return generateChartData(
      qualityValues,
      qualityBackgroundColor,
      qualityBorderColor,
      assetCurrentKPIs?.targetQuality
    );
  }, [assetIntervalsKPIs.quality, assetCurrentKPIs?.targetQuality]);

  return (
    <>
      {assetCurrentKPIs ? (
        <Root>
          <SingleIndicatorComponent
            chartData={oeeChartData}
            indicatorName="OEE"
            currentValue={assetCurrentKPIs.oEE}
            loadingData={loading}
          />
          <SingleIndicatorComponent
            chartData={availabilityChartData}
            indicatorName="Availability"
            currentValue={assetCurrentKPIs.availability}
            loadingData={loading}
          />
          <SingleIndicatorComponent
            chartData={performaceChartData}
            indicatorName="Performance"
            currentValue={assetCurrentKPIs.performance}
            loadingData={loading}
          />
          <SingleIndicatorComponent
            chartData={qualityChartData}
            indicatorName="Quality"
            currentValue={assetCurrentKPIs.quality}
            loadingData={loading}
          />
        </Root>
      ) : (
        <Loading>
          <CircularProgress />
        </Loading>
      )}
    </>
  );
};

export default AssetKPIsComponent;
