import { styled } from "@mui/material/styles";
import _ from "lodash";
import * as React from "react";
import {
  selectMqttAssets,
  selectMqttDefinitions,
} from "../../../../store/common/mqttSlice";
import { useAppSelector } from "../../../../store/hooks";
import ParameterComponent from "../../../common/parameter-parts/ParameterComponent";

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  maxHeight: "115px",
  paddingTop: "5px",
  border: "solid 0.5px #CACACA",
  overflowY: "auto",
  "&> div": {
    display: "flex",
    alignItems: "center",
    margin: "0px 0px 0px 0px",
  },
}));

interface ParameterProps {
  assetId: string;
}

const AssetMqttComponent: React.FC<ParameterProps> = ({ assetId }) => {
  const mqttAssets = useAppSelector(selectMqttAssets);
  const mqttDefinitions = useAppSelector(selectMqttDefinitions);

  const parameters = React.useMemo(
    () =>
      mqttAssets.find((e) => e.assetId.toLowerCase() === assetId.toLowerCase())
        ?.parameters,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [assetId, mqttAssets]
  );

  const mqttDefinition = React.useMemo(
    () =>
      mqttDefinitions?.find(
        (e) => e.assetId.toLowerCase() === assetId.toLowerCase()
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [assetId, mqttDefinitions]
  );

  return (
    <>
      {mqttDefinition?.topics && mqttDefinition?.topics?.length > 0 && (
        <Root>
          {_.orderBy(
            mqttDefinition.topics,
            (o) => (Number.isNaN(Number(o.priority)) ? -1 : Number(o.priority)),
            "desc"
          ).map((def) => {
            return (
              <ParameterComponent
                topicDefinition={def}
                key={def.name}
                assetParameters={parameters}
                topic={parameters?.find((e) => e.name === def.name)}
              />
            );
          })}
        </Root>
      )}
    </>
  );
};

export default AssetMqttComponent;
