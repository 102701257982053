import { styled } from "@mui/material";

export const TilesContainer = styled("div")(() => ({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(20rem, 1fr))",
  gridAutoRows: "min-content",
  gap: "0.5rem",
  padding: "0.5rem",
  boxSizing: "border-box",
  overflow: "auto",
}));
