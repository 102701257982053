import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

const Root = styled("span")(({ theme }) => ({
  display: "flex",
  padding: "65px 0px 0px 0px",
  flex: "0 0 350px",
  alignItems: "center",
  justifyContent: "center",
  height: "75px",
}));

interface NoDataUtilComponentInterface {}

export const NoDataUtilComponent: React.FC<
  NoDataUtilComponentInterface
> = () => {
  const { t } = useTranslation();
  return <Root>{t("No Data")}</Root>;
};
