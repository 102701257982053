import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const initialState: boolean = false;

export const deleteTabSlice = createSlice({
  name: "deleteTabDialog",
  initialState,
  reducers: {
    deleteTabDialogOpened: (state, action: PayloadAction<void>) => {
      return true;
    },
    deleteTabDialogClosed: (state, action: PayloadAction<void>) => {
      return false;
    },
  },
});

export const { deleteTabDialogOpened, deleteTabDialogClosed } =
  deleteTabSlice.actions;

export default deleteTabSlice.reducer;
