import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";

export interface ConfirmClearCacheDialogComponentProps {
  open: boolean;
  handleConfirm: () => void;
  handleClose: () => void;
}

const ConfirmClearCacheDialogComponent: React.FC<
  ConfirmClearCacheDialogComponentProps
> = ({ open, handleConfirm, handleClose }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={() => {}} fullWidth maxWidth={"sm"}>
      <DialogTitle>{t("Confirm clearing cache")}</DialogTitle>
      <DialogContent sx={{ alignItems: "flex-start" }}>
        <Typography variant="subtitle1" gutterBottom>
          {t("ConfirmClearCache")}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="success"
          onClick={() => handleConfirm()}
        >
          {t("Submit")}
        </Button>
        <Button variant="contained" color="error" onClick={() => handleClose()}>
          {t("Cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmClearCacheDialogComponent;
