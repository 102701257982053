import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton, Tooltip } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import { styled } from "@mui/material/styles";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { TabOption } from "../../../helpers/tabs/baseTabs";
import OverviewIcon from "../../../icons/OverviewIcon";
import { useAppSelector } from "../../../store/hooks";
import {
  selectAllFlatAssets,
  selectSelectedAssetsIds,
} from "../../../store/user/assets-selection/assetsSelectionSlice";
import ButtonTabComponent from "./styled/ButtonTabComponent";

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0.5rem 1rem 0rem 3rem",
}));

const TabsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  maxWidth: "90%",
  padding: "0.75rem 0rem 0.75rem 0rem",
}));

const ViewsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  minWidth: "fit-content",
  "& > button": {
    padding: 0,
  },
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  "&.MuiTabs-root": {
    minHeight: "2.2rem",
    height: "2.2rem",
  },
  "& .MuiTabs-indicator": {
    display: "none",
  },
  "& .MuiTabs-flexContainer": {
    height: "100%",
    padding: "unset",
  },
}));

interface AssetDetailsMenuProps {}

const AssetDetailsMenu: React.FC<AssetDetailsMenuProps> = () => {
  const [menuOption] = useState<string | null>("/overview");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const selectedAssetIds = useAppSelector(selectSelectedAssetsIds);
  const allFlatAssets = useAppSelector(selectAllFlatAssets);

  const handleBack = useCallback(() => {
    if (selectedAssetIds.length > 0) {
      navigate(`/assets?assetIds=${selectedAssetIds.join(",")}`);
    } else {
      navigate(`/assets`);
    }
  }, [navigate, selectedAssetIds]);

  useEffect(() => {
    const assetId = window.location.pathname.replace("/assets/details/", "");
    if (assetId === "" || !allFlatAssets.find((a) => a.id === assetId)) {
      handleBack();
    }
  }, [allFlatAssets, handleBack]);

  const tabOptions: Array<TabOption> = [
    {
      label: t("Overview"),
      Icon: OverviewIcon,
      path: "/overview",
    },
  ];

  const styledTabOptions: Array<TabOption> = tabOptions.map((t) => ({
    ...t,
    Icon: (
      <t.Icon
        style={{
          height: "1rem",
          width: "1rem",
          marginRight: "0.4rem",
          marginBottom: "0px",
        }}
      />
    ),
  }));

  return (
    <Root>
      <TabsContainer>
        <StyledTabs
          variant="scrollable"
          // onChange={handleChange}
          value={menuOption}
          scrollButtons="auto"
        >
          {styledTabOptions.map((tab) => (
            <ButtonTabComponent
              wrapped
              disableRipple
              key={tab.path}
              label={t(tab.label)}
              icon={tab.Icon === false ? undefined : (tab.Icon as any)}
              value={tab.path}
            />
          ))}
        </StyledTabs>
      </TabsContainer>
      <ViewsContainer>
        <Tooltip title={t("Go back").toString()}>
          <IconButton onClick={handleBack} size="small">
            <ArrowBackIcon fontSize="large" />
          </IconButton>
        </Tooltip>
      </ViewsContainer>
    </Root>
  );
};

export default AssetDetailsMenu;
