import * as React from "react";

function SvgComponent(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18.001}
      height={18.01}
      viewBox="0 0 18.001 18.01"
      {...props}
    >
      <path
        d="M17.602 17.92a.775.775 0 00.389-.56l.007-.1V9.75a.75.75 0 00-1.493-.1l-.007.1v5.69L1.276.22A.744.744 0 00.299.15L.215.22a.756.756 0 00-.073.98l.073.08L15.437 16.5l-5.692.01a.749.749 0 00-.743.64l-.007.11a.756.756 0 00.649.74l.1.01 7.56-.01a.613.613 0 00.234-.05z"
        fill={props.color}
      />
    </svg>
  );
}

export default SvgComponent;
