import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "..";
import { UserDto } from "../../services/httpService";
import { getAllUsers } from "../../services/main/usersService";

export const initialState: Array<UserDto> = [];

export const loadUsersAsync = createAsyncThunk(
  "definitions/loadUsersAsync",
  async (_, { getState, dispatch }) => {
    const result = await getAllUsers();
    return result;
  }
);

export const usersSlice = createSlice({
  name: "definitions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadUsersAsync.fulfilled, (state, action) => {
        return action.payload;
      })
      .addCase(loadUsersAsync.rejected, (state, action) => {
        return [];
      });
  },
});

export const selectUsers = createSelector(
  (state: RootState) => state.definitions.users,
  (users) => users
);

export default usersSlice.reducer;
