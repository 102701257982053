import * as React from "react";

function SvgComponent(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={15.995}
      viewBox="0 0 18 15.995"
      {...props}
    >
      <path
        d="M10.267.207a.751.751 0 00-1.034 1.09l6.251 5.95H.75a.75.75 0 000 1.5h14.734l-6.251 5.95a.751.751 0 101.034 1.09l7.419-7.07a.974.974 0 00.3-.58.707.707 0 000-.28.974.974 0 00-.3-.58z"
        fill={props.color}
      />
    </svg>
  );
}

export default SvgComponent;
