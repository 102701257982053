import { styled } from "@mui/material";
import React, { FunctionComponent } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../common/app-bar/FooterComponent";
import LoginAppBar from "../common/app-bar/login/LoginAppBar";

const Main = styled("main")(({ theme }) => ({
  width: "100%",
  height: "calc(100% - 3.125rem)",
  maxHeight: "calc(100% - 3.125rem)",
}));

interface DefaultAreaLayoutProps {}

const DefaultAreaLayout: FunctionComponent<DefaultAreaLayoutProps> = () => {
  return (
    <React.Fragment>
      <LoginAppBar />
      <Main>
        <Outlet />
      </Main>
      <Footer />
    </React.Fragment>
  );
};

export default DefaultAreaLayout;
