import { createListenerMiddleware } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { LocalStorageKey } from "../../../helpers/localStorageKeys";
import { getFromLocalStorage } from "../../../services/localStorageService";
import {
  loadNestedAssetsAsync,
  selectNestedAssetsNodeIdsWithParents,
} from "../../definitions/assetsSlice";
import { SelectedAsset, assetsSelected } from "./assetsSelectionSlice";

const assetsListener = createListenerMiddleware();

assetsListener.startListening({
  actionCreator: loadNestedAssetsAsync.fulfilled,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    const defaultAssets = getFromLocalStorage(
      LocalStorageKey.DEFAULT_ASSET_IDS
    );

    const assetIds = window.location.search
      .replace("?assetIds=", "")
      .split(",");

    const nestedAssetsIds = selectNestedAssetsNodeIdsWithParents(
      listenerApi.getState() as RootState
    );

    const selectedAssets: Array<SelectedAsset> = [];
    assetIds.forEach((n: any) => {
      const assetPath = nestedAssetsIds.find(
        (a) => a.split("/")[a.split("/").length - 1] === n
      );
      if (assetPath) {
        selectedAssets.push({
          sourceStack: assetPath?.split("/").slice(0, -1),
          assetId: assetPath?.split("/")[assetPath?.split("/").length - 1],
        } as SelectedAsset);
      }
    });
    if (selectedAssets.length > 0) {
      listenerApi.dispatch(assetsSelected(selectedAssets));
    } else if (defaultAssets && defaultAssets.length > 0) {
      const selectedAssets = defaultAssets.map(
        (n: any) =>
          ({
            sourceStack: n.split("/").slice(0, -1),
            assetId: n.split("/")[n.split("/").length - 1],
          } as SelectedAsset)
      );
      listenerApi.dispatch(assetsSelected(selectedAssets));
    }
    // }
  },
});

export default assetsListener;
