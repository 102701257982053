import { env } from "../../env";
import { handleExceptionMessage } from "../../helpers/handleExceptionMessage";
import { instance } from "../axiosInstance";
import {
  CorrectProductionLogRequest,
  ProductionEventsClient,
  ProductionLogDto,
  ProductionSummaryDto,
} from "../httpService";

const http = new ProductionEventsClient(env.REACT_APP_MES_API_URL, instance);

export const getCurrentShiftProductionEventsByAssetId = async (
  assetId: string,
  throwError: boolean = true
): Promise<Array<ProductionLogDto> | undefined> => {
  try {
    const result = await http.getProductionEventsByAssetId(assetId);
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const getProductionSummaryByAsset = async (
  assetId: string,
  throwError: boolean = true
): Promise<Array<ProductionSummaryDto> | undefined> => {
  try {
    const result = await http.getProductionSummaryByAsset(assetId);
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const correctHistoricalProductionEvent = async (
  logId: string,
  productionReasonId: string,
  quantityProduced: number,
  comment: string | null = null,
  throwError: boolean = true
): Promise<void> => {
  try {
    const request = new CorrectProductionLogRequest({
      productionReasonId,
      quantityProduced,
      comment,
    });
    await http.correctProductionEvent(logId, request);
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};
