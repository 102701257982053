import { combineReducers } from "@reduxjs/toolkit";
import assetsReducer from "./assetsSlice";
import usersReducer from "./usersSlice";
import utilStatesReducer from "./utilStatesSlice";
import productionReasonsReducer from "./productionReasonsSlice";
import attributeGroupsReducer from "./attributeGroupsSlice";

export default combineReducers({
  assets: assetsReducer,
  users: usersReducer,
  utilStates: utilStatesReducer,
  productionReasons: productionReasonsReducer,
  attributeGroups: attributeGroupsReducer,
});
