import {
  deleteFromLocalStorage,
  getFromLocalStorage,
  saveToLocalStorage,
} from "../../services/localStorageService";

const useLocalStorage = () => {
  return [getFromLocalStorage, saveToLocalStorage, deleteFromLocalStorage];
};

export default useLocalStorage;
