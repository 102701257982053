import DashboardIcon from "@mui/icons-material/Dashboard";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import { IconButton, Tooltip } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import { styled } from "@mui/material/styles";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { env } from "../../../env";
import { TabOption, baseTabs } from "../../../helpers/tabs/baseTabs";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  kpiViewSelected,
  selectKpiView,
  selectTabCarousel,
  selectTableView,
} from "../../../store/setup/setupSlice";
import { selectSelectedAssetsIds } from "../../../store/user/assets-selection/assetsSelectionSlice";
import ButtonTabComponent from "./styled/ButtonTabComponent";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { selectIsAuthorized } from "../../../store/user/authSlice";
import { defaultNewTileComponent } from "../../../helpers/custom-tile/defaultTileSettings";
import { editableTabDialogOpened } from "../../../store/common/dialogs/editableTabDialog/editableTabSlice";
import { selectCustomTabComponents } from "../../../store/common/customTabsSlice";
import { deleteTabDialogOpened } from "../../../store/common/dialogs/deleteTabSlice";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import i18n from "../../../i18n";

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  overflow: "auto",
  marginTop: "1rem",
}));

const ActionsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  height: "100%",
  minWidth: "5rem",
  marginRight: "1rem",
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  "&.MuiTabs-root": {
    minHeight: "2.5rem",
    height: "2.5rem",
    marginLeft: "0.5rem",
  },
  "& .MuiTabs-indicator": {
    display: "none",
  },
  "& .MuiTabs-flexContainer": {
    height: "100%",
  },
}));

const EditButtonContainer = styled("div")(({ theme }) => ({
  opacity: 0.2,
  position: "absolute",
  top: "50%",
  left: "50%",
  zIndex: 200,
  transform: "translate(-50%, -50%)",
  transition: "opacity 1s ",
  "&:hover": {
    opacity: "1",
  },
  span: {
    "&.MuiIconButton-root:hover": {
      color: theme.palette.success.main,
    },
  },
}));

const AddTabButton = styled(IconButton)(({ theme }) => ({
  alignItems: "center",
  marginLeft: "1rem",
}));

const TabsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  maxWidth: "90%",
  padding: "0.75rem 0rem 0.75rem 0rem",
  "@media (max-width: 700px)": {
    maxWidth: "80%",
  },
}));

interface TabsMenuProps {}

const TabsMenu: React.FC<TabsMenuProps> = () => {
  const location = useLocation();
  const [menuOption, setMenuOption] = useState<string>("/assets");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const kpiView = useAppSelector(selectKpiView);
  const tableView = useAppSelector(selectTableView);
  const tabCarousel = useAppSelector(selectTabCarousel);
  const selectedAssetIds = useAppSelector(selectSelectedAssetsIds);
  const isAuthorized = useAppSelector(selectIsAuthorized);
  const customTabs = useAppSelector(selectCustomTabComponents);

  useEffect(() => {
    setMenuOption(location.pathname === "/" ? "/assets" : location.pathname);
  }, [location]);

  const handleOpenAddTabDialog = () => {
    const tabToAdd = {
      attrName: "",
      titlePl: "",
      titleEn: "",
      path: "",
      code: defaultNewTileComponent,
      isNew: true,
    };
    dispatch(editableTabDialogOpened(tabToAdd));
  };

  const handleOpenDeleteTabDialog = useCallback(() => {
    dispatch(deleteTabDialogOpened());
  }, [dispatch]);

  const handleOpenEditTabDialog = useCallback(() => {
    const tab = customTabs.find((e) => e.path === location.pathname);
    if (tab) {
      const tabToEdit = {
        attrName: tab.attrName,
        titlePl: tab.titlePl,
        titleEn: tab.titleEn,
        path: tab.path,
        code: tab.code,
        isNew: false,
      };
      dispatch(editableTabDialogOpened(tabToEdit));
    }
  }, [customTabs, dispatch, location]);

  const styledBaseTabs: Array<TabOption> = baseTabs.map((t) => ({
    ...t,
    Icon: (
      <t.Icon
        style={{
          height: "1rem",
          width: "1rem",
          marginRight: "0.4rem",
          marginBottom: "0px",
        }}
      />
    ),
  }));

  const mapCustomTabs = useMemo(
    () =>
      customTabs.map((e) => {
        return {
          label: i18n.language === "pl" ? e.titlePl : e.titleEn, //TODO
          Icon: isAuthorized && (
            <>
              <IconButton
                id="delete"
                component="span"
                onClick={handleOpenDeleteTabDialog}
                size="small"
                disabled={location.pathname !== e.path}
                style={{
                  position: "absolute",
                  top: 1,
                  right: 2,
                  zIndex: 300,
                  transform: "rotate(45deg)",
                }}
              >
                <AddIcon />
              </IconButton>
              <EditButtonContainer>
                <IconButton
                  component="span"
                  disabled={location.pathname !== e.path}
                  onClick={handleOpenEditTabDialog}
                  size="small"
                >
                  <EditIcon fontSize="medium" />
                </IconButton>
              </EditButtonContainer>
            </>
          ),
          path: e.path,
        } as TabOption;
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      customTabs,
      isAuthorized,
      location.pathname,
      handleOpenDeleteTabDialog,
      handleOpenEditTabDialog,
      i18n.language,
    ]
  );

  const tabs = useMemo(() => {
    return styledBaseTabs.concat(mapCustomTabs);
  }, [styledBaseTabs, mapCustomTabs]);

  useEffect(() => {
    const timerTab = setTimeout(() => {
      if (tabCarousel) {
        const currentTabId = tabs.findIndex((tab) => tab.path === menuOption);
        const newMenuOption =
          tabs[currentTabId === tabs.length - 1 ? 0 : currentTabId + 1].path;
        setMenuOption(newMenuOption);
        if (selectedAssetIds.length > 0) {
          navigate(`${newMenuOption}?assetIds=${selectedAssetIds.join(",")}`);
        } else {
          navigate(`${newMenuOption}`);
        }
      }
    }, env.REACT_APP_TAB_CAROUSEL_INTERVAL_TIME);
    return () => clearTimeout(timerTab);
    // eslint-disable-next-line
  }, [tabCarousel, menuOption]);

  const handleKpiView = () => {
    dispatch(kpiViewSelected(!kpiView));
  };

  // const handleTableView = () => {
  //   dispatch(tableViewSelected(!tableView));
  // };

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newMenuOption: string
  ) => {
    setMenuOption(newMenuOption);
    if (selectedAssetIds.length > 0) {
      navigate(`${newMenuOption}?assetIds=${selectedAssetIds.join(",")}`);
    } else {
      navigate(`${newMenuOption}`);
    }
  };

  return (
    <Root>
      <TabsContainer>
        <StyledTabs
          variant="scrollable"
          onChange={handleChange}
          value={menuOption}
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          {tabs.map((tab) => (
            <ButtonTabComponent
              wrapped
              disableRipple
              key={tab.path}
              label={t(tab.label)}
              icon={tab.Icon === false ? undefined : (tab.Icon as any)}
              value={tab.path}
            />
          ))}
        </StyledTabs>
        {isAuthorized && (
          <Tooltip title={t("Add new tab").toString()}>
            <AddTabButton
              aria-label="open"
              onClick={handleOpenAddTabDialog}
              size="small"
            >
              <AddCircleOutlineIcon />
            </AddTabButton>
          </Tooltip>
        )}
      </TabsContainer>
      <ActionsContainer>
        {menuOption === "/assets" && kpiView === false && !tableView && (
          <Tooltip title={t("Chart view").toString()}>
            <IconButton onClick={handleKpiView}>
              <ShowChartIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        )}
        {menuOption === "/assets" && kpiView === true && !tableView && (
          <Tooltip title={t("Main view").toString()}>
            <IconButton onClick={handleKpiView}>
              <DashboardIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        )}
        {/* <Tooltip placement="bottom-start" title={t("Table view").toString()}>
          <IconButton onClick={handleTableView}>
            <TableChartIcon fontSize="inherit" />
          </IconButton>
        </Tooltip> */}
      </ActionsContainer>
    </Root>
  );
};

export default TabsMenu;
