import { HubConnectionState } from "@microsoft/signalr";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../..";
import {
  TaskCompletedSocketDto,
  TaskPausedSocketDto,
  TaskRegisteredBadProductionSocketDto,
  TaskRegisteredGoodProductionSocketDto,
  TaskStartedSocketDto,
  TaskUnregisteredBadProductionSocketDto,
  TaskUnregisteredGoodProductionSocketDto,
} from "../../../services/sockets/hub-services/manufacturing-execution/tasksAllService";

export interface TasksState {
  connectionStatus: HubConnectionState;
}

export const initialState: TasksState = {
  connectionStatus: HubConnectionState.Disconnected,
};

export const tasksSlice = createSlice({
  name: "sockets",
  initialState,
  reducers: {
    tasksConnectionStatusChanged: (
      state,
      action: PayloadAction<HubConnectionState>
    ) => {
      state.connectionStatus = action.payload;
    },
    taskStartedSocketEvent: (
      state,
      action: PayloadAction<TaskStartedSocketDto>
    ) => {},
    taskPausedSocketEvent: (
      state,
      action: PayloadAction<TaskPausedSocketDto>
    ) => {},
    taskCompletedSocketEvent: (
      state,
      action: PayloadAction<TaskCompletedSocketDto>
    ) => {},
    taskRegisteredGoodProductionSocketEvent: (
      state,
      action: PayloadAction<TaskRegisteredGoodProductionSocketDto>
    ) => {},
    taskUnregisteredGoodProductionSocketEvent: (
      state,
      action: PayloadAction<TaskUnregisteredGoodProductionSocketDto>
    ) => {},
    taskRegisteredBadProductionSocketEvent: (
      state,
      action: PayloadAction<TaskRegisteredBadProductionSocketDto>
    ) => {},
    taskUnregisteredBadProductionSocketEvent: (
      state,
      action: PayloadAction<TaskUnregisteredBadProductionSocketDto>
    ) => {},
  },
});

export const {
  tasksConnectionStatusChanged,
  taskStartedSocketEvent,
  taskPausedSocketEvent,
  taskCompletedSocketEvent,
  taskRegisteredGoodProductionSocketEvent,
  taskUnregisteredGoodProductionSocketEvent,
  taskRegisteredBadProductionSocketEvent,
  taskUnregisteredBadProductionSocketEvent,
} = tasksSlice.actions;

export const selectManufacturingExecutionTasksConnectionStatus = createSelector(
  (state: RootState) =>
    state.sockets.manufacturingExecution.tasks.connectionStatus,
  (connectionStatus) => connectionStatus
);

export default tasksSlice.reducer;
