import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  JsonHubProtocol,
} from "@microsoft/signalr";
import { AnyAction, Dispatch } from "redux";
import { env } from "../../../../env";
import {
  taskCompletedSocketEvent,
  taskPausedSocketEvent,
  taskRegisteredBadProductionSocketEvent,
  taskRegisteredGoodProductionSocketEvent,
  tasksConnectionStatusChanged,
  taskStartedSocketEvent,
  taskUnregisteredBadProductionSocketEvent,
  taskUnregisteredGoodProductionSocketEvent,
} from "../../../../store/sockets/manufacturing-execution/tasksSlice";
import { selectIsAuthorized } from "../../../../store/user/authSlice";
import RetryPolicy from "../../retryPolicy";

export interface TaskStartedSocketDto {
  taskId: string;
  assetId: string;
  taskStateId: string;
}

export interface TaskPausedSocketDto {
  taskId: string;
  taskStateId: string;
}

export interface TaskCompletedSocketDto {
  taskId: string;
  taskStateId: string;
}

export interface TaskRegisteredGoodProductionSocketDto {
  taskId: string;
  productionReasonId: string;
  newTaskQuantity: number;
  addedQuantity: number;
}

export interface TaskUnregisteredGoodProductionSocketDto {
  taskId: string;
  productionReasonId: string;
  newTaskQuantity: number;
  addedQuantity: number;
}

export interface TaskRegisteredBadProductionSocketDto {
  taskId: string;
  productionReasonId: string;
  newTaskQuantity: number;
  addedQuantity: number;
}

export interface TaskUnregisteredBadProductionSocketDto {
  taskId: string;
  productionReasonId: string;
  newTaskQuantity: number;
  addedQuantity: number;
}

interface ITasksAllService {
  init(): void;
  connect(dispatch: Dispatch<AnyAction>, getState: any): void;
  disconnect(): void;
  subscribe(dispatch: Dispatch<AnyAction>, getState: any): void;
  unsubscribe(): void;
  joinAllGroup(assetId: string): void;
  leaveAllGroup(assetId: string): void;
}

const assetsHubUrl = `${env.REACT_APP_MES_API_URL}/manufacturing-execution/tasks`;

const retryPolicy = new RetryPolicy();

const protocol = new JsonHubProtocol();

const transport = HttpTransportType.WebSockets | HttpTransportType.LongPolling;

const options = {
  transport,
};

export class TasksAllService implements ITasksAllService {
  public connectionTasksHub: HubConnection | undefined;

  constructor() {
    this.init();
  }

  init() {
    this.connectionTasksHub = new HubConnectionBuilder()
      .withUrl(assetsHubUrl, options)
      .withHubProtocol(protocol)
      .withAutomaticReconnect(retryPolicy)
      .build();
  }

  async start(dispatch: Dispatch<AnyAction>) {
    try {
      await this.connectionTasksHub?.start().then(async (a) => {
        dispatch(tasksConnectionStatusChanged(HubConnectionState.Connected));
      });
    } catch (error) {
      console.error(error);
    }
  }

  async connect(dispatch: Dispatch<AnyAction>, getState: any) {
    if (this.connectionTasksHub?.state !== HubConnectionState.Disconnected) {
      this.unsubscribe();
      this.init();
      this.subscribe(dispatch, getState());
    }

    await this.start(dispatch);

    this.connectionTasksHub?.onreconnecting((error) => {
      console.error(error);
      dispatch(tasksConnectionStatusChanged(HubConnectionState.Reconnecting));
    });

    this.connectionTasksHub?.onreconnected((connectionId) => {
      dispatch(tasksConnectionStatusChanged(HubConnectionState.Connected));
    });

    this.connectionTasksHub?.onclose(async (error) => {
      error && console.error(error);
      const isAuthorized = selectIsAuthorized(getState());
      if (isAuthorized) {
        //STILL LOGGED IN
        await this.start(dispatch);
      } else {
        dispatch(tasksConnectionStatusChanged(HubConnectionState.Disconnected));
      }
    });
  }

  disconnect() {
    this.unsubscribe();
    this.connectionTasksHub?.stop();
  }

  subscribe(dispatch: Dispatch<AnyAction>, getState: any) {
    this.connectionTasksHub?.on("task-started", (message) => {
      if (message != null) {
        dispatch(taskStartedSocketEvent(message));
      }
    });
    this.connectionTasksHub?.on("task-paused", (message) => {
      if (message != null) {
        dispatch(taskPausedSocketEvent(message));
      }
    });
    this.connectionTasksHub?.on("task-completed", (message) => {
      if (message != null) {
        dispatch(taskCompletedSocketEvent(message));
      }
    });
    this.connectionTasksHub?.on(
      "task-registered-good-production",
      (message) => {
        if (message != null) {
          dispatch(taskRegisteredGoodProductionSocketEvent(message));
        }
      }
    );
    this.connectionTasksHub?.on(
      "task-unregistered-good-production",
      (message) => {
        if (message != null) {
          dispatch(taskUnregisteredGoodProductionSocketEvent(message));
        }
      }
    );
    this.connectionTasksHub?.on("task-registered-bad-production", (message) => {
      if (message != null) {
        dispatch(taskRegisteredBadProductionSocketEvent(message));
      }
    });
    this.connectionTasksHub?.on(
      "task-unregistered-bad-production",
      (message) => {
        if (message != null) {
          dispatch(taskUnregisteredBadProductionSocketEvent(message));
        }
      }
    );
  }

  async joinAllGroup(): Promise<void> {
    if (this.connectionTasksHub?.state === HubConnectionState.Connected) {
      this.connectionTasksHub?.invoke("JoinAllGroup");
    }
  }

  leaveAllGroup = () => {
    if (this.connectionTasksHub?.state === HubConnectionState.Connected) {
      this.connectionTasksHub?.invoke("LeaveAllGroup");
    }
  };

  unsubscribe = () => {
    this.connectionTasksHub?.off("task-started");
    this.connectionTasksHub?.off("task-paused");
    this.connectionTasksHub?.off("task-completed");
    this.connectionTasksHub?.off("task-registered-good-production");
    this.connectionTasksHub?.off("task-registered-bad-production");
  };
}
