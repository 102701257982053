import { Navigate, createBrowserRouter } from "react-router-dom";
import DefaultAreaComponent from "../components/common/defaultArea/DefaultAreaComponent";
import AssetDetailsLayout from "../components/layouts/AssetDetailsLayout";
import DefaultAreaLayout from "../components/layouts/DefaultAreaLayout";
import MainLayout from "../components/layouts/MainLayout";
import PageLayout from "../components/layouts/PageLayout";
import AssetsPage from "../components/main/assets/AssetsPage";
import AssetDetailsComponent from "../components/main/assets/asset-details/AssetDetailsPage";
import OperatorsPage from "../components/main/operators/OperatorsPage";
import OrdersPage from "../components/main/orders/OrdersPage";
import LoginLayout from "../components/layouts/LoginLayout";
import LoginPage from "../components/common/LoginPage";
import CustomPage from "../components/main/custom-tab/CustomPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    // errorElement: <ErrorPage />,
    errorElement: <Navigate to="/" />,
    children: [
      {
        path: "",
        element: <PageLayout />,
        errorElement: <Navigate to="/" />,
        children: [
          { path: "*", element: <CustomPage /> },
          {
            path: "assets",
            element: <AssetsPage />,
          },
          {
            path: "operators",
            element: <OperatorsPage />,
          },
          {
            path: "orders",
            element: <OrdersPage />,
          },
        ],
      },
      {
        path: "/assets/details",
        element: <AssetDetailsLayout />,
        errorElement: <Navigate to="/" />,
        children: [
          {
            path: ":assetId",
            element: <AssetDetailsComponent />,
          },
        ],
      },
      {
        path: "/admin",
        element: <LoginLayout />,
        children: [{ index: true, element: <LoginPage /> }],
      },
      {
        path: "defaultArea",
        errorElement: <Navigate to="/" />,
        element: <DefaultAreaLayout />,
        children: [{ index: true, element: <DefaultAreaComponent /> }],
      },
    ],
  },
]);

export default router;
