import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../..";

interface EditableTabDialogState {
  open: boolean;
  attrName: string;
  titlePl: string;
  titleEn: string;
  path: string;
  code: string;
  isNew: boolean;
  cmdRefreshTab: boolean;
}

export const initialState: EditableTabDialogState = {
  open: false,
  attrName: "",
  titlePl: "",
  titleEn: "",
  path: "",
  code: "",
  isNew: false,
  cmdRefreshTab: false,
};

export const editableTabSlice = createSlice({
  name: "editableTabDialog",
  initialState,
  reducers: {
    editableTabDialogOpened: (
      state,
      action: PayloadAction<{
        attrName: string;
        titlePl: string;
        titleEn: string;
        path: string;
        code: string;
        isNew: boolean;
      }>
    ) => {
      state.open = true;
      state.attrName = action.payload.attrName;
      state.titlePl = action.payload.titlePl;
      state.titleEn = action.payload.titleEn;
      state.path = action.payload.path;
      state.code = action.payload.code;
      state.isNew = action.payload.isNew ?? false;
    },
    editableTabDialogClosed: (state, action: PayloadAction<void>) => {
      state.open = false;
    },

    tabRefreshed: (state, action: PayloadAction<boolean>) => {
      state.cmdRefreshTab = action.payload;
    },
  },
});

export const {
  editableTabDialogOpened,
  editableTabDialogClosed,
  tabRefreshed,
} = editableTabSlice.actions;

export const selectRefreshTab = createSelector(
  (state: RootState) => state.dialogs.editableTabDialog.cmdRefreshTab,
  (cmdRefreshTab) => cmdRefreshTab
);

export const selectEditableTab = createSelector(
  (state: RootState) => state.dialogs.editableTabDialog,
  (editableTabDialog) => editableTabDialog
);

export default editableTabSlice.reducer;
