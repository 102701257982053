import { useEffect, useState } from "react";
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from "../../services/localStorageService";

const useLocalStorageState = (defaultValue: any, key: string) => {
  const [storedValue, setStoredValue] = useState(() => {
    const currentLocalStorageValue = getFromLocalStorage(key);
    if (currentLocalStorageValue === undefined) {
      saveToLocalStorage(key, defaultValue);
      return defaultValue;
    } else return currentLocalStorageValue;
  });

  useEffect(() => {
    function handleLocalStorageChange(event: StorageEvent) {
      if (event.key === key) {
        setStoredValue(event.newValue);
      }
    }
    window.addEventListener("storage", handleLocalStorageChange);

    return () => {
      window.removeEventListener("storage", handleLocalStorageChange);
    };
  }, [key]);

  const setValue = (value: any) => {
    const event = new StorageEvent("storage", {
      key: key,
      oldValue: storedValue,
      newValue: value,
    });

    saveToLocalStorage(key, value);
    setStoredValue(value);

    window.dispatchEvent(event);
  };

  return [storedValue, setValue];
};

export default useLocalStorageState;
