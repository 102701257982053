import {
  EnglishIcon,
  EntityIcon,
  PolishIcon,
  changeLanguage,
} from "@improdis/core";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LanguageIcon from "@mui/icons-material/Language";
import SettingsIcon from "@mui/icons-material/Settings";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import {
  Collapse,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Switch,
  styled,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  assetViewCarouselSelected,
  selectAssetViewCarousel,
  selectTabCarousel,
  tabCarouselSelected,
} from "../../../store/setup/setupSlice";

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  "> svg": {
    height: 20,
    width: 20,
  },
}));

const NestedListItemIcon = styled(StyledListItemIcon)(({ theme }) => ({
  "> svg": {
    paddingLeft: "1.2rem",
    paddingRight: "0.5rem",
  },
}));

const StyledSettingsIcon = styled(SettingsIcon)(({ theme }) => ({
  height: "2rem",
  width: "2rem",
}));

export interface AppBarSettingsProps {}

const AppBarSettings: React.FC<AppBarSettingsProps> = () => {
  const [openLanguage, setOpenLanguage] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const tabCarousel = useAppSelector(selectTabCarousel);
  const assetViewCarousel = useAppSelector(selectAssetViewCarousel);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const handleClickLanguage = () => {
    setOpenLanguage(!openLanguage);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAssetViewCarouselChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(assetViewCarouselSelected(e.target.checked));
  };

  const handleTabCarouselChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(tabCarouselSelected(e.target.checked));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeLanguage = (
    event: React.MouseEvent<EventTarget>,
    lng: string
  ) => {
    i18n.changeLanguage(lng);
    changeLanguage(i18n.language);
    handleClose();
  };

  const handleChangeDefaultArea = (event: React.MouseEvent<EventTarget>) => {
    navigate("/defaultArea");
    handleClose();
  };

  return (
    <>
      <IconButton onClick={handleMenu} color="inherit" size="medium">
        <StyledSettingsIcon />
      </IconButton>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuList>
          <MenuItem>
            <StyledListItemIcon>
              <ViewCarouselIcon />
            </StyledListItemIcon>
            <ListItemText>{t("Carousel")}</ListItemText>
            <Switch
              checked={tabCarousel}
              color="default"
              onChange={handleTabCarouselChange}
            />
          </MenuItem>
          <MenuItem>
            <StyledListItemIcon>
              <ViewCarouselIcon />
            </StyledListItemIcon>
            <ListItemText>{t("CarouselEnt")}</ListItemText>
            <Switch
              checked={assetViewCarousel}
              color="default"
              onChange={handleAssetViewCarouselChange}
            />
          </MenuItem>
          <MenuItem onClick={handleClickLanguage}>
            <StyledListItemIcon>
              <LanguageIcon />
            </StyledListItemIcon>
            <ListItemText>{t("Language")}</ListItemText>
            {openLanguage ? <ExpandLess /> : <ExpandMore />}
          </MenuItem>
          <Collapse in={openLanguage} timeout="auto" unmountOnExit>
            <MenuItem onClick={(event) => handleChangeLanguage(event, "en")}>
              <NestedListItemIcon>
                <EnglishIcon />
              </NestedListItemIcon>
              <ListItemText>EN</ListItemText>
            </MenuItem>
            <MenuItem onClick={(event) => handleChangeLanguage(event, "pl")}>
              <NestedListItemIcon>
                <PolishIcon />
              </NestedListItemIcon>
              <ListItemText>PL</ListItemText>
            </MenuItem>
          </Collapse>
          <MenuItem onClick={handleChangeDefaultArea}>
            <StyledListItemIcon>
              <EntityIcon style={{ fill: "#0000008a" }} />
            </StyledListItemIcon>
            <ListItemText>{t("Change default area")}</ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};

export default AppBarSettings;
