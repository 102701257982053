import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    data-name="imfactory_logo_dark (1)"
    xmlns="http://www.w3.org/2000/svg"
    width={285.24}
    height={76.891}
    viewBox="0 0 285 77"
    {...props}
  >
    <path
      data-name="Rectangle 892"
      d="M94.006 8.9h5.682v29.682h-5.682Z"
      fill="#00c1de"
    />
    <path
      data-name="Path 10758"
      d="M124.24 25.818a4.033 4.033 0 1 1 8.056 0v12.763h5.6V25.606c0-6.4-4.071-9.5-9.625-9.5a8.306 8.306 0 0 0-6.827 2.883 7.034 7.034 0 0 0-5.894-2.883 6.27 6.27 0 0 0-4.961 2.417V16.7h-5.6v21.88h5.6V25.818a4.033 4.033 0 1 1 8.056 0v12.763h5.6Z"
      fill="#00c1de"
    />
    <path
      data-name="Path 10759"
      d="M148.581 14.539h12.381V8.9H142.9v29.68h5.685V26.412h12.381v-5.64h-12.385Z"
      fill="#00259a"
    />
    <path
      data-name="Path 10760"
      d="M170.881 30.016c1.06-.551 4.113-.806 7.293-1.018v.721c0 2.756-2.289 4.367-5.173 4.367-2.5 0-3.308-1.145-3.308-2.2a1.764 1.764 0 0 1 1.187-1.866m7.208-5.639c-3.307.254-7.675.594-9.837 1.611-3.053 1.442-4.155 3.35-4.155 6.53 0 4.028 2.968 6.487 7.972 6.487 3.35 0 5.088-1.272 6.106-2.926v2.5h5.6V26.157c0-6.064-3.477-10.049-9.753-10.049-5.343 0-8.608 2.883-9.5 7.463h5.64a4.237 4.237 0 0 1 7.929.763Z"
      fill="#00259a"
    />
    <path
      data-name="Path 10761"
      d="M201.708 30.61a3.737 3.737 0 0 1-4.071 3.35c-2.671 0-4.155-1.7-4.155-4.282v-4.24c0-2.586 1.484-4.283 4.155-4.283a3.793 3.793 0 0 1 4.071 3.35h5.6c-.594-5.131-3.986-8.4-9.668-8.4-6.275 0-9.753 3.986-9.753 10.049v2.8c0 6.064 3.477 10.049 9.753 10.049 5.682 0 9.074-3.265 9.668-8.4Z"
      fill="#00259a"
    />
    <path
      data-name="Path 10762"
      d="M219.6 33.536c-1.357 0-1.611-.721-1.611-1.738v-10.05h5.385V16.7h-5.387v-4.959h-5.6V16.7h-3.1v5.046h3.1v11.238c0 3.52 1.7 5.6 5.6 5.6h5.385v-5.048Z"
      fill="#00259a"
    />
    <path
      data-name="Path 10763"
      d="M240.209 29.677c0 2.586-1.484 4.282-4.156 4.282s-4.155-1.7-4.155-4.282v-4.24c0-2.586 1.484-4.283 4.155-4.283s4.156 1.7 4.156 4.283Zm-13.909-.721c0 6.064 3.478 10.044 9.754 10.044s9.752-3.986 9.752-10.049v-2.8c0-6.063-3.477-10.049-9.752-10.049s-9.754 3.991-9.754 10.054Z"
      fill="#00259a"
    />
    <path
      data-name="Path 10764"
      d="M255.813 26.03a3.931 3.931 0 0 1 4.155-4.24h2.632v-5.47h-1.7a5.417 5.417 0 0 0-5.089 2.756V16.7h-5.6v21.88h5.6Z"
      fill="#00259a"
    />
    <path
      data-name="Path 10765"
      d="M285.24 16.7h-5.894l-4.876 13.95-4.876-13.95H263.7l7.8 21.88-.636 1.781a1.355 1.355 0 0 1-1.484.975h-2.332v5.046h4.152c3.9 0 4.24-2.417 5.428-5.724Z"
      fill="#00259a"
    />
    <path
      data-name="Path 10766"
      d="M101.914 52.857h1.852V64.98h-1.84v-8.417l-2.5 5.161h-1.519l-2.493-5.161v8.417h-1.836V52.857h1.853l3.238 6.7Z"
      fill="#00259a"
    />
    <path
      data-name="Path 10767"
      d="M108.09 57.949h5.3v1.836h-5.3v3.359h5.3v1.836h-7.153V52.857h7.153v1.836h-5.3Z"
      fill="#00259a"
    />
    <path
      data-name="Path 10768"
      d="M114.962 61.223h1.888a2.436 2.436 0 0 0 2.614 2.1c1.247 0 2.044-.606 2.044-1.541 0-.9-.5-1.455-1.714-1.75l-2.148-.519a3.228 3.228 0 0 1-2.493-3.4c0-2.338 1.766-3.429 3.915-3.429a3.817 3.817 0 0 1 4.139 3.412h-1.89a2.1 2.1 0 0 0-2.234-1.576c-1.438 0-2.044.676-2.044 1.593a1.572 1.572 0 0 0 1.368 1.611l2.079.5a3.378 3.378 0 0 1 2.909 3.585c0 1.957-1.437 3.343-3.965 3.343a4.1 4.1 0 0 1-4.469-3.931"
      fill="#00259a"
    />
    <path
      data-name="Path 10769"
      d="M133.285 65.154a4.055 4.055 0 0 1-4.348-4.278v-3.912a4.349 4.349 0 0 1 8.678-.346h-1.853a2.5 2.5 0 0 0-4.971.346v3.914a2.5 2.5 0 0 0 4.971.347h1.853a4.038 4.038 0 0 1-4.33 3.931"
      fill="#00259a"
    />
    <path
      data-name="Path 10770"
      d="M139.465 59.507a3.724 3.724 0 1 1 7.447 0v1.94a3.724 3.724 0 1 1-7.448 0Zm5.629.034a1.907 1.907 0 1 0-3.811 0v1.87a1.907 1.907 0 1 0 3.811 0Z"
      fill="#00259a"
    />
    <path
      data-name="Path 10771"
      d="M156.371 64.98h-1.818v-5.438a1.825 1.825 0 1 0-3.637 0v5.438H149.1v-8.937h1.82v.78a2.439 2.439 0 0 1 1.991-1.022 3.24 3.24 0 0 1 2.563 1.264 3.392 3.392 0 0 1 2.719-1.264 3.451 3.451 0 0 1 3.637 3.706v5.473h-1.818v-5.438a1.825 1.825 0 1 0-3.637 0Z"
      fill="#00259a"
    />
    <path
      data-name="Path 10772"
      d="M171.617 61.447a3.44 3.44 0 0 1-3.551 3.706 2.45 2.45 0 0 1-2.078-1.022v4.035h-1.818V56.043h1.818v.779a2.451 2.451 0 0 1 2.078-1.021 3.44 3.44 0 0 1 3.551 3.706Zm-1.82-1.9a1.906 1.906 0 1 0-3.81 0v1.87a1.906 1.906 0 1 0 3.81 0Z"
      fill="#00259a"
    />
    <path
      data-name="Path 10773"
      d="M179.13 62.14h1.853a3.409 3.409 0 0 1-3.672 3.014 3.449 3.449 0 0 1-3.724-3.707v-1.94a3.724 3.724 0 1 1 7.447 0v1.611h-5.629v.294a1.774 1.774 0 0 0 1.9 1.992 1.725 1.725 0 0 0 1.818-1.264m-3.723-2.65v.018h3.811v-.016a1.906 1.906 0 1 0-3.811 0"
      fill="#00259a"
    />
    <path
      data-name="Path 10774"
      d="M185.642 62.381c0 .658.121.849.832.849h1.368v1.751h-1.888a1.863 1.863 0 0 1-2.13-2.131v-5.092h-1.264v-1.715h1.264v-2.026h1.818v2.026h2.2v1.715h-2.2Z"
      fill="#00259a"
    />
    <path
      data-name="Path 10775"
      d="M195.059 62.14h1.853a3.409 3.409 0 0 1-3.672 3.014 3.449 3.449 0 0 1-3.724-3.707v-1.94a3.724 3.724 0 1 1 7.447 0v1.611h-5.629v.294a1.774 1.774 0 0 0 1.9 1.992 1.725 1.725 0 0 0 1.818-1.264m-3.724-2.65v.018h3.811v-.016a1.906 1.906 0 1 0-3.811 0"
      fill="#00259a"
    />
    <path
      data-name="Path 10776"
      d="M200.9 64.98h-1.818v-8.937h1.818v.78a2.452 2.452 0 0 1 2.078-1.022 3.44 3.44 0 0 1 3.551 3.706v5.473h-1.821v-5.438a1.906 1.906 0 1 0-3.81 0Z"
      fill="#00259a"
    />
    <path
      data-name="Path 10777"
      d="M212.345 65.153a3.449 3.449 0 0 1-3.724-3.706v-1.94a3.732 3.732 0 0 1 7.429-.485h-1.85a1.71 1.71 0 0 0-1.854-1.472 1.774 1.774 0 0 0-1.905 1.992v1.87a1.774 1.774 0 0 0 1.9 1.992 1.727 1.727 0 0 0 1.871-1.472h1.84a3.422 3.422 0 0 1-3.706 3.221"
      fill="#00259a"
    />
    <path
      data-name="Path 10778"
      d="M223.478 62.14h1.854a3.409 3.409 0 0 1-3.672 3.014 3.449 3.449 0 0 1-3.724-3.707v-1.94a3.724 3.724 0 1 1 7.447 0v1.611h-5.629v.294a1.774 1.774 0 0 0 1.9 1.992 1.725 1.725 0 0 0 1.818-1.264m-3.724-2.65v.018h3.811v-.016a1.906 1.906 0 1 0-3.811 0"
      fill="#00259a"
    />
    <path
      data-name="Path 10779"
      d="M235.478 65.154a4.055 4.055 0 0 1-4.347-4.278v-3.912a4.349 4.349 0 0 1 8.677-.346h-1.852a2.5 2.5 0 0 0-4.971.346v3.914a2.5 2.5 0 0 0 4.971.347h1.852a4.037 4.037 0 0 1-4.33 3.931"
      fill="#00259a"
    />
    <path
      data-name="Path 10780"
      d="M247.2 62.14h1.856a3.409 3.409 0 0 1-3.672 3.014 3.449 3.449 0 0 1-3.724-3.707v-1.94a3.724 3.724 0 1 1 7.448 0v1.611h-5.631v.294a1.774 1.774 0 0 0 1.906 1.992 1.725 1.725 0 0 0 1.817-1.264m-3.724-2.65v.018h3.811v-.016a1.906 1.906 0 1 0-3.811 0"
      fill="#00259a"
    />
    <path
      data-name="Path 10781"
      d="M253.046 64.98h-1.818v-8.937h1.818v.78a2.451 2.451 0 0 1 2.078-1.022 3.44 3.44 0 0 1 3.551 3.706v5.473h-1.819v-5.438a1.906 1.906 0 1 0-3.81 0Z"
      fill="#00259a"
    />
    <path
      data-name="Path 10782"
      d="M263.382 62.381c0 .658.121.849.832.849h1.368v1.751h-1.888a1.863 1.863 0 0 1-2.13-2.131v-5.092H260.3v-1.715h1.264v-2.026h1.818v2.026h2.2v1.715h-2.2Z"
      fill="#00259a"
    />
    <path
      data-name="Path 10783"
      d="M272.8 62.14h1.851a3.409 3.409 0 0 1-3.672 3.014 3.449 3.449 0 0 1-3.724-3.707v-1.94a3.724 3.724 0 1 1 7.447 0v1.611h-5.629v.294a1.774 1.774 0 0 0 1.9 1.992 1.725 1.725 0 0 0 1.827-1.264m-3.724-2.65v.018h3.811v-.016a1.906 1.906 0 1 0-3.811 0"
      fill="#00259a"
    />
    <path
      data-name="Path 10784"
      d="M280.555 57.654a1.761 1.761 0 0 0-1.9 1.975v5.351h-1.819v-8.937h1.818v.866a2.452 2.452 0 0 1 2.078-1.022h.693v1.766Z"
      fill="#00259a"
    />
    <path
      data-name="Rectangle 893"
      d="M37.006 0h2.951v2.951h-2.951Z"
      fill="#00c1de"
    />
    <path
      data-name="Rectangle 894"
      d="M37.006 73.94h2.951v2.951h-2.951Z"
      fill="#00c1de"
    />
    <path
      data-name="Path 10785"
      d="M53.184 50.047h-36.8v-8.331l2.6-15.275h3.645l2.785 13.539 1.909 1.041 1.041-.521 8.679-4.339v3.645l2.083 1.215.694-.347V33.9l-1.906-1.21-10.246 5.034-.174-1.042-2.43-11.8-1.214-1.213h-5.9l-1.391 1.21-2.95 16.49v10.067L15 52.825h38.184Z"
      fill="#00259a"
    />
    <path
      data-name="Path 10786"
      d="m61.342 32.69-9.72 4.86V33.9l-2.083-1.21-9.72 4.86v3.124l9.026-4.513v3.645l2.083 1.215 9.72-4.86v6.73h2.773V33.9Z"
      fill="#00c1de"
    />
    <path
      data-name="Rectangle 895"
      d="m10.234 12.316 2.087-2.087 2.087 2.087-2.087 2.084Z"
      fill="#00c1de"
    />
    <path
      data-name="Rectangle 896"
      d="m62.518 64.6 2.082-2.087 2.091 2.087-2.091 2.087Z"
      fill="#00c1de"
    />
    <path
      data-name="Rectangle 897"
      d="M0 37.024h2.951v2.951H0Z"
      fill="#00c1de"
    />
    <path
      data-name="Rectangle 898"
      d="M73.94 37.024h2.951v2.951H73.94Z"
      fill="#00c1de"
    />
    <path
      data-name="Rectangle 899"
      d="m10.23 64.66 2.087-2.087L14.4 64.66l-2.087 2.087Z"
      fill="#00c1de"
    />
    <path
      data-name="Rectangle 900"
      d="M62.513 12.376 64.6 10.29l2.087 2.087-2.087 2.086Z"
      fill="#00c1de"
    />
    <path
      data-name="Path 10787"
      d="m65.809 17.377 7 16.731h2.955L67.9 15.294Z"
      fill="#00259a"
    />
    <path
      data-name="Path 10788"
      d="M59.432 65.638 42.873 72.4v3.12l18.644-7.8Z"
      fill="#00259a"
    />
    <path
      data-name="Path 10789"
      d="m72.667 42.891-7 16.562 2.083 2.083 7.866-18.645Z"
      fill="#00259a"
    />
    <path
      data-name="Path 10790"
      d="m42.873 4.091 16.852 6.949 2.083-2.083-18.935-7.815Z"
      fill="#00259a"
    />
    <path
      data-name="Path 10791"
      d="M11.086 17.292 9 15.209l-7.734 18.9h2.957Z"
      fill="#00259a"
    />
    <path
      data-name="Path 10792"
      d="M4.362 42.891H1.411l7.683 18.782 2.133-2.133Z"
      fill="#00259a"
    />
    <path
      data-name="Path 10793"
      d="M17.22 11.006 34.09 4.05V1.1L15.137 8.923Z"
      fill="#00259a"
    />
    <path
      data-name="Path 10794"
      d="m34.091 72.539-16.529-6.915-2.083 2.083L34.09 75.49Z"
      fill="#00259a"
    />
  </svg>
);

export default SvgComponent;
