import { Box, Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AlarmOptions } from "../../../store/common/mqttSlice";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const Root = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: "35px",
}));

const ValueTypography = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  "&.MuiTypography-body2": {
    marginRight: "1rem",
  },
}));

const ErrorIcon = styled(ErrorOutlineIcon)(({ theme }) => ({
  color: "red",
}));

interface AlarmProps {
  value: number;
  options: AlarmOptions | undefined;
}

const AlarmComponent: React.FC<AlarmProps> = ({ value, options }) => {
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        options?.operator !== undefined &&
        options?.alarmValue !== undefined
      ) {
        options.operator === "ge" &&
          value >= options.alarmValue &&
          setVisible((visible) => !visible);
        options.operator === "le" &&
          value <= options.alarmValue &&
          setVisible((visible) => !visible);
        options.operator === "g" &&
          value > options.alarmValue &&
          setVisible((visible) => !visible);
        options.operator === "l" &&
          value < options.alarmValue &&
          setVisible((visible) => !visible);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [value, options?.alarmValue, options?.operator]);

  return (
    <Root>
      <ValueTypography
        variant="body2"
        gutterBottom
        style={{
          color: visible ? "red" : "",
          backgroundColor: visible ? "" : "",
        }}
      >
        {visible && <ErrorIcon></ErrorIcon>}
        {value}
      </ValueTypography>
    </Root>
  );
};

export default AlarmComponent;
