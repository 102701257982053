import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Paper, Typography, styled } from "@mui/material";
import React, { useState } from "react";
import { TreeItem, TreeView } from "@mui/x-tree-view";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import useLocalStorageState from "../../../helpers/hooks/useLocalStorageState";
import { LocalStorageKey } from "../../../helpers/localStorageKeys";
import ImFactory from "../../../icons/ImFactory";
import { saveToLocalStorage } from "../../../services/localStorageService";
import {
  NestedAsset,
  getNestedAssetNodeId,
  selectNestedAssets,
} from "../../../store/definitions/assetsSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  SelectedAsset,
  assetsSelected,
} from "../../../store/user/assets-selection/assetsSelectionSlice";

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  height: "100%",

  "& > *": {
    margin: "auto",
    width: "100%",
    padding: "1rem",
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  maxWidth: "600px",
}));

const LogoContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginBottom: "10px",
  "& >p": {
    paddingLeft: "10px",
    width: "90%",
    fontSize: "1.2rem",
    margin: "0px 0px 0px 0px",
  },
}));

const Title = styled("p")(({ theme }) => ({
  margin: "0px 0px 5px 0px",
  width: "90%",
  fontSize: "0.8rem",
}));

const StyledSimpleBar = styled(SimpleBar)(({ theme }) => ({
  height: "15rem",
  marginBottom: "10px",
}));

const ListContainer = styled("div")(({ theme }) => ({
  border: "0.5px solid #c3c3c3",
  width: "90%",
}));

const TreeLabel = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0.5, 0),
}));

const SelectButton = styled(Button)(({ theme }) => ({
  width: "90%",
  margin: "10px 0px 0px 0px",
}));

interface DefaultAreaComponentProps {}

const DefaultAreaComponent: React.FC<DefaultAreaComponentProps> = () => {
  const { t } = useTranslation();
  const [defaultAssets] = useLocalStorageState(
    [],
    LocalStorageKey.DEFAULT_ASSET_IDS
  );
  const nestedAssets = useAppSelector(selectNestedAssets);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [selected, setSelected] = useState<Array<string>>(defaultAssets);

  const renderTree = (nodes: Array<NestedAsset>) =>
    nodes.map((node) => {
      const id = getNestedAssetNodeId(node.sourceStack, node.id);
      return (
        <TreeItem
          key={id}
          nodeId={id}
          label={
            <TreeLabel>
              <Typography variant="secondaryLabel">{node.name}</Typography>
            </TreeLabel>
          }
        >
          {Array.isArray(node.childrenAssets) &&
          node.childrenAssets.length > 0 &&
          node.childrenAssets.some(
            (ch) =>
              Array.isArray(ch.childrenAssets) && ch.childrenAssets.length > 0
          )
            ? node.childrenAssets.map((ch) => renderTree([ch]))
            : null}
        </TreeItem>
      );
    });

  const handleNodeSelect = (
    event: React.ChangeEvent<{}>,
    nodeIds: Array<string>
  ) => {
    setSelected(nodeIds);
  };

  const handleConfirm = () => {
    saveToLocalStorage(LocalStorageKey.DEFAULT_ASSET_IDS, selected);
    const selectedAssets = selected.map(
      (n) =>
        ({
          sourceStack: n.split("/").slice(0, -1),
          assetId: n.split("/")[n.split("/").length - 1],
        } as SelectedAsset)
    );

    navigate(
      `/assets?assetIds=${selectedAssets.map((a) => a.assetId).join(",")}`
    );
    dispatch(assetsSelected(selectedAssets));
  };

  return (
    <Root>
      <StyledPaper elevation={6}>
        <LogoContainer>
          <ImFactory />
          <p>
            <b>{process.env.REACT_APP_WEBSITE_NAME}</b>
          </p>
        </LogoContainer>
        <Title>{t("Default area selection")}</Title>
        <ListContainer>
          <StyledSimpleBar autoHide={false}>
            <TreeView
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              selected={selected}
              multiSelect
              style={{ margin: "5px 10px" }}
              onNodeSelect={handleNodeSelect}
            >
              {renderTree(nestedAssets)}
            </TreeView>
          </StyledSimpleBar>
        </ListContainer>
        <SelectButton
          variant="contained"
          color="primary"
          onClick={handleConfirm}
        >
          {t("Select")}
        </SelectButton>
      </StyledPaper>
    </Root>
  );
};

export default DefaultAreaComponent;
