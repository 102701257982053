import { FunctionComponent } from "react";
import { FallbackProps } from "react-error-boundary";
import { useAppDispatch } from "../../../store/hooks";
import AppError from "./AppError";
import ComponentError from "./ComponentError";
import ViewError from "./ViewError";
import { useNavigate } from "react-router-dom";
import { logOutAsync } from "../../../store/user/authSlice";
import { AppThunkDispatch } from "../../../store";

export enum ErrorLevel {
  App,
  View,
  Component,
}

interface ErrorFallbackProps extends FallbackProps {
  level: ErrorLevel;
}

const ErrorFallback: FunctionComponent<ErrorFallbackProps> = ({
  level,
  error,
  resetErrorBoundary,
}) => {
  const thunkDispatch = useAppDispatch() as AppThunkDispatch;
  const navigate = useNavigate();

  const handleClear = async () => {
    // clear local storage
    global?.localStorage?.clear();
    //  clear cache
    //FIX for reference error on some browsers
    try {
      caches?.keys()?.then((names) => {
        names?.forEach((name) => {
          caches?.delete(name);
        });
      });
    } catch (Exception) {}

    await thunkDispatch(logOutAsync());
    navigate("/");
    resetErrorBoundary();
  };

  return (
    <>
      {level === ErrorLevel.App && (
        <AppError
          error={error}
          resetErrorBoundary={resetErrorBoundary}
          onClear={handleClear}
        />
      )}
      {level === ErrorLevel.View && (
        <ViewError
          error={error}
          resetErrorBoundary={resetErrorBoundary}
          onClear={handleClear}
        />
      )}
      {level === ErrorLevel.Component && (
        <ComponentError error={error} resetErrorBoundary={resetErrorBoundary} />
      )}
    </>
  );
};

export default ErrorFallback;
