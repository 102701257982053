export const getFromLocalStorage = (key: string): any | undefined => {
  let ls = {};
  if (global.localStorage) {
    try {
      const stringItem = global.localStorage.getItem(key);
      if (stringItem !== null) {
        ls = JSON.parse(stringItem);
        return ls;
      }
    } catch (e) {
      /*Ignore*/
    }
  }
  // TODO
  // return ls[key];
};

export const deleteFromLocalStorage = (key: string) => {
  if (global.localStorage) {
    global.localStorage.removeItem(key);
  }
};

export const saveToLocalStorage = (key: string, value: any) => {
  if (global.localStorage) {
    global.localStorage.setItem(key, JSON.stringify(value));
  }
};
