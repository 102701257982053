import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={271.369}
    height={356.772}
    viewBox="0 0 271 356"
    {...props}
  >
    <g data-name="Group 78" opacity={0.67}>
      <g data-name="Path 4285" fill="#efefef">
        <path d="M43.529 356.272c-6.865 0-12.965-1.12-18.133-3.329a33.402 33.402 0 0 1-6.915-3.977 33.547 33.547 0 0 1-5.63-5.372c-2.624-3.15-4.854-6.959-6.628-11.319-1.69-4.154-3.021-8.941-3.956-14.23-1.721-9.74-2.181-21.274-1.406-35.26 1.435-25.893 6.879-55.456 12.144-84.045 5.524-29.99 10.74-58.318 10.74-79.364 0-9.14.632-17.931 1.876-26.132 1.251-8.25 3.154-16.099 5.654-23.329 2.52-7.287 5.709-14.12 9.477-20.312 3.806-6.254 8.297-12.005 13.347-17.093 5.112-5.15 10.925-9.75 17.276-13.67 6.44-3.976 13.597-7.354 21.27-10.04 7.792-2.727 16.313-4.809 25.328-6.187C127.137 1.21 137.043.5 147.416.5c20.914 0 37.373 2.176 50.316 6.652 12.518 4.328 21.904 10.879 28.694 20.025 3.268 4.402 5.923 9.35 8.118 15.127 2.084 5.486 3.697 11.58 4.93 18.627 2.139 12.227 3.093 26.997 3.093 47.888 0 10.437 4.994 24.875 10.776 41.592 3.389 9.795 7.229 20.898 10.372 32.23 3.593 12.95 5.777 24.395 6.678 34.99.525 6.171.615 12.172.267 17.837-.375 6.126-1.276 12.08-2.675 17.695a87.468 87.468 0 0 1-6.29 17.285c-2.84 5.799-6.4 11.389-10.583 16.615-5.89 7.362-13.015 14.274-21.175 20.544-7.585 5.829-16.247 11.244-25.747 16.095-16.762 8.56-36.548 15.603-58.81 20.933-17.94 4.296-37.4 7.472-57.841 9.44-18.876 1.816-34.352 2.197-44.01 2.197Z" />
        <path d="M147.416 1c-10.348 0-20.229.709-29.368 2.107-8.984 1.374-17.475 3.448-25.238 6.165-7.639 2.674-14.762 6.036-21.172 9.993-6.318 3.9-12.1 8.475-17.184 13.597-5.023 5.06-9.49 10.78-13.275 17-3.75 6.162-6.923 12.963-9.432 20.217-2.49 7.2-4.385 15.02-5.632 23.24-1.24 8.176-1.87 16.943-1.87 26.057 0 21.092-5.22 49.44-10.748 79.455-5.262 28.574-10.704 58.12-12.137 83.982-.773 13.947-.315 25.443 1.4 35.146.928 5.253 2.249 10.006 3.926 14.128 1.755 4.312 3.958 8.076 6.548 11.187a33.05 33.05 0 0 0 5.547 5.292 32.902 32.902 0 0 0 6.811 3.918c5.106 2.182 11.14 3.288 17.937 3.288 9.647 0 25.106-.38 43.962-2.196 20.417-1.965 39.855-5.136 57.773-9.427 22.223-5.32 41.972-12.35 58.699-20.892 9.472-4.837 18.109-10.236 25.67-16.046 8.128-6.246 15.223-13.13 21.089-20.46 4.16-5.198 7.7-10.757 10.523-16.523a86.961 86.961 0 0 0 6.254-17.186c1.393-5.586 2.288-11.51 2.662-17.605.346-5.64.257-11.617-.266-17.763-.898-10.564-3.077-21.98-6.661-34.9-3.14-11.317-6.977-22.411-10.363-32.2-5.798-16.76-10.804-31.234-10.804-41.755 0-20.861-.952-35.604-3.086-47.802-1.227-7.016-2.832-13.079-4.905-18.536-2.177-5.732-4.811-10.64-8.052-15.006-6.728-9.063-16.036-15.556-28.455-19.85C184.679 3.166 168.274 1 147.416 1m0-1c84.23 0 95.65 35.405 95.65 108.82 0 35.238 57.618 117.095 8.436 178.556-49.181 61.46-164.174 69.396-207.973 69.396-84.23 0-20.283-163.981-20.283-237.396C23.246 45.962 63.186 0 147.416 0Z" />
      </g>
    </g>
  </svg>
);

export default SvgComponent;
