import SearchIcon from "@mui/icons-material/Search";
import { IconButton, Paper, styled, Typography } from "@mui/material";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";
import { selectAssetLogonsByAssetId } from "../../../store/main/asset-logons/assetsLogonsSlice";
import { selectRunningTaskExecsByAssetId } from "../../../store/main/task-execs/taskExecsSlice";
import { selectUtilizationExecsByAssetId } from "../../../store/main/util-execs/utilExecsSlice";
import { selectKpiView } from "../../../store/setup/setupSlice";
import { TileContent } from "../../common/tiles/TileContent";
import { TileHeader } from "../../common/tiles/TileHeader";
import BasicAssetTileComponent from "./basic-tile/BasicAssetTileComponent";
import KpiAssetTileComponent from "./kpi-tile/KpiAssetTileComponent";

interface ReasonIndicatorProps {
  color: string | undefined;
}
const ReasonIndicator = styled("div")<ReasonIndicatorProps>(
  ({ theme, color }) => ({
    minHeight: "16px",
    backgroundColor: color,
  })
);

interface AssetTileProps {
  title: string;
  assetId: string;
}

const AssetTile: FunctionComponent<AssetTileProps> = ({ title, assetId }) => {
  const navigate = useNavigate();
  const kpiView = useAppSelector(selectKpiView);
  const utilExec = useAppSelector((state) =>
    selectUtilizationExecsByAssetId(state, assetId)
  );
  const taskExecs = useAppSelector((state) =>
    selectRunningTaskExecsByAssetId(state, assetId)
  );
  const assetLogons = useAppSelector((state) =>
    selectAssetLogonsByAssetId(state, assetId)
  );

  const handleEntDetailsView = () => {
    navigate(`/assets/details/${assetId}`);
  };

  return (
    <Paper elevation={2} square>
      <ReasonIndicator color={utilExec?.utilStateColor ?? ""} />
      <TileContent>
        <TileHeader>
          <Typography variant="h6">{title}</Typography>
          <IconButton size="small" onClick={handleEntDetailsView}>
            <SearchIcon />
          </IconButton>
        </TileHeader>
        {kpiView ? (
          <KpiAssetTileComponent utilExec={utilExec} />
        ) : (
          <BasicAssetTileComponent
            assetId={assetId}
            utilExec={utilExec}
            taskExecs={taskExecs}
            assetLogons={assetLogons}
          />
        )}
      </TileContent>
    </Paper>
  );
};

export default AssetTile;
