import React from "react";
import { Outlet } from "react-router-dom";
import AssetDetailsAppBarComponent from "../common/app-bar/AssetDetailsAppBar";
import Footer from "../common/app-bar/FooterComponent";
import AssetDetailsMenu from "../main/navigation/AssetDetailsMenu";
import AssetsNavigation from "../main/navigation/AssetsNavigation";
import { styled } from "@mui/material";

const Content = styled("main")(() => ({
  display: "grid",
  grid: "min-content 1fr / 1fr",
  height: "calc(100% - 3.125rem)",
  maxHeight: "calc(100% - 3.125rem)",
  width: "100%",
  overflow: "auto",
  boxSizing: "border-box",
}));

interface AssetDetailsLayoutProps {}

const AssetDetailsLayout: React.FC<AssetDetailsLayoutProps> = () => {
  return (
    <>
      <AssetDetailsAppBarComponent />
      <AssetsNavigation />
      <Content>
        <AssetDetailsMenu />
        <Outlet />
      </Content>
      <Footer />
    </>
  );
};

export default AssetDetailsLayout;
