import styled from "@emotion/styled";
import { CircularProgress, Typography } from "@mui/material";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import React, { ReactElement, useMemo } from "react";
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../store/hooks";
import {
  selectAssetsUtilStatesPending,
  selectUtilStatesByAssetId,
} from "../../../../store/main/kpis/kpisSlice";
import { NoDataUtilComponent } from "./NoDataUtilStateComponent";

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutContainer = styled("div")((props) => ({
  display: "flex",
}));

const ChartContainer = styled("div")((props) => ({
  height: "140px",
  width: "140px",
}));

const ChartLegend = styled("div")((props) => ({
  display: "flex",
  flex: "0 0 160px",
  flexDirection: "column",
  justifyContent: "center",
}));

const LegendProperty = styled("div")((props) => ({
  display: "flex",
  alignItems: "center",
}));

const Loading = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "140px",
}));

interface UtilStateChartInterface {
  assetId: string | undefined;
}

export interface GetUtilStateDataDTO {
  percent: number;
  stateDesc: string | null;
  hexColor: string | null;
}

const UtilStateChartComponent: React.FC<UtilStateChartInterface> = ({
  assetId,
}): ReactElement => {
  const { t } = useTranslation();
  const loading = useAppSelector(selectAssetsUtilStatesPending);
  const utilStates = useAppSelector((state) =>
    selectUtilStatesByAssetId(state, assetId!)
  );

  const data = useMemo(
    () => ({
      datasets: [
        {
          data: utilStates
            ? utilStates.map((utilState) => utilState.percent)
            : [],
          backgroundColor: utilStates
            ? utilStates.map((utilState) => utilState.utilStateColor || "#444")
            : [],
          borderWidth: 0,
          label: "state",
        },
      ],
    }),
    [utilStates]
  );

  const options = {
    width: 140,
    height: 140,
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    tooltips: {
      enabled: false,
    },
    legend: {
      display: false,
    },
    events: [],
  };
  return (
    <>
      {loading ? (
        <Loading>
          <CircularProgress />
        </Loading>
      ) : (
        <DoughnutContainer>
          {utilStates && utilStates.length > 0 ? (
            <>
              <ChartLegend>
                {utilStates.map((o, index) => (
                  <LegendProperty key={index}>
                    <Circle color={o.utilStateColor || "#444"} />
                    <Typography variant="caption">
                      {t(o.utilStateName ?? "")} {o.percent?.toFixed(2)}%
                    </Typography>
                    <br />
                  </LegendProperty>
                ))}
              </ChartLegend>
              <ChartContainer>
                <Doughnut data={data} options={options} />
              </ChartContainer>
            </>
          ) : (
            <NoDataUtilComponent />
          )}
        </DoughnutContainer>
      )}
    </>
  );
};

interface CircleInterface {
  color?: string;
}

const Circle: React.FC<CircleInterface> = ({ color }): ReactElement => {
  return (
    <>
      <svg height="16" width="16">
        <circle cx="8" cy="8" r="4" fill={color} />
      </svg>
    </>
  );
};

export default UtilStateChartComponent;
