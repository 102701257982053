import { EntityIcon } from "@improdis/core";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TreeItem, TreeView } from "@mui/x-tree-view";
import { Button, Typography, styled } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  NestedAsset,
  getNestedAssetNodeId,
  selectNestedAssets,
  selectNestedAssetsNodeIds,
} from "../../../store/definitions/assetsSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  SelectedAsset,
  assetsSelected,
  selectSelectedAssetsIdsWithSourceStack,
} from "../../../store/user/assets-selection/assetsSelectionSlice";

const Root = styled("div")(({ theme }) => ({
  paddingLeft: "1.75rem",
  paddingRight: "1.75rem",
  paddingTop: "0.5rem",
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
}));

const Header = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginLeft: "-0.25rem",
  gap: "1rem",
}));

const StyledAssetIcon = styled(EntityIcon)(({ theme }) => ({
  height: 20,
  width: 20,
}));

const SelectionContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
}));

const TreeLabel = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0.5, 0),
}));

interface TreeViewProps {}

const TreeViewComponent: React.FC<TreeViewProps> = () => {
  const nestedAssets = useAppSelector(selectNestedAssets);
  const nestedAssetsIds = useAppSelector(selectNestedAssetsNodeIds);
  const selectedNodes = useAppSelector(selectSelectedAssetsIdsWithSourceStack);
  const [expanded, setExpanded] = useState<string[]>(nestedAssetsIds);
  const [selected, setSelected] = useState<Array<string>>(selectedNodes);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const renderTree = (nodes: Array<NestedAsset>) =>
    nodes.map((node) => {
      const id = getNestedAssetNodeId(node.sourceStack, node.id);
      return (
        <TreeItem
          key={id}
          nodeId={id}
          label={
            <TreeLabel>
              <Typography variant="secondaryLabel">{node.name}</Typography>
            </TreeLabel>
          }
        >
          {Array.isArray(node.childrenAssets) &&
          node.childrenAssets.length > 0 &&
          node.childrenAssets.some(
            (ch) =>
              Array.isArray(ch.childrenAssets) && ch.childrenAssets.length > 0
          )
            ? node.childrenAssets.map((ch) => renderTree([ch]))
            : null}
        </TreeItem>
      );
    });

  const handleNodeToggle = (event: React.ChangeEvent<{}>, nodes: string[]) => {
    setExpanded(nodes);
  };

  const handleNodeSelect = async (
    event: React.ChangeEvent<{}>,
    nodeIds: Array<string>
  ) => {
    setSelected(nodeIds);
    const selectedAssets = nodeIds.map(
      (n) =>
        ({
          sourceStack: n.split("/").slice(0, -1),
          assetId: n.split("/")[n.split("/").length - 1],
        } as SelectedAsset)
    );
    dispatch(assetsSelected(selectedAssets));
    if (selectedAssets.length > 0) {
      if (location.pathname === "/") {
        navigate(
          `assets?assetIds=${selectedAssets.map((a) => a.assetId).join(",")}`
        );
      } else if (!location.pathname.includes("details")) {
        navigate(
          `${location.pathname}?assetIds=${selectedAssets
            .map((a) => a.assetId)
            .join(",")}`
        );
      } else {
        navigate(
          `/assets?assetIds=${selectedAssets.map((a) => a.assetId).join(",")}`
        );
      }
    }
  };

  const handleSelectAll = () => {
    const nodeIds = nestedAssets.map((n) =>
      getNestedAssetNodeId(n.sourceStack, n.id)
    );
    setSelected(nodeIds);
    const selectedAssets = nodeIds.map(
      (n) =>
        ({
          sourceStack: n.split("/").slice(0, -1),
          assetId: n.split("/")[n.split("/").length - 1],
        } as SelectedAsset)
    );
    dispatch(assetsSelected(selectedAssets));
    if (selectedAssets.length > 0) {
      if (location.pathname === "/") {
        //TODO is it needed?
        navigate(
          `assets?assetIds=${selectedAssets.map((a) => a.assetId).join(",")}`
        );
      } else {
        navigate(
          `${location.pathname}?assetIds=${selectedAssets
            .map((a) => a.assetId)
            .join(",")}`
        );
      }
    }
  };

  return (
    <Root>
      <Header>
        <StyledAssetIcon />
        <Typography variant="title">{t("Assets")}</Typography>
      </Header>
      <SelectionContainer>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          type="submit"
          onClick={handleSelectAll}
        >
          {t("SelectAll")}
        </Button>
      </SelectionContainer>
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
        selected={selected}
        multiSelect
        onNodeToggle={handleNodeToggle}
        onNodeSelect={handleNodeSelect}
      >
        {renderTree(nestedAssets)}
      </TreeView>
    </Root>
  );
};

export default TreeViewComponent;
