import { env } from "../../env";
import { handleExceptionMessage } from "../../helpers/handleExceptionMessage";
import { instance } from "../axiosInstance";
import {
  GetAssetIntervalAvailabilityByAssetIdsDto,
  GetAssetIntervalAvailabilityByAssetIdsRequest,
  GetAssetIntervalPerformanceByAssetIdsDto,
  GetAssetIntervalPerformanceByAssetIdsRequest,
  GetAssetIntervalQualityByAssetIdsDto,
  GetAssetIntervalQualityByAssetIdsRequest,
  GetAvailabilityByAssetIdDto,
  GetPerformanceByAssetIdDto,
  GetQualityByAssetIdDto,
  OeeClient,
} from "../httpService";

const http = new OeeClient(env.REACT_APP_MES_API_URL, instance);

export const getAvailabilityByAssetId = async (
  assetId: string,
  startDate: Date | undefined,
  endDate: Date | undefined,
  throwError: boolean = true
): Promise<GetAvailabilityByAssetIdDto | undefined> => {
  try {
    const result = await http.getAvailabilityByAssetId(
      assetId,
      startDate,
      endDate
    );
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const getAssetsAvailabilityByAssetIds = async (
  assetIds: Array<string>,
  startDate: Date | null,
  endDate: Date | null,
  throwError: boolean = true
): Promise<Array<GetAssetIntervalAvailabilityByAssetIdsDto> | undefined> => {
  try {
    const request = new GetAssetIntervalAvailabilityByAssetIdsRequest({
      assetIds: assetIds,
      startDate: startDate,
      endDate: endDate,
    });
    const result = await http.getAssetIntervalsAvailabilityByAssetIds(request);
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const getPerformanceByAssetId = async (
  assetId: string,
  startDate: Date | undefined,
  endDate: Date | undefined,
  throwError: boolean = true
): Promise<GetPerformanceByAssetIdDto | undefined> => {
  try {
    const result = await http.getPerformanceByAssetId(
      assetId,
      startDate,
      endDate
    );
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const getAssetsPerformanceByAssetIds = async (
  assetIds: Array<string>,
  startDate: Date | null,
  endDate: Date | null,
  throwError: boolean = true
): Promise<Array<GetAssetIntervalPerformanceByAssetIdsDto> | undefined> => {
  try {
    const request = new GetAssetIntervalPerformanceByAssetIdsRequest({
      assetIds: assetIds,
      startDate: startDate,
      endDate: endDate,
    });
    const result = await http.getAssetsPerformanceByAssetIds(request);
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const getQualityByAssetId = async (
  assetId: string,
  startDate: Date | undefined,
  endDate: Date | undefined,
  throwError: boolean = true
): Promise<GetQualityByAssetIdDto | undefined> => {
  try {
    const result = await http.getQualityByAssetId(assetId, startDate, endDate);
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const getAssetsQualityByAssetIds = async (
  assetIds: Array<string>,
  startDate: Date | null,
  endDate: Date | null,
  throwError: boolean = true
): Promise<Array<GetAssetIntervalQualityByAssetIdsDto> | undefined> => {
  try {
    const request = new GetAssetIntervalQualityByAssetIdsRequest({
      assetIds: assetIds,
      startDate: startDate,
      endDate: endDate,
    });
    const result = await http.getAssetsQualityByAssetIds(request);
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};
