import { styled } from "@mui/material";
import { useEffect } from "react";
import { env } from "../../../env";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  loadAssetUtilStatesAsync,
  loadSelectedAssetsShiftKpisAsync,
} from "../../../store/main/kpis/kpisSlice";
import {
  kpiViewSelected,
  selectAssetViewCarousel,
  selectKpiView,
  selectTableView,
} from "../../../store/setup/setupSlice";
import { selectAvailableFlatAssets } from "../../../store/user/assets-selection/assetsSelectionSlice";
import { TilesContainer } from "../../common/tiles/TilesContainer";
import AssetTile from "./AssetTile";

const TableContainer = styled("div")(() => ({
  padding: "1.5rem",
  height: "100%",
  boxSizing: "border-box",
}));

interface AssetsPageProps {}

const AssetsPage: React.FC<AssetsPageProps> = () => {
  const availableAssets = useAppSelector(selectAvailableFlatAssets);
  const availableAssetsIds = availableAssets.map((a) => a.id);
  const tableView = useAppSelector(selectTableView);
  const dispatch = useAppDispatch();
  const assetViewCarousel = useAppSelector(selectAssetViewCarousel);
  const kpiView = useAppSelector(selectKpiView);

  useEffect(() => {
    const interval = setInterval(() => {
      if (availableAssetsIds.length > 0 && kpiView) {
        dispatch(loadSelectedAssetsShiftKpisAsync() as any);
      }
    }, env.REACT_APP_FETCH_OEE_INTERVAL_TIME);
    return () => clearInterval(interval);
  }, [dispatch, availableAssetsIds, kpiView]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (availableAssetsIds.length > 0 && kpiView) {
        // dispatch(loadSelectedAssetsCurrentKpisAsync() as any);
        availableAssetsIds.map((id) =>
          dispatch(loadAssetUtilStatesAsync({ assetId: id }) as any)
        );
      }
    }, env.REACT_APP_FETCH_UTIL_EVENTS_INTERVAL_TIME);
    return () => clearInterval(interval);
  }, [dispatch, availableAssetsIds, kpiView]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (assetViewCarousel) {
        dispatch(kpiViewSelected(!kpiView));
      }
    }, env.REACT_APP_ASSET_CAROUSEL_INTERVAL_TIME);
    return () => clearInterval(timer);
    // eslint-disable-next-line
  }, [assetViewCarousel, kpiView]);

  return (
    <>
      {tableView ? (
        <TableContainer>
          {/* <AssetsTable data={tableData} /> */}
        </TableContainer>
      ) : (
        <TilesContainer>
          {availableAssets.map((o) => (
            <AssetTile
              key={o.id}
              title={o.description || o.name || "Unknown"}
              assetId={o.id}
            />
          ))}
        </TilesContainer>
      )}
    </>
  );
};

export default AssetsPage;
