import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  JsonHubProtocol,
} from "@microsoft/signalr";
import { AnyAction, Dispatch } from "redux";
import { env } from "../../../../env";
import convertStringDates from "../../dateParser";
import {
  taskLogOffRegisteredSocketEvent,
  taskLogOnRegisteredSocketEvent,
  taskLogonConnectionStatusChanged,
} from "../../../../store/sockets/access-management/taskLogonsSlice";

export interface TaskLogonRegisteredSocketDto {
  logId: string;
  taskId: string;
  userId: string;
  connectionId: string;
  startTime: Date;
}

export interface TaskLogoffRegisteredSocketDto {
  logId: string;
  taskId: string;
  userId: string;
  connectionId: string;
  finishedAt: Date;
}

export interface ITaskLogonAllService {
  init(): void;
  connect(dispatch: Dispatch<AnyAction>, getState: any): void;
  disconnect(): void;
  subscribe(dispatch: Dispatch<AnyAction>, getState: any): void;
  unsubscribe(): void;
  leaveAllGroup(): void;
  joinAllGroup(dispatch: Dispatch<AnyAction>): void;
}

const taskLogonHubUrl = `${env.REACT_APP_MES_API_URL}/access-management/task-logons`;

const protocol = new JsonHubProtocol();

const transport = HttpTransportType.WebSockets | HttpTransportType.LongPolling;

const options = {
  transport,
};

export class TaskLogonAllService implements ITaskLogonAllService {
  public connectionTaskLogonHub: HubConnection | undefined;

  constructor() {
    this.init();
  }

  init() {
    this.connectionTaskLogonHub = new HubConnectionBuilder()
      .withUrl(taskLogonHubUrl, options)
      .withHubProtocol(protocol)
      .withAutomaticReconnect()
      .build();
  }

  async start(dispatch: Dispatch<AnyAction>) {
    try {
      await this.connectionTaskLogonHub?.start().then(async (a) => {
        dispatch(
          taskLogonConnectionStatusChanged(HubConnectionState.Connected)
        );
      });
    } catch (error) {
      console.error(error);
    }
  }

  async connect(dispatch: Dispatch<AnyAction>, getState: any) {
    if (
      this.connectionTaskLogonHub?.state !== HubConnectionState.Disconnected
    ) {
      this.unsubscribe();
      this.init();
      this.subscribe(dispatch, getState());
    }
    await this.start(dispatch);

    this.connectionTaskLogonHub?.onreconnecting((error) => {
      console.error(error);
      dispatch(
        taskLogonConnectionStatusChanged(HubConnectionState.Reconnecting)
      );
    });

    this.connectionTaskLogonHub?.onreconnected((connectionId) => {
      dispatch(taskLogonConnectionStatusChanged(HubConnectionState.Connected));
    });

    this.connectionTaskLogonHub?.onclose((error) => {
      console.error(error);
      dispatch(
        taskLogonConnectionStatusChanged(HubConnectionState.Disconnected)
      );
    });
  }

  disconnect() {
    this.connectionTaskLogonHub?.stop();
  }

  subscribe(dispatch: Dispatch<AnyAction>, getState: any) {
    this.connectionTaskLogonHub?.on("task-logon-registered", (message) => {
      if (message != null) {
        convertStringDates(message, ["startTime"]);
        dispatch(taskLogOnRegisteredSocketEvent(message));
      }
    });

    this.connectionTaskLogonHub?.on("task-logoff-registered", (message) => {
      if (message != null) {
        convertStringDates(message, ["finishedAt"]);
        dispatch(taskLogOffRegisteredSocketEvent(message));
      }
    });
  }

  async joinAllGroup(): Promise<void> {
    if (this.connectionTaskLogonHub?.state === HubConnectionState.Connected) {
      this.connectionTaskLogonHub?.invoke("JoinAllGroup");
    }
  }

  leaveAllGroup = () => {
    if (this.connectionTaskLogonHub?.state === HubConnectionState.Connected) {
      this.connectionTaskLogonHub?.invoke("LeaveAllGroup");
    }
  };

  unsubscribe = () => {
    this.connectionTaskLogonHub?.off("task-logon-registered");
    this.connectionTaskLogonHub?.off("task-logoff-registered");
  };
}
