import { LinearProgress, Typography, styled } from "@mui/material";
import { ChartData, ChartOptions } from "chart.js";
import { Chart } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { ArrowComponent } from "./ArrowComponent";
import { Point } from "./KPIsHelpersFunctions";
import { NoDataKPIComponent } from "./NoDataKPIComponent";

const SingleIndicator = styled("div")(() => ({
  display: "grid",
  gridTemplateColumns: "25% 5% 15% 50%",
  alignItems: "center",
  height: "35px",
  columnGap: "1%",
}));

const IndicatorValue = styled(Typography)(() => ({
  display: "flex",
  justifyContent: "flex-end",
  marginRight: "5px",
}));

const ChartContainer = styled("span")(() => ({
  height: "35px",
  position: "relative",
  top: "0px",
  overflow: "hidden",
}));

const options = {
  responsive: true,
  spanGaps: false,
  maintainAspectRatio: false,
  fill: true,
  tension: "0.4",
  animation: {
    duration: 1,
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  scales: {
    y: {
      display: false,
      suggestedMin: 0,
      suggestedMax: 100,
      ticks: {
        stepSize: 2,
      },
    },
    x: {
      type: "time",
      display: false,
    },
  },
} as ChartOptions<"line">;

interface SingleIndicatorComponentProps {
  chartData: ChartData<"line", Array<Point>>;
  indicatorName: string;
  currentValue: number | null;
  loadingData: boolean;
}

export const SingleIndicatorComponent: React.FC<
  SingleIndicatorComponentProps
> = ({ chartData, indicatorName, currentValue, loadingData }) => {
  const { t } = useTranslation();
  return (
    <SingleIndicator>
      {indicatorName === "OEE" ? (
        <Typography style={{ fontSize: "20px" }}>{indicatorName}</Typography>
      ) : (
        <>
          <Typography variant="indicatorName">{t(indicatorName)}</Typography>
        </>
      )}
      <ArrowComponent values={chartData} />
      {loadingData ? (
        <>
          <div></div>
          <LinearProgress style={{ height: "5px" }} />
        </>
      ) : (
        <>
          <IndicatorValue variant="indicatorValue">
            {currentValue === null
              ? "--"
              : currentValue !== null && Math.round(currentValue) + "%"}
          </IndicatorValue>
          {chartData.datasets[0].data.length > 1 ? (
            <>
              <ChartContainer>
                <Chart type="line" data={chartData} options={options} />
              </ChartContainer>
            </>
          ) : (
            // <>
            <NoDataKPIComponent />
            // </>
          )}
        </>
      )}
    </SingleIndicator>
  );
};
