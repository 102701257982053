import { changeLanguage } from "@improdis/core";
import { Middleware } from "redux";
import { REHYDRATE } from "redux-persist";
import { PersistConfigKey } from "..";
import i18n from "../../i18n";
import { AssetLogonAllService } from "../../services/sockets/hub-services/access-management/assetLogonAllService";
import { TaskLogonAllService } from "../../services/sockets/hub-services/access-management/taskLogonAllService";
import { TasksAllService as ManufacturingExecutionTasksAllService } from "../../services/sockets/hub-services/manufacturing-execution/tasksAllService";
import { TasksAllService as ProductionPlanningTasksAllService } from "../../services/sockets/hub-services/production-planning/tasksAllService";
import { AssetsAllService } from "../../services/sockets/hub-services/utilization/assetsAllService";
import { loadNestedAssetsAsync } from "../definitions/assetsSlice";
import { loadUsersAsync } from "../definitions/usersSlice";
import { loadUtilStatesAsync } from "../definitions/utilStatesSlice";
import { loadAssetsLogonsAsync } from "../main/asset-logons/assetsLogonsSlice";
import { loadTaskExecsAsync } from "../main/task-execs/taskExecsSlice";
import { loadTaskLogonsAsync } from "../main/task-logons/taskLogonsSlice";
import { loadUtilizationExecsAsync } from "../main/util-execs/utilExecsSlice";
import { loadProductionReasonsAsync } from "../definitions/productionReasonsSlice";
import { AssetTopic, topicsDefinitionsLoaded } from "../common/mqttSlice";
import { Validator } from "jsonschema";
import topicsSchema from "../../helpers/schemas/topicsSchema.json";
import { env } from "../../env";
import { loadNestedAttributeGroupsAsync } from "../definitions/attributeGroupsSlice";
import { loadCustomTabsAsync } from "../common/customTabsSlice";
import { appStarted } from "../setup/setupSlice";
import { UtilizationEventsAllService } from "../../services/sockets/hub-services/utilization/utilizationEventsAllService";

const assetsService = new AssetsAllService();
const manufacturingExecutionTasksAllService =
  new ManufacturingExecutionTasksAllService();
const productionPlanningTasksAllService =
  new ProductionPlanningTasksAllService();
const assetLogonAllService = new AssetLogonAllService();
const taskLogonAllService = new TaskLogonAllService();
const utilizationEventsAllService = new UtilizationEventsAllService();

const fetchMqttTopics = (dispatch) => {
  fetch(`/topics.json`)
    .then((r) => r.json())
    .then((data: Array<AssetTopic>) => {
      var v = new Validator();
      var result = v.validate(data, topicsSchema as any, {
        nestedErrors: true,
      });
      let errors;
      let assetTopics = data;

      result.errors.length > 0 &&
        result.errors.forEach((err: any) => {
          if (err.path.length >= 3) {
            data[err.path[0]].topics[err.path[2]].errors = [
              ...(data[err.path[0]].topics[err.path[2]].errors || []),
              err.stack,
            ];
          } else if (err.path.length >= 1) {
            data[err.path[0]].errors = [
              ...(data[err.path[0]].errors || []),
              err.stack,
            ];
          } else {
            errors = [...(errors || []), err.stack] as Array<string>;
          }
        });

      dispatch(
        topicsDefinitionsLoaded({
          definitions: assetTopics,
          errors: errors,
        })
      );
    })
    .catch((err) => {
      dispatch(
        topicsDefinitionsLoaded({
          definitions: [],
          errors: [err.message],
        })
      );
    });
};

const initAppState = async (dispatch: any, getState: any) => {
  await assetsService.connect(dispatch, getState);
  assetsService.subscribe(dispatch, getState);

  await manufacturingExecutionTasksAllService.connect(dispatch, getState);
  manufacturingExecutionTasksAllService.joinAllGroup();
  manufacturingExecutionTasksAllService.subscribe(dispatch, getState);

  await productionPlanningTasksAllService.connect(dispatch, getState);
  productionPlanningTasksAllService.joinAllGroup();
  productionPlanningTasksAllService.subscribe(dispatch, getState);

  await assetLogonAllService.connect(dispatch, getState);
  assetLogonAllService.joinAllGroup();
  assetLogonAllService.subscribe(dispatch, getState);

  await taskLogonAllService.connect(dispatch, getState);
  taskLogonAllService.joinAllGroup();
  taskLogonAllService.subscribe(dispatch, getState);

  await utilizationEventsAllService.connect(dispatch, getState);
  utilizationEventsAllService.joinAllGroup();
  utilizationEventsAllService.subscribe(dispatch, getState);

  env.REACT_APP_MQTT_URL && fetchMqttTopics(dispatch);
  await dispatch(loadNestedAssetsAsync());
  dispatch(loadUsersAsync());
  dispatch(loadUtilStatesAsync());
  dispatch(loadProductionReasonsAsync());
  await dispatch(loadNestedAttributeGroupsAsync());
  await dispatch(loadCustomTabsAsync());
  dispatch(loadUtilizationExecsAsync());
  dispatch(loadTaskExecsAsync());
  dispatch(loadAssetsLogonsAsync());
  dispatch(loadTaskLogonsAsync());
  changeLanguage(i18n.language);
  dispatch(appStarted(true));
};

const signalRMiddleware: Middleware =
  ({ getState, dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === REHYDRATE && action.key === PersistConfigKey.Setup) {
      initAppState(dispatch, getState);
    }
    return next(action);
  };

export default signalRMiddleware;
