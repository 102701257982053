import { styled } from "@mui/material";
import { useAppSelector } from "../../../store/hooks";
import { selectAssetsLogons } from "../../../store/main/asset-logons/assetsLogonsSlice";
import { selectTaskLogons } from "../../../store/main/task-logons/taskLogonsSlice";
import { selectTableView } from "../../../store/setup/setupSlice";
import { selectAvailableFlatAssets } from "../../../store/user/assets-selection/assetsSelectionSlice";
import { TilesContainer } from "../../common/tiles/TilesContainer";
import OperatorTile from "./OperatorTile";

const TableContainer = styled("div")(() => ({
  padding: "1.5rem",
  height: "100%",
  boxSizing: "border-box",
}));

interface OperatorsPageProps {}

const OperatorsPage: React.FC<OperatorsPageProps> = () => {
  const tableView = useAppSelector(selectTableView);
  const availableAssets = useAppSelector(selectAvailableFlatAssets);
  const assetsLogons = useAppSelector(selectAssetsLogons);
  const taskLogons = useAppSelector(selectTaskLogons);

  return (
    <>
      {tableView ? (
        <TableContainer>
          {/* <AssetsTable data={tableData} /> */}
        </TableContainer>
      ) : (
        <TilesContainer>
          {assetsLogons.map(
            (l) =>
              availableAssets.find((a) => a.id === l.assetId) && (
                <OperatorTile
                  key={`${l.connectionId}|${l.assetId}`}
                  assetLogon={l}
                  asset={availableAssets.find((a) => a.id === l.assetId)}
                  taskLogon={taskLogons.find(
                    (tl) =>
                      tl.userId === l.userId &&
                      tl.finishedAt === null &&
                      tl.connectionId === l.connectionId
                  )}
                />
              )
          )}
        </TilesContainer>
      )}
    </>
  );
};

export default OperatorsPage;
