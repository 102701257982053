import * as React from "react";

function SvgComponent(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18.006}
      viewBox="0 0 18 18.006"
      {...props}
    >
      <path
        d="M9.749 0h7.552l.1.02.1.02.06.03a.582.582 0 01.219.15l.04.04.061.09.037.06.039.09.019.06.014.07.009.09v7.54a.75.75 0 01-1.493.1l-.007-.1v-5.7L1.279 17.78a.743.743 0 01-.976.08l-.084-.08a.74.74 0 01-.073-.97l.073-.09L15.438 1.5h-5.69a.747.747 0 01-.743-.65l-.007-.1a.747.747 0 01.648-.74z"
        fill={props.color}
      />
    </svg>
  );
}

export default SvgComponent;
