// import "chart.js/auto";
// import "chartjs-adapter-moment";
import {
  OEEChartComponent,
  ProductionSummaryChartComponent,
  UtilDowntimesChartComponent,
  UtilSummaryChartComponent,
  UtilTimelineChartComponent,
} from "@improdis/core";
import { Box, CircularProgress, Paper, styled } from "@mui/material";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import SimpleBar from "simplebar-react";
import ProductionReason from "../../../../helpers/enums/productionReasonEnum";
import {
  GetAvailabilityByAssetIdDto,
  GetPerformanceByAssetIdDto,
  GetQualityByAssetIdDto,
} from "../../../../services/httpService";
import {
  getAvailabilityByAssetId,
  getPerformanceByAssetId,
  getQualityByAssetId,
} from "../../../../services/main/oeeService";
import { AppThunkDispatch } from "../../../../store";
import { selectProductionReasons } from "../../../../store/definitions/productionReasonsSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  loadProductionEventsForCurrentShiftAsync,
  selectProductionEventsSummary,
} from "../../../../store/main/production-events/prodEventsSlice";
import {
  loadUtilEventsForCurrentShiftAsync,
  selectUtilEventsDowntimes,
  selectUtilEventsOverTime,
  selectUtilEventsStateSummary,
} from "../../../../store/main/utilization-events/utilEventsSlice";
import { selectAllFlatAssets } from "../../../../store/user/assets-selection/assetsSelectionSlice";

const Root = styled(SimpleBar)(({ theme }) => ({
  height: "100%",
  width: "100%",
  overflow: "auto",
  "& .simplebar-content": {
    display: "flex",
    height: "100%",
    width: "100%",
  },
}));

const Grid = styled("div")(({ theme }) => ({
  display: "grid",
  boxSizing: "border-box",
  margin: "0.4rem",
  padding: "0.2rem",
  height: "calc(100% - 0.8rem)",
  width: "100%",
  overflow: "auto",
  gridGap: "0.5rem",
  [theme.breakpoints.up("xl")]: {
    gridTemplateColumns: "repeat(3, 1fr)",
    gridTemplateRows: "1fr 1fr",
    gridTemplateAreas: `'oee utilSummary prodSummary'
          'downtimes utilTimeline utilTimeline'`,
  },
  [theme.breakpoints.down("xl")]: {
    gridTemplateColumns: "repeat(2, 1fr)",
    gridTemplateRows: "1fr 1fr 1fr",
    gridTemplateAreas: `'oee utilSummary'
          'prodSummary downtimes'
          'utilTimeline utilTimeline'`,
  },
  [theme.breakpoints.down("md")]: {
    grid: "repeat(5, minmax(350px, auto)) / 1fr",
    gridTemplateAreas: `'oee'
        'utilSummary'
        'prodSummary'
        'downtimes'
        'utilTimeline'`,
  },
}));

const ChartPaper = styled(Paper)((props) => ({
  height: "100%",
  width: "100%",
}));

interface OEEIndicator {
  oee: number | null;
  oeeTarget: number;
}

interface AssetDetailsComponentProps {}

const AssetDetailsComponent: React.FC<AssetDetailsComponentProps> = () => {
  const { assetId } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch() as AppThunkDispatch;
  const productionReasons = useAppSelector(selectProductionReasons);
  const utilizationSummary = useAppSelector(selectUtilEventsStateSummary);
  const productionSummary = useAppSelector(selectProductionEventsSummary);
  const utilizationDowntimes = useAppSelector(selectUtilEventsDowntimes);
  const utilizationOverTime = useAppSelector(selectUtilEventsOverTime);
  const allFlatAssets = useAppSelector(selectAllFlatAssets);
  const [performanceIndicator, setPerformanceIndicator] =
    useState<GetPerformanceByAssetIdDto>();
  const [qualityIndicator, setQualityIndicator] =
    useState<GetQualityByAssetIdDto>();
  const [availabilityIndicator, setAvailabilityIndicator] =
    useState<GetAvailabilityByAssetIdDto>();
  const [oeeIndicator, setOEEIndicator] = useState<OEEIndicator>();

  const checkAssetId = () => {
    return allFlatAssets.find((a) => a.id === assetId) !== undefined
      ? true
      : false;
  };
  const validAssetId = checkAssetId();

  useEffect(() => {
    const fetchKPIsData = async () => {
      if (validAssetId && assetId !== undefined && assetId !== null) {
        const performanceResult = await getPerformanceByAssetId(
          assetId,
          undefined,
          undefined
        );
        isMount &&
          performanceResult !== undefined &&
          setPerformanceIndicator(performanceResult);
        const qualityResult = await getQualityByAssetId(
          assetId,
          undefined,
          undefined
        );
        isMount &&
          qualityResult !== undefined &&
          setQualityIndicator(qualityResult);
        const availabilityResult = await getAvailabilityByAssetId(
          assetId,
          undefined,
          undefined
        );
        isMount &&
          availabilityResult !== undefined &&
          setAvailabilityIndicator(availabilityResult);
        if (
          availabilityResult !== undefined &&
          qualityResult !== undefined &&
          performanceResult !== undefined
        ) {
          let oee: number | null = null;
          if (
            availabilityResult.availability &&
            qualityResult.quality &&
            performanceResult.performance
          ) {
            oee =
              (availabilityResult.availability *
                performanceResult.performance *
                qualityResult.quality) /
              10000;
          }
          setOEEIndicator({
            oee: oee,
            oeeTarget:
              (availabilityResult.targetAvailability +
                performanceResult.targetPerformance +
                qualityResult.targetQuality) /
              3,
          });
        }
      }
    };
    const fetchActualUtilStateoverTime = async () => {
      if (validAssetId && assetId !== undefined && assetId !== null) {
        dispatch(loadUtilEventsForCurrentShiftAsync({ assetId: assetId }));
      }
    };
    const fetchActualProduction = async () => {
      if (validAssetId && assetId !== undefined && assetId !== null) {
        dispatch(
          loadProductionEventsForCurrentShiftAsync({ assetId: assetId })
        );
      }
    };
    let isMount = true;
    if (isMount) {
      fetchKPIsData();
      fetchActualUtilStateoverTime();
      fetchActualProduction();
    }
    const interval = setInterval(() => {
      if (isMount) {
        fetchKPIsData();
        fetchActualUtilStateoverTime();
        fetchActualProduction();
      }
    }, 60000);
    return () => {
      isMount = false;
      clearInterval(interval);
    };
  }, [assetId, validAssetId, dispatch]);

  const oeeChart = useMemo(
    () => (
      <OEEChartComponent
        oee={{
          value: oeeIndicator?.oee,
          target: oeeIndicator?.oeeTarget,
        }}
        availability={{
          value: availabilityIndicator?.availability,
          target: availabilityIndicator?.targetAvailability,
        }}
        performance={{
          value: performanceIndicator?.performance,
          target: performanceIndicator?.targetPerformance,
        }}
        quality={{
          value: qualityIndicator?.quality,
          target: qualityIndicator?.targetQuality,
        }}
      />
    ),
    [
      availabilityIndicator,
      performanceIndicator,
      qualityIndicator,
      oeeIndicator,
    ]
  );
  const utilSummaryChart = useMemo(() => {
    var sumDuration = _.sumBy(utilizationSummary, "duration");
    return (
      <>
        {utilizationSummary ? (
          <UtilSummaryChartComponent
            data={utilizationSummary.map((s) => ({
              label: `${t(s.utilStateName || "Unknown")} ${(
                (s.duration / sumDuration) *
                100
              ).toFixed(2)}%`,
              color: s.utilStateColor!,
              duration: s.duration,
            }))}
          />
        ) : (
          <>
            {assetId && (
              <Box
                display="flex"
                height="100%"
                alignItems="center"
                justifyContent="center"
              >
                <CircularProgress size="4rem" />
              </Box>
            )}
          </>
        )}
      </>
    );
  }, [t, assetId, utilizationSummary]);

  const productionSummaryChart = useMemo(
    () => (
      <>
        {productionSummary ? (
          <ProductionSummaryChartComponent
            data={productionSummary.map((p) => ({
              label: p.itemName || t("Unknown"),
              isGood:
                p.productionReasonId ===
                productionReasons.find(
                  (pr) => pr.name === ProductionReason.Good
                )?.id,
              quantity: p.quantityProduced,
            }))}
          />
        ) : (
          <>
            {assetId && (
              <Box
                display="flex"
                height="100%"
                alignItems="center"
                justifyContent="center"
              >
                <CircularProgress size="4rem" />
              </Box>
            )}
          </>
        )}
      </>
    ),
    [t, productionSummary, assetId, productionReasons]
  );

  const utilDowntimesChart = useMemo(
    () => (
      <UtilDowntimesChartComponent
        data={utilizationDowntimes.map((d) => {
          return {
            label: t(d.utilRawName || "Unknown"),
            color: d.utilStateColor ?? "transparent",
            duration: d.duration / 60,
            count: d.count,
          };
        })}
      />
    ),
    [t, utilizationDowntimes]
  );

  const utilTimelineChart = useMemo(
    () => (
      <UtilTimelineChartComponent
        data={utilizationOverTime.map((s) => ({
          startDate: s.startedAt,
          endDate: s.finishedAt ?? new Date(),
          label: `${t(s.utilStateName ?? "Unknown")} - ${t(
            s.utilRawName ?? "Unknown"
          )}`,
          color: s.utilStateColor ?? "transparent",
          duration: s.duration,
        }))}
      />
    ),
    [t, utilizationOverTime]
  );

  return (
    <Root>
      <Grid>
        <ChartPaper sx={{ gridArea: "oee" }} elevation={2} square>
          {oeeChart}
        </ChartPaper>
        <ChartPaper sx={{ gridArea: "utilSummary" }} elevation={2} square>
          {utilSummaryChart}
        </ChartPaper>{" "}
        <ChartPaper sx={{ gridArea: "prodSummary" }} elevation={2} square>
          {productionSummaryChart}
        </ChartPaper>
        <ChartPaper sx={{ gridArea: "downtimes" }} elevation={2} square>
          {utilDowntimesChart}
        </ChartPaper>
        <ChartPaper sx={{ gridArea: "utilTimeline" }} elevation={2} square>
          {utilTimelineChart}
        </ChartPaper>
      </Grid>
    </Root>
  );
};

export default AssetDetailsComponent;
