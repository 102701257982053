import {
  PayloadAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "..";
import { GetNestedAssetsQueryDto } from "../../services/httpService";
import { getAllNestedAssets } from "../../services/main/assetsService";

export const getNestedAssetNodeId = (
  sourceStack: Array<string> | null,
  id: string | null
) =>
  (sourceStack && sourceStack.length > 0 ? sourceStack?.join("/") + "/" : "") +
  id;

export interface NestedAsset extends GetNestedAssetsQueryDto {}

export const initialState: Array<NestedAsset> = [];

export const loadNestedAssetsAsync = createAsyncThunk(
  "definitions/loadNestedAssetsAsync",
  async (_, { getState, dispatch }) => {
    const result = await getAllNestedAssets();
    return result;
  }
);

export const assetsSlice = createSlice({
  name: "definitions",
  initialState,
  reducers: {
    nestedAssetsLoaded: (state, action: PayloadAction<Array<NestedAsset>>) => {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadNestedAssetsAsync.fulfilled, (state, action) => {
        return action.payload;
      })
      .addCase(loadNestedAssetsAsync.rejected, (state, action) => {
        return [];
      });
  },
});

export const { nestedAssetsLoaded } = assetsSlice.actions;

export const selectNestedAssets = createSelector(
  (state: RootState) => state.definitions.assets,
  (nestedAssets) => nestedAssets
);

export const selectNestedAssetsNodeIds = createSelector(
  selectNestedAssets,
  (nestedAssets) => {
    let ids: Array<string> = [];
    const lookup = (node: NestedAsset) => {
      if (node.childrenAssets && node.childrenAssets.length > 0) {
        ids = [...ids, getNestedAssetNodeId(node.sourceStack, node.id)];
        node.childrenAssets.forEach((node) => lookup(node));
      }
    };
    nestedAssets.forEach((node) => lookup(node));
    return ids;
  }
);

export const selectNestedAssetsNodeIdsWithParents = createSelector(
  selectNestedAssets,
  (nestedAssets) => {
    let ids: Array<string> = [];
    const lookup = (node: NestedAsset) => {
      if (
        node.childrenAssets &&
        (node.childrenAssets.length > 0 || node.parentAssetId === null)
      ) {
        ids = [...ids, getNestedAssetNodeId(node.sourceStack, node.id)];
        node.childrenAssets.forEach((node) => lookup(node));
      }
    };
    nestedAssets.forEach((node) => lookup(node));
    return ids;
  }
);

export default assetsSlice.reducer;
