import {
  Box,
  LinearProgress,
  LinearProgressProps,
  styled,
  Typography,
} from "@mui/material";
import { lighten } from "@mui/material/styles";
import React, { useMemo } from "react";
import {
  MqttTopicValue,
  ProgressOptions,
} from "../../../store/common/mqttSlice";

interface StyledLinearProgressProps extends LinearProgressProps {
  customBarColor: React.CSSProperties["color"];
}

const StyledLinearProgress = styled(LinearProgress, {
  shouldForwardProp: (propName) => propName !== "customBarColor",
})<StyledLinearProgressProps>(({ theme, customBarColor }) => ({
  "&.MuiLinearProgress-root": {
    width: 80,
    height: 10,
    backgroundColor: customBarColor ? lighten(customBarColor, 0.7) : "#FFF",
  },
  "& .MuiLinearProgress-bar": {
    backgroundColor: customBarColor,
  },
}));

interface ProgresBarProps {
  value: number;
  options: ProgressOptions | undefined;
  assetParameters: Array<MqttTopicValue> | undefined;
}

const ProgressBarComponent: React.FC<ProgresBarProps> = ({
  value,
  options,
  assetParameters,
}) => {
  const prepareMaxValueMqtt = () => {
    return isNaN(Number(options?.max))
      ? Number(
          assetParameters?.find((e) => e.name === options?.max)?.value || 0
        )
      : Number(options?.max);
  };

  const max = prepareMaxValueMqtt();

  const prepareBarColor = (value: number, max: number) => {
    if (options !== undefined) {
      const availableLevels = options.levels
        .filter((o) => o.value * max < value)
        .map((o) => o.value);
      const maxLevel = Math.max(...availableLevels);
      const maxColor = options.levels.find((o) => o.value === maxLevel)?.color;
      return maxColor;
    }
  };

  const progressDesc = useMemo(
    () =>
      `${
        !isNaN(Math.round((value / max) * 100)) &&
        isFinite(Math.round((value / max) * 100))
          ? Math.round((value / max) * 100) > 100
            ? 100
            : Math.round((value / max) * 100)
          : 0
      }%`,
    [value, max]
  );

  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <StyledLinearProgress
          variant="determinate"
          value={
            Math.floor((value / max) * 100) > 100
              ? 100
              : Math.floor((value / max) * 100)
          }
          color="primary"
          customBarColor={prepareBarColor(value, max)}
        />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body1" color="textSecondary">
          {progressDesc}
        </Typography>
      </Box>
    </Box>
  );
};

export default ProgressBarComponent;
