import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../..";

export const initialState: boolean = false;

export const assetNavigationDialogReducer = createSlice({
  name: "assetNavigationDialog",
  initialState,
  reducers: {
    opened: (state, action: PayloadAction<void>) => {
      return true;
    },
    closed: (state, action: PayloadAction<void>) => {
      return false;
    },
  },
});

export const {
  opened: assetsNavigationDialogOpened,
  closed: assetsNavigationDialogClosed,
} = assetNavigationDialogReducer.actions;

export const selectAssetNavigationDialogOpen = createSelector(
  (state: RootState) => state.dialogs.openAssetNavigationDialog,
  (openAssetNavigationDialog) => openAssetNavigationDialog
);

export default assetNavigationDialogReducer.reducer;
