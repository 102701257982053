import { styled } from "@mui/material";

export const InfoContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  maxHeight: "100px",
  marginBottom: "6px",
  overflowY: "auto",
  "&> div": {
    display: "flex",
    alignItems: "center",
    margin: "2px 0px",
    gap: "0.3rem",
  },
  boxSizing: "border-box",
  padding: "0.5rem",
  minHeight: "60px",
}));
