import { Middleware } from "redux";
import { logInAsync, logOutAsync } from "../user/authSlice";
import { instance } from "../../services/axiosInstance";

const createAxiosAuthMiddleware: Middleware =
  ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    if (action.type === logInAsync.fulfilled.type) {
      const token = action.payload.token;
      instance.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else if (action.type === logOutAsync.fulfilled.type) {
      instance.defaults.headers.common.Authorization = null;
    }
    return next(action);
  };

export default createAxiosAuthMiddleware;
