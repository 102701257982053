import { combineReducers } from "@reduxjs/toolkit";
import assetNavigationDialogReducer from "./assetNavigationSlice";
import editableTabDialogReducer from "./editableTabDialog/editableTabSlice";
import deleteTabDialogReducer from "./deleteTabSlice";

export default combineReducers({
  openAssetNavigationDialog: assetNavigationDialogReducer,
  editableTabDialog: editableTabDialogReducer,
  deleteTabDialog: deleteTabDialogReducer,
});
