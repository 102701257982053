import { Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import React from "react";
import { env } from "../../../../env";
import AppBarLoginLanguageMenu from "./LoginAppBarLanguageMenu";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  height: "3.125rem",
  maxHeight: "3.5rem",
  padding: "0.1rem 2rem",
}));

const AppBarContent = styled("div")(({ theme }) => ({
  display: "flex",
  height: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  flexGrow: 1,
}));

const AppBarSection = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "1rem",
}));

const Title = styled("div")(({ theme }) => ({
  textAlign: "left",
}));

interface Props {}

const LoginAppBar: React.FC<Props> = () => {
  return (
    <StyledAppBar position="relative" elevation={2}>
      <AppBarContent>
        <AppBarSection>
          <Title>
            <Typography variant="primaryLabel">
              {process.env.REACT_APP_WEBSITE_NAME}
            </Typography>
            <div>
              <Typography variant="secondaryLabel">
                {env.REACT_APP_THEME_TYPE === "AVEVA" ? "AVEVA" : "ImProdis"}
              </Typography>
            </div>
          </Title>
        </AppBarSection>
        <AppBarSection>
          <AppBarLoginLanguageMenu />
        </AppBarSection>
      </AppBarContent>
    </StyledAppBar>
  );
};

export default LoginAppBar;
