import { styled, Tab, TabProps } from "@mui/material";

const ButtonTabComponent = styled(Tab)<TabProps>(({ theme }) => ({
  "&.MuiTab-root": {
    minHeight: "unset",
    padding: "0 1rem",
    marginLeft: "2rem",
    borderRadius: "0.5rem",
    color: theme.palette.text.secondary,
    marginBottom: "0.2rem",
    "&:first-of-type": {
      marginLeft: "1rem",
    },
    [theme.breakpoints.up("sm")]: {
      minWidth: "10rem",
    },
  },
  "&.Mui-selected": {
    // boxShadow: `0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)`,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    fontWeight: "bold",
  },
  "&:not(.Mui-selected)": {
    opacity: 0.7,
  },
  "&.MuiTab-labelIcon": {
    minHeight: "unset",
    paddingTop: "unset",
  },
  "&.MuiTab-wrapped": {
    flexDirection: "row",
    fontSize: "0.8rem",
    marginBottom: "unset",
  },
  "span[id=delete]": {
    "&.MuiIconButton-root:hover": {
      color: theme.palette.error.main,
    },
  },
}));

export default ButtonTabComponent;
