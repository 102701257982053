import { LiveParser, LiveProvider } from "@improdis/core";
import { FunctionComponent, useMemo } from "react";
import staticScope from "../../../helpers/custom-tile/staticScope";
import { useAppSelector } from "../../../store/hooks";
import { selectAssetsLogons } from "../../../store/main/asset-logons/assetsLogonsSlice";
import { selectTaskExecs } from "../../../store/main/task-execs/taskExecsSlice";
import { selectUtilizationExecs } from "../../../store/main/util-execs/utilExecsSlice";
import { AvailableAsset } from "../../../store/user/assets-selection/assetsSelectionSlice";
import ErrorFallback, { ErrorLevel } from "../error-fallbacks/ErrorFallback";

interface ReadonlyCustomTileComponentProps {
  code: string;
  asset: AvailableAsset;
}

const ReadonlyCustomTileComponent: FunctionComponent<
  ReadonlyCustomTileComponentProps
> = ({ code, asset }) => {
  const utilExecs = useAppSelector(selectUtilizationExecs);
  const taskExecs = useAppSelector(selectTaskExecs);
  const assetLogons = useAppSelector(selectAssetsLogons);

  const selectedAssetUtilExec = useMemo(
    () => asset && utilExecs.find((ue) => ue.assetId === asset.id),
    [utilExecs, asset]
  );

  const selectedAssetTaskExecs = useMemo(
    () => asset && taskExecs.filter((je) => je.assetId === asset.id),
    [taskExecs, asset]
  );

  const selectedAssetLogons = useMemo(
    () => asset && assetLogons.filter((al) => al.assetId === asset.id),
    [assetLogons, asset]
  );

  const dynamicScope = useMemo(
    () => ({
      asset: asset,
      utilExec: selectedAssetUtilExec,
      taskExecs: selectedAssetTaskExecs,
      assetLogons: selectedAssetLogons,
    }),
    [asset, selectedAssetUtilExec, selectedAssetTaskExecs, selectedAssetLogons]
  );

  return (
    <LiveProvider
      staticScope={staticScope}
      dynamicScope={dynamicScope}
      code={code}
      ErrorFallbackComponent={(props) => (
        <ErrorFallback {...props} level={ErrorLevel.Component} />
      )}
    >
      <LiveParser />
    </LiveProvider>
  );
};

export default ReadonlyCustomTileComponent;
