import { styled } from "@mui/material";
import { useAppSelector } from "../../../store/hooks";
import { selectRunningTaskExecs } from "../../../store/main/task-execs/taskExecsSlice";
import { selectTaskLogons } from "../../../store/main/task-logons/taskLogonsSlice";
import { selectTableView } from "../../../store/setup/setupSlice";
import { selectAvailableFlatAssets } from "../../../store/user/assets-selection/assetsSelectionSlice";
import { TilesContainer } from "../../common/tiles/TilesContainer";
import OrderTile from "./OrderTile";

const TableContainer = styled("div")(() => ({
  padding: "1.5rem",
  height: "100%",
  boxSizing: "border-box",
}));

interface OrdersPageProps {}

const OrdersPage: React.FC<OrdersPageProps> = () => {
  const tableView = useAppSelector(selectTableView);
  const taskExecs = useAppSelector(selectRunningTaskExecs);
  const availableAssets = useAppSelector(selectAvailableFlatAssets);
  const taskLogons = useAppSelector(selectTaskLogons);
  return (
    <>
      {tableView ? (
        <TableContainer>
          {/* <AssetsTable data={tableData} /> */}
        </TableContainer>
      ) : (
        <TilesContainer>
          {taskExecs.map(
            (t) =>
              availableAssets.find((a) => a.id === t.assetId) && (
                <OrderTile
                  key={`${t.assetId}|${t.assetPosition}`}
                  order={t}
                  asset={availableAssets.find((a) => a.id === t.assetId)}
                  taskLogons={taskLogons.filter(
                    (tl) =>
                      tl.taskId === t.currentTaskId && tl.finishedAt === null
                  )}
                />
              )
          )}
        </TilesContainer>
      )}
    </>
  );
};

export default OrdersPage;
