import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NestedSystemAttributeGroupDTO } from "../../services/httpService";
import { getNestedSystemAttributeGroups } from "../../services/main/systemAttributeGroupsService";

export interface NestedSystemAttributeGroup
  extends NestedSystemAttributeGroupDTO {}

export const initialState: Array<NestedSystemAttributeGroup> = [];

export const loadNestedAttributeGroupsAsync = createAsyncThunk(
  "definitions/loadNestedAttributeGroupsAsync",
  async (_, { getState, dispatch }) => {
    const result = await getNestedSystemAttributeGroups();
    return result;
  }
);

export const attributeGroupsSlice = createSlice({
  name: "attributeGroups",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadNestedAttributeGroupsAsync.fulfilled, (state, action) => {
        return action.payload;
      })
      .addCase(loadNestedAttributeGroupsAsync.rejected, (state, action) => {
        return [];
      });
  },
});

export default attributeGroupsSlice.reducer;
