import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "..";
import { UtilStateDto } from "../../services/httpService";
import { getAllUtilStates } from "../../services/main/utilStatesService";

export const initialState: Array<UtilStateDto> = [];

export const loadUtilStatesAsync = createAsyncThunk(
  "definitions/loadUtilStatesAsync",
  async (_, { getState, dispatch }) => {
    const result = await getAllUtilStates();
    return result;
  }
);

export const utilStates = createSlice({
  name: "definitions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadUtilStatesAsync.fulfilled, (state, action) => {
        return action.payload;
      })
      .addCase(loadUtilStatesAsync.rejected, (state, action) => {
        return [];
      });
  },
});

export const selectUtilStates = createSelector(
  (state: RootState) => state.definitions.utilStates,
  (utilStates) => utilStates
);

export default utilStates.reducer;
