import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { LocalStorageKey } from "../../helpers/localStorageKeys";
import { getFromLocalStorage } from "../../services/localStorageService";
import { useAppSelector } from "../../store/hooks";
import { selectSelectedAssetsIds } from "../../store/user/assets-selection/assetsSelectionSlice";
import AreasAppBar from "../common/app-bar/AreasAppBar";
import Footer from "../common/app-bar/FooterComponent";
import DeleteTabDialogComponent from "../common/dialogs/DeleteTabDialogComponent";
import EditableTabDialogComponent from "../common/dialogs/EditableTabDialogComponent";
import AssetsNavigation from "../main/navigation/AssetsNavigation";
import TabsMenu from "../main/navigation/TabsMenu";

const AreasRoot = styled("main")(() => ({
  display: "grid",
  grid: "min-content min-content 1fr / 1fr",
  maxHeight: "100%",
  overflow: "auto",
}));

const Content = styled("div")(() => ({
  height: "calc(100% - 0.5rem)",
  marginTop: "0.5rem",
  width: "100%",
  overflow: "auto",
}));

const ContentNoAsset = styled(Box)(() => ({
  display: "flex",
  height: "calc(100vh - 3.125rem)",
  justifyContent: "center",
  alignItems: "center",
}));

interface PageLayoutProps {}

const PageLayout: React.FC<PageLayoutProps> = () => {
  const selectedAssets = useAppSelector(selectSelectedAssetsIds);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const defaultAssets = getFromLocalStorage(LocalStorageKey.DEFAULT_ASSET_IDS);
  const location = useLocation();

  useEffect(() => {
    if (defaultAssets === undefined) {
      navigate("/defaultArea");
    }
  }, [defaultAssets, navigate]);

  useEffect(() => {
    if (selectedAssets.length !== 0 && selectedAssets[0] !== undefined) {
      navigate(
        `${
          location.pathname === "/" ? "/assets" : location.pathname
        }?assetIds=${selectedAssets.join(",")}`
      );
    } else {
      navigate(`${location.pathname}`);
    }
  }, [selectedAssets, location.pathname, navigate]);

  return (
    <React.Fragment>
      <AreasRoot id="areas-root">
        <AreasAppBar />
        <AssetsNavigation />
        {(defaultAssets !== undefined && defaultAssets.length !== 0) ||
        selectedAssets.length !== 0 ? (
          <>
            <TabsMenu />
            <Content>
              <Outlet />
            </Content>
          </>
        ) : (
          <ContentNoAsset>
            <Typography variant="h6" color="textSecondary">
              {t("No asset selected")}
            </Typography>
          </ContentNoAsset>
        )}
        <Footer />
        <EditableTabDialogComponent />
        <DeleteTabDialogComponent />
      </AreasRoot>
    </React.Fragment>
  );
};

export default PageLayout;
