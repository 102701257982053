import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={98.235}
    height={98.236}
    viewBox="0 0 98 98"
    {...props}
  >
    <path
      data-name="Icon metro-warning"
      d="m49.117 8.9 41.166 82.045H7.952L49.118 8.9Zm0-8.9C47 0 44.883 1.428 43.276 4.283L1.347 87.852c-3.212 5.711-.48 10.384 6.073 10.384h83.4c6.552 0 9.286-4.673 6.073-10.384L54.958 4.283C53.352 1.428 51.235 0 49.117 0Zm6.14 79.816a6.14 6.14 0 1 1-6.14-6.14 6.14 6.14 0 0 1 6.14 6.14Zm-6.14-12.279a6.14 6.14 0 0 1-6.14-6.14V42.978a6.14 6.14 0 0 1 12.279 0v18.419a6.14 6.14 0 0 1-6.139 6.14Z"
      fill="red"
    />
  </svg>
);

export default SvgComponent;
