import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

const Root = styled("span")(({ theme }) => ({
  display: "flex",
  // flex: "0 0 160px",
  alignItems: "center",
  justifyContent: "center",
}));

interface NoDataKPIComponentInterface {}

export const NoDataKPIComponent: React.FC<NoDataKPIComponentInterface> = () => {
  const { t } = useTranslation();
  return <Root>{t("No Data")}</Root>;
};
