import { Slider, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { ReactElement, useMemo } from "react";
import config from "../../config.json";

const Container = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  height: "45px",
  width: "70px",
  marginTop: "0px",
  marginBottom: "10px",
  marginLeft: "auto",
  paddingRight: "13px",
}));

const Description = styled(Typography)(({ theme }) => ({
  position: "absolute",
  left: "50%",
  top: "70%",
  transform: "translate(-50%, -20%)",
}));

interface ProgressSliderProps {
  currentcolor: string;
}

const ProgressSlider = styled(Slider)<ProgressSliderProps>(
  ({ currentcolor }) => ({
    color: currentcolor,
    cursor: "auto",
    "& .MuiSlider-rail": {
      backgroundColor: "#bfbfbf",
      height: "8.8px",
    },
    "& .MuiSlider-track": {
      border: "solid",
    },
    "& .MuiSlider-thumb": {
      width: "0",
      "&:hover, &:focus, &.Mui-active": {
        boxShadow: "0px 0px 0px 0px transparent",
      },
    },
  })
);

interface ProgressBarInterface {
  ended: number;
  total: number;
}

const ProgressBar: React.FC<ProgressBarInterface> = ({
  ended,
  total,
}): ReactElement => {
  const prepareColor = (ended: number, total: number) => {
    const progress: number = Math.floor((ended / total) * 100);

    if (progress < parseInt(config.progressGauge.lowLevel.percentageRange)) {
      return config.progressGauge.lowLevel.color;
    } else if (
      progress < parseInt(config.progressGauge.mediumLevel.percentageRange)
    ) {
      return config.progressGauge.mediumLevel.color;
    } else {
      return config.progressGauge.highLevel.color;
    }
  };

  const progress: number = useMemo(() => {
    return Math.floor((ended / total) * 100);
  }, [ended, total]);

  return (
    <Container>
      <ProgressSlider
        max={100}
        min={0}
        value={progress}
        currentcolor={prepareColor(ended, total)}
      />
      <Description variant="body2">{`${progress}%`}</Description>
    </Container>
  );
};

export default ProgressBar;
