import assetsLogonsListener from "../main/asset-logons/assetsLogonsListener";
import kpisListener from "../main/kpis/kpisListener";
import taskExecsListener from "../main/task-execs/taskExecsListener";
import taskLogonsListener from "../main/task-logons/taskLogonsListener";
import utilExecsListener from "../main/util-execs/utilExecsListener";
import utilEventsListener from "../main/utilization-events/utilEventsListener";
import assetsListener from "../user/assets-selection/assetsSelectionListener";
import userListener from "../user/userListener";
import editableTabListener from "./../common/dialogs/editableTabDialog/editableTabListener";
import tabSelectionListener from "../user/tab-selection/tabSelectionListener";

const listenersMiddleware = [
  utilExecsListener.middleware,
  taskExecsListener.middleware,
  userListener.middleware,
  utilEventsListener.middleware,
  assetsListener.middleware,
  assetsLogonsListener.middleware,
  taskLogonsListener.middleware,
  kpisListener.middleware,
  editableTabListener.middleware,
  tabSelectionListener.middleware,
];

export default listenersMiddleware;
