import { createListenerMiddleware } from "@reduxjs/toolkit";
import { RootState } from "../..";
import {
  loadAssetUtilStatesAsync,
  loadSelectedAssetsShiftKpisAsync,
} from "../../main/kpis/kpisSlice";
import { selectKpiView } from "../../setup/setupSlice";
import { selectAvailableFlatAssets } from "../assets-selection/assetsSelectionSlice";
import { tabChanged } from "./tabSelectionSlice";

const tabListener = createListenerMiddleware();

tabListener.startListening({
  actionCreator: tabChanged,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    const availableAssets = selectAvailableFlatAssets(
      listenerApi.getState() as RootState
    );
    const kpiView = selectKpiView(listenerApi.getState() as RootState);
    const availableAssetsIds = availableAssets.map((a) => a.id);
    if (
      availableAssetsIds.length > 0 &&
      kpiView &&
      action.payload === "/assets"
    ) {
      listenerApi.dispatch(loadSelectedAssetsShiftKpisAsync());
      // listenerApi.dispatch(loadSelectedAssetsCurrentKpisAsync());
      availableAssetsIds.map((id) =>
        listenerApi.dispatch(loadAssetUtilStatesAsync({ assetId: id }))
      );
    }
  },
});

export default tabListener;
