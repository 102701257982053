import * as React from "react";

function SvgComponent(props: any) {
  return (
    <svg width={51} height={30} viewBox="0 0 165 96.8" {...props}>
      <path
        d="M9.2 87.582V59.936l8.632-50.718H29.9l9.2 44.946 6.314 3.456 3.448-1.73 28.744-14.407v12.1l6.9 4.046 2.3-1.153V34l-6.324-4.046-33.915 16.718-.575-3.456-8.046-39.17L33.908 0H14.374l-4.6 4.046L0 58.773v33.419l4.6 4.609h126.482v-9.218z"
        fill="#00259a"
      />
      <path
        d="M158.101 29.963l-32.2 16.133V34.009l-6.9-4.046-32.188 16.133v10.367l29.9-14.979v12.1l6.9 4.046 32.2-16.133v21.895h9.2V34.009z"
        fill="#00cae4"
      />
    </svg>
  );
}

export default SvgComponent;
