import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import React from "react";
import { Typography } from "@mui/material";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  top: "auto",
  backgroundColor: "transparent",
  bottom: 0,
  height: "1.5rem",
  fontWeight: 400,
}));

const Content = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  paddingLeft: "0.6rem",
  paddingRight: "0.6rem",
  top: "auto",
  backgroundColor: "transparent",
  color: theme.palette.text.secondary,
  bottom: 0,
  height: "1.5rem",
  fontWeight: 400,
  pointerEvents: "none",
  userSelect: "none",
}));

const FooterComponent: React.FC = () => {
  return (
    <StyledAppBar position="fixed" elevation={0}>
      <Content>
        <Typography variant="footerLabel">ImFactory</Typography>
        <Typography variant="footerLabel">
          ImProdis {">"} {process.env.REACT_APP_WEBSITE_NAME}{" "}
          {process.env.REACT_APP_PRODUCT_VERSION} (
          {process.env.REACT_APP_VERSION})
        </Typography>
      </Content>
    </StyledAppBar>
  );
};

export default FooterComponent;
