import { t } from "i18next";
import { FormatDate, GetDuration } from "./DateFormatHelper";
import { UserDto } from "../services/httpService";
import { TaskExec } from "../store/main/task-execs/taskExecsSlice";
import { AvailableAsset } from "../store/user/assets-selection/assetsSelectionSlice";
import { UtilExec } from "../store/main/util-execs/utilExecsSlice";

export const getDateText = (isPrimaryView: boolean, date: Date) => {
  let dateString = date.toString();
  if (isPrimaryView) {
    return `${t("Date")}: ${FormatDate(dateString, t)}`;
  } else {
    return `${t("Since")}: ${GetDuration(dateString, t)}`;
  }
};

export const getUserData = (users: UserDto[], userId: string) => {
  const user = users.find((u) => u.id === userId);
  if (user)
    return user.firstName === null || user.lastName === null
      ? user.userName
      : user.firstName + " " + user.lastName;
  else return "";
};

export const getTaskNameText = (taskExec: TaskExec) => {
  return `${taskExec?.currentTaskName} > ${taskExec?.currentTaskItemName} `;
};

export const getAssetStateText = (
  asset: AvailableAsset,
  utilExec: UtilExec
) => {
  return (
    (asset?.name || asset?.description) + " > " + t(utilExec?.utilRawName || "")
  );
};
