import { EntityIcon, JobsIcon } from "@improdis/core";
import { Box, Paper, Tooltip, Typography, styled } from "@mui/material";
import { t } from "i18next";
import React, { FunctionComponent, useMemo, useState } from "react";
import {
  getAssetStateText,
  getDateText,
  getTaskNameText,
  getUserData,
} from "../../../helpers/tileDisplayFunctions";
import { selectUsers } from "../../../store/definitions/usersSlice";
import { useAppSelector } from "../../../store/hooks";
import { AssetLogon } from "../../../store/main/asset-logons/assetsLogonsSlice";
import { selectTaskExecs } from "../../../store/main/task-execs/taskExecsSlice";
import { TaskLogon } from "../../../store/main/task-logons/taskLogonsSlice";
import { selectUtilizationExecsByAssetId } from "../../../store/main/util-execs/utilExecsSlice";
import { AvailableAsset } from "../../../store/user/assets-selection/assetsSelectionSlice";
import { StyledLinesEllipsis } from "../../common/LineEllipsis";
import ProgressBar from "../../common/ProgressBar";
import { IconContainer } from "../../common/tiles/IconContainer";
import { InfoBorderContainer } from "../../common/tiles/InfoBorderContainer";
import { StatusBar } from "../../common/tiles/StatusBar";
import { TileContent } from "../../common/tiles/TileContent";
import { TileHeader } from "../../common/tiles/TileHeader";
import { InfoContainer } from "./../../common/tiles/InfoContainer";
import { env } from "../../../env";

const ValueContainer = styled("div")(({ theme }) => ({
  marginLeft: "auto",
  textAlign: "right",
  // minWidth: "3rem",
}));

interface OperatorTileProps {
  assetLogon: AssetLogon;
  asset: AvailableAsset | undefined;
  taskLogon: TaskLogon | undefined;
}

const OperatorTile: FunctionComponent<OperatorTileProps> = ({
  assetLogon,
  asset,
  taskLogon,
}) => {
  const utilExec = useAppSelector((state) =>
    selectUtilizationExecsByAssetId(state, assetLogon.assetId)
  );
  const taskExecs = useAppSelector(selectTaskExecs);
  const users = useAppSelector(selectUsers);
  const user = users.find((u) => u.id === assetLogon.userId);
  const [isPrimaryView, setIsPrimaryView] = useState<boolean>(true);
  React.useEffect(() => {
    const timer = setTimeout(
      () => setIsPrimaryView((val) => !val),
      env.REACT_APP_TILE_INTERVAL_TIME
    );
    return () => clearTimeout(timer);
  }, [isPrimaryView]);

  const taskExec = useMemo(
    () =>
      taskExecs.find(
        (te) =>
          te.currentTaskId === taskLogon?.taskId && te.assetId === asset?.id
      ),
    [taskLogon, taskExecs, asset]
  );

  return (
    <Paper elevation={2} square>
      <TileContent>
        <TileHeader>
          <Typography variant="h6">{getUserData(users, user!.id)}</Typography>
        </TileHeader>
        <InfoContainer>
          {utilExec && asset && (
            <Box>
              <>
                <StatusBar color={utilExec.utilStateColor ?? ""} />
                <IconContainer>
                  <EntityIcon />
                </IconContainer>
                <Tooltip title={getAssetStateText(asset, utilExec)}>
                  <div>
                    <StyledLinesEllipsis
                      text={getAssetStateText(asset, utilExec)}
                      maxLine="3"
                      ellipsis="..."
                      trimRight
                      basedOn="letters"
                    />
                  </div>
                </Tooltip>
              </>
              <ValueContainer>
                <Typography variant="body2">
                  {utilExec?.currentUtilRawStartedAt && (
                    <span>
                      {getDateText(
                        isPrimaryView,
                        utilExec?.currentUtilRawStartedAt
                      )}
                    </span>
                  )}
                </Typography>
              </ValueContainer>
            </Box>
          )}
        </InfoContainer>
        <InfoBorderContainer>
          {taskExec && taskLogon ? (
            <Box display="flex" minHeight="60px">
              <>
                <StatusBar color="success" />
                <IconContainer>
                  <JobsIcon />
                </IconContainer>
                <Tooltip
                  key={taskLogon.taskId}
                  title={getTaskNameText(taskExec)}
                >
                  <div>
                    <StyledLinesEllipsis
                      text={getTaskNameText(taskExec)}
                      maxLine="3"
                      ellipsis="..."
                      trimRight
                      basedOn="letters"
                    />
                  </div>
                </Tooltip>
              </>
              {isPrimaryView ? (
                <ProgressBar
                  key={taskExec.assetPosition}
                  total={Number(taskExec.currentTaskQuantityRequired)}
                  ended={Number(taskExec.currentTaskQuantityProduced)}
                />
              ) : (
                <ValueContainer>
                  <Typography variant="body2" key={taskExec.assetPosition}>
                    {taskExec.currentTaskQuantityProduced}/
                    {taskExec.currentTaskQuantityRequired}
                  </Typography>
                </ValueContainer>
              )}
            </Box>
          ) : (
            <Box>
              <StatusBar />
              <IconContainer>
                <JobsIcon />
              </IconContainer>
              <Typography variant="body2">{t("No running jobs")}</Typography>
            </Box>
          )}
        </InfoBorderContainer>
      </TileContent>
    </Paper>
  );
};

export default OperatorTile;
