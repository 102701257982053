import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import {
  createCustomTabAsync,
  updateCustomTabAsync,
} from "../../customTabsSlice";
import { editableTabDialogClosed } from "./editableTabSlice";

const editableTabListener = createListenerMiddleware();

editableTabListener.startListening({
  matcher: isAnyOf(
    createCustomTabAsync.fulfilled,
    updateCustomTabAsync.fulfilled
  ),
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    listenerApi.dispatch(editableTabDialogClosed());
  },
});

export default editableTabListener;
