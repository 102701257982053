import SnackbarUtils from "../helpers/snackbarHelper";
import i18n from "../i18n";

function escapeRegExp(string: string) {
  return string.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&");
}

export const handleExceptionMessage = (
  exception: any,
  variant: "toast" | "console" = "toast"
) => {
  if (typeof exception === "object" && exception !== null) {
    const exceptionMessage =
      exception?.response?.detail ??
      exception?.response?.title ??
      exception?.title ??
      exception?.message;
    for (const [key, value] of Object.entries(errorsDictionary)) {
      const keyRegexValues = value.map((v) => new RegExp(escapeRegExp(v)));
      if (keyRegexValues.every((rx) => rx.test(exceptionMessage))) {
        if (variant === "toast") SnackbarUtils.error(i18n.t(key));
        console.error(i18n.t(key));
        return;
      }
    }
    if (variant === "toast") SnackbarUtils.error(i18n.t(exceptionMessage));
    console.error(exceptionMessage);
  } else if (typeof exception === "string") {
    if (variant === "toast") SnackbarUtils.error(exception);
    console.error(exception);
  }
};

interface IErrorsMapper {
  [index: string]: Array<string>;
}

// key: [procedure_name]_[error_name]
const errorsDictionary: IErrorsMapper = {
  "[sp_I_IP_AddProdPostExecByName]_[not runing job]": [
    "The supplied job",
    "was not running on the asset:",
    "during the specified production time (in UTC):",
  ],
  "[sp_U_User_Name_DeActivateUser]_[invalid password]": [
    "Cannot insert the value NULL into column 'object_name', table 'MESDB.dbo.error_log'; column does not allow nulls. INSERT fails.",
    "The statement has been terminated.",
  ],
  //{3408}
  "[FactMES_Server_Communication]_[invalid userId]": [
    "Logon Failure, Unknown UserID or Bad Password",
  ],
  //{3175}
  "[sp_U_Job_Exec_StartJob]_[more jobs on ent than allowed]": [
    "You are trying to run more jobs on this asset than it allows to be run simultaneously.  You need to either stop one or more jobs if any are currently running, or start a job that does not have as many other jobs linked to it, or change the asset's setup to allow more jobs to be run at the same time.",
  ],
  //{3189}
  "[sp_U_Job_Exec_PauseJob]_[pause not running job]": [
    "The job is not currently running on the specified asset.",
  ],
  //{3402}
  "[FactMES_Server_Communication]_[invalid PIN]": [
    "Invalid Password - No associated User_Id",
  ],
  "Network Error": ["Network Error"],
  ResumeJobFailed: ["Another user is working currently on this job."],
  Unauthorized: ["Unauthorized"],
};
