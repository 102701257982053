import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { AsyncValue, RootState } from "../..";
import { ProductionLogDto } from "../../../services/httpService";
import { getCurrentShiftProductionEventsByAssetId } from "../../../services/main/productionEventsService";

export interface ProductionEvent extends ProductionLogDto {}
export type ProductionEventSummary = Pick<
  ProductionLogDto,
  "itemName" | "productionReasonId" | "quantityProduced"
>;

export interface ProductionEventsState {
  productionEvents: AsyncValue<Array<ProductionEvent>>;
  selectedProductionEventLogId: string | null; //logId
  pendingAddProduction: boolean;
}

export const initialState: ProductionEventsState = {
  productionEvents: {
    value: [],
    pending: false,
  },
  selectedProductionEventLogId: null,
  pendingAddProduction: false,
};

interface LoadProductionEventParams {
  assetId: string;
}

export const loadProductionEventsForCurrentShiftAsync = createAsyncThunk(
  "productionEvents/loadProductionEventsForCurrentShiftAsync",
  async (params: LoadProductionEventParams, { getState, dispatch }) => {
    const result = await getCurrentShiftProductionEventsByAssetId(
      params.assetId
    );
    return result;
  }
);

export const productionEventsSlice = createSlice({
  name: "productionEvents",
  initialState,
  reducers: {
    productionEventSelected: (state, action: PayloadAction<string>) => {
      state.selectedProductionEventLogId = action.payload;
    },
    productionEventsSelectionCleared: (state, action: PayloadAction<void>) => {
      state.selectedProductionEventLogId = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        loadProductionEventsForCurrentShiftAsync.pending,
        (state, action) => {
          state.productionEvents.pending = true;
        }
      )
      .addCase(
        loadProductionEventsForCurrentShiftAsync.fulfilled,
        (state, action) => {
          state.productionEvents.value = action.payload!;
          state.productionEvents.pending = false;
        }
      )
      .addCase(
        loadProductionEventsForCurrentShiftAsync.rejected,
        (state, action) => {
          state.productionEvents.value = [];
          state.productionEvents.pending = false;
        }
      );
  },
});

export const { productionEventSelected, productionEventsSelectionCleared } =
  productionEventsSlice.actions;

export const selectProductionEvents = createSelector(
  (state: RootState) => state.main.productionEvents.productionEvents.value,
  (productionEvents) => productionEvents
);

// export const selectRunnedTaskProductionEvents = createSelector(
//   selectProductionEvents,
//   selectRunnedTaskId,
//   (productionEvents, runnedTaskId) =>
//     productionEvents.filter((pe) => pe.taskId === runnedTaskId)
// );

export const selectProductionEventsPending = createSelector(
  (state: RootState) => state.main.productionEvents.productionEvents.pending,
  (productionEventsPending) => productionEventsPending
);

export const selectAddProductionPending = createSelector(
  (state: RootState) => state.main.productionEvents.pendingAddProduction,
  (pendingAddProduction) => pendingAddProduction
);

export const selectProductionEventsSummary = createSelector(
  selectProductionEvents,
  (productionEvents) => {
    const uniqs = _.uniqBy(productionEvents, (v) =>
      [v.itemName, v.productionReasonId].join()
    );
    return uniqs.map(
      (v) =>
        ({
          itemName: v.itemName,
          productionReasonId: v.productionReasonId,
          quantityProduced: _.sumBy(
            productionEvents.filter(
              (d) =>
                d.itemName === v.itemName &&
                d.productionReasonId === v.productionReasonId
            ),
            "quantityProduced"
          ),
        } as ProductionEventSummary)
    );
  }
);

export default productionEventsSlice.reducer;
