import { createListenerMiddleware } from "@reduxjs/toolkit";
import {
  assetLogOffRegisteredSocketEvent,
  assetLogOnRegisteredSocketEvent,
} from "../../sockets/access-management/assetLogonsSlice";
import {
  AssetLogon,
  assetLogOffRegistered,
  assetLogOnRegistered,
} from "./assetsLogonsSlice";
import { AssetLogoffRegisteredSocketDto } from "../../../services/sockets/hub-services/access-management/assetLogonAllService";

const assetsLogonsListener = createListenerMiddleware();

assetsLogonsListener.startListening({
  actionCreator: assetLogOnRegisteredSocketEvent,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    const { assetId, userId, connectionId, startTime } = action.payload;

    listenerApi.dispatch(
      assetLogOnRegistered({
        assetId: assetId,
        userId: userId,
        connectionId: connectionId,
        startedAt: startTime,
        finishedAt: null,
      } as AssetLogon)
    );
  },
});

assetsLogonsListener.startListening({
  actionCreator: assetLogOffRegisteredSocketEvent,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    const { assetId, userId, connectionId } = action.payload;

    listenerApi.dispatch(
      assetLogOffRegistered({
        assetId: assetId,
        userId: userId,
        connectionId: connectionId,
      } as AssetLogoffRegisteredSocketDto)
    );
  },
});

export default assetsLogonsListener;
