export const defaultNewTileComponent = `
({ asset, utilExec, taskExecs, assetLogons }) => {
  //PLACE FOR OBJECT DESTRUCTURING
  const {
    useTranslation,
    useLocalStorage,
    useLocalStorageState,
    usePrevious,
  } = hooks;
  const { Paper, Typography, Box, styled } = materialUI;
    const {    UtilSummaryChartComponent,
    OEEChartComponent,
    OEESimpleChartComponent,
    ProductionSummaryChartComponent,
    UtilDowntimesChartComponent,
    UtilTimelineChartComponent} = chartComponents;

  //PLACE FOR HOOKs
  const { t, i18n } = useTranslation();

  //PLACE FOR STYLED COMPONENTS
  const Tile = React.useMemo(
    () =>
      styled(Paper)(({ theme }) => ({
        display: "flex",
        flexDirection: "column",
      })),
    []
  );

  const ReasonIndicator = React.useMemo(
    () =>
      styled("div")(({ theme }) => ({
        minHeight: "16px",
      })),
    []
  );

  const TileContent = React.useMemo(
    () =>
      styled(Box)(({ theme }) => ({
        padding: "0rem 0.375rem 0.375rem 0.375rem",
      })),
    []
  );

  const Title = React.useMemo(
    () =>
      styled(Typography)(({ theme }) => ({
        height: "30px",
        fontSize: "20px",
        margin: "13px 0px 18px 6px",
      })),
    []
  );
  //YOUR COMPONENT LOGIC

  const title = React.useMemo(() => t(asset.name), [asset]);

  //RENDER
  return (
    <Tile elevation={2} square>
      <ReasonIndicator
        style={{
          backgroundColor: utilExec?.utilStateColor,
        }}
      />
      <TileContent>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Title>{title}</Title>
        </Box>
        <Typography variant="subtitle2">
          Oto przykładowe użycie edytowalnego komponentu.
        </Typography>
        <Box display="flex" flexDirection="column" sx={{ gap: "0.5rem", m: 2 }}>
          <Typography variant="caption">Baza danych</Typography>
          <Typography variant="body1">
            Nazwa stanowiska: {asset.name}
          </Typography>
          <Typography variant="body1">
            Opis stanowiska: {asset.description}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" sx={{ gap: "0.5rem", m: 2 }}>
          <Typography variant="caption">Zalogowani użytkownicy</Typography>
          {assetLogons.map((el) => (
            <Typography key={\`\${el.entId}/\${el.userId}/\${el.sessionId}\`}>
              {el.userFullName ?? el.userName}
            </Typography>
          ))}
        </Box>
        <Box display="flex" flexDirection="column" sx={{ gap: "0.5rem", m: 2 }}>
        <Typography variant="caption">Uruchomione zlecenia</Typography>
        {taskExecs.map((exec) => (
          <Typography
            key={\`\${exec.assetPosition} > \${exec.currentWorkOrderId} > \${exec.currentTaskId} > \${exec.currentTaskItemName}\`}
          >
            {\`Pozycja: \${exec.assetPosition} \` +
              (exec.currentTaskId
                ? \`\${exec.currentWorkOrderName} > \${exec.currentTaskName} > \${exec.currentTaskItemName}\`
                : "Brak zlecenia")}
          </Typography>
        ))}
      </Box>
      <Box display="flex" flexDirection="column" sx={{ gap: "0.5rem", m: 2 }}>
      <Typography variant="caption">Obecny powód</Typography>
      <Typography>
        {
          t(utilExec?.utilRawName)
        }
      </Typography>
    </Box>
      </TileContent>
    </Tile>
  );
};

`;
