import {
  PayloadAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { AsyncValue, RootState } from "../..";
import { AssetLogonDTO } from "../../../services/httpService";
import { getAllAssetsLogons } from "../../../services/main/assetLogonsService";
import { AssetLogoffRegisteredSocketDto } from "../../../services/sockets/hub-services/access-management/assetLogonAllService";

export type AssetLogon = Omit<AssetLogonDTO, "lastHeartbeatAt">;

export interface AssetLogonsState {
  assetsLogons: AsyncValue<Array<AssetLogon>>;
}

export const initialState: AssetLogonsState = {
  assetsLogons: {
    value: [],
    pending: false,
  },
};

export const loadAssetsLogonsAsync = createAsyncThunk(
  "assetsLogons/loadAssetsLogonsAsync",
  async (_, { getState, dispatch }) => {
    const result = await getAllAssetsLogons(undefined, undefined, true);
    return result;
  }
);

export const assetsLogonsSlice = createSlice({
  name: "assetsLogons",
  initialState,
  reducers: {
    assetLogOnRegistered: (state, action: PayloadAction<AssetLogon>) => {
      state.assetsLogons.value.push(action.payload);
    },
    assetLogOffRegistered: (
      state,
      action: PayloadAction<AssetLogoffRegisteredSocketDto>
    ) => {
      const index = state.assetsLogons.value.findIndex(
        (el) =>
          el.assetId === action.payload.assetId &&
          el.userId === action.payload.userId &&
          el.connectionId === action.payload.connectionId
      );
      if (index !== -1) {
        state.assetsLogons.value.splice(index, 1);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadAssetsLogonsAsync.pending, (state, action) => {
        state.assetsLogons.pending = true;
      })
      .addCase(loadAssetsLogonsAsync.fulfilled, (state, action) => {
        state.assetsLogons.value = action.payload!;
        state.assetsLogons.pending = false;
      })
      .addCase(loadAssetsLogonsAsync.rejected, (state, action) => {
        state.assetsLogons.value = [];
        state.assetsLogons.pending = false;
      });
  },
});

export const { assetLogOnRegistered, assetLogOffRegistered } =
  assetsLogonsSlice.actions;

export const selectAssetsLogons = createSelector(
  (state: RootState) => state.main.assetsLogons.assetsLogons.value,
  (assetsLogons) => assetsLogons
);

export const selectAssetLogonsByAssetId = createSelector(
  [selectAssetsLogons, (state, assetId: string) => assetId],
  (assetsLogons, assetId) => assetsLogons.filter((al) => al.assetId === assetId)
);

export default assetsLogonsSlice.reducer;
