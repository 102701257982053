import { UserIcon } from "@improdis/core";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, Divider, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { useParams } from "react-router-dom";
import "simplebar/dist/simplebar.min.css";
import { env } from "../../../env";
import { selectNestedAssets } from "../../../store/definitions/assetsSlice";
import {
  assetsNavigationDialogClosed,
  assetsNavigationDialogOpened,
  selectAssetNavigationDialogOpen,
} from "../../../store/common/dialogs/assetNavigationSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  selectIsAuthorized,
  selectUserDetails,
} from "../../../store/user/authSlice";
import AppBarSettingsContent from "./AppBarSettings";
import { selectAllFlatAssets } from "../../../store/user/assets-selection/assetsSelectionSlice";

const UserInfo = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  paddingRight: "0.5rem",
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  height: "3.125rem",
  maxHeight: "3.5rem",
  padding: "0.1rem 1rem",
}));

const AppBarContent = styled("div")(({ theme }) => ({
  display: "flex",
  height: "100%",
  // flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  flexGrow: 1,
}));

const AppBarSection = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "1rem",
  // alignItems: "center",
}));

const MenuButton = styled(IconButton)(({ theme }) => ({
  // margin: "0.3375rem 1rem 0.3375rem 0rem",
  marginTop: "0.3375rem",
  marginBottom: "0.3375rem",
  padding: "0.1rem",
}));

const StyledMenuIcon = styled(MenuIcon)(({ theme }) => ({
  width: "2.25rem",
  height: "2.25rem",
  fill: theme.palette.primary.contrastText,
}));

const Title = styled("div")(({ theme }) => ({
  textAlign: "left",
}));

const AppBarAsset = styled("div")(({ theme }) => ({
  display: "flex",
  marginLeft: "2rem",
  marginTop: "0.4rem",
  marginBottom: "0.4rem",
  alignItems: "center",
  "& > hr": {
    marginRight: "1rem",
  },
}));

const StyledUserIcon = styled(UserIcon)(({ theme }) => ({
  height: 25,
  width: 25,
  fill: theme.palette.primary.contrastText,
  paddingRight: "0.5rem",
  paddingLeft: "0.5rem",
}));

interface AppBarSettingsProps {}

const AssetDetailsAppBar: React.FC<AppBarSettingsProps> = () => {
  const dispatch = useAppDispatch();
  const openAssetsNavigation = useAppSelector(selectAssetNavigationDialogOpen);
  const isAuthorized = useAppSelector(selectIsAuthorized);
  const userInfo = useAppSelector(selectUserDetails);
  const { assetId } = useParams();
  const allFlatAssets = useAppSelector(selectAllFlatAssets);
  return (
    <StyledAppBar position="relative" elevation={2}>
      <AppBarContent>
        <AppBarSection>
          <MenuButton
            onClick={() =>
              openAssetsNavigation
                ? dispatch(assetsNavigationDialogClosed())
                : dispatch(assetsNavigationDialogOpened())
            }
            size="large"
          >
            <StyledMenuIcon />
          </MenuButton>
          <Title>
            <Typography variant="primaryLabel">
              {process.env.REACT_APP_WEBSITE_NAME}
            </Typography>
            <div>
              <Typography variant="secondaryLabel">
                {env.REACT_APP_THEME_TYPE === "AVEVA" ? "AVEVA" : "ImProdis"}
              </Typography>
            </div>
          </Title>
          <AppBarAsset>
            <Divider orientation="vertical" variant="fullWidth" flexItem />
            {allFlatAssets.find((a) => a.id === assetId)?.name}
          </AppBarAsset>
        </AppBarSection>
        <AppBarSection>
          {isAuthorized && (
            <UserInfo>
              <StyledUserIcon />
              <Typography variant="subtitle1">
                {userInfo.firstName === null || userInfo.lastName === null
                  ? userInfo.userName
                  : userInfo.firstName + " " + userInfo.lastName}
              </Typography>
            </UserInfo>
          )}
          <AppBarSettingsContent></AppBarSettingsContent>
        </AppBarSection>
      </AppBarContent>
    </StyledAppBar>
  );
};

export default AssetDetailsAppBar;
