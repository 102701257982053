import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { AppThunkDispatch } from "../../../store";
import {
  deleteCustomTabAsync,
  selectCustomTabComponents,
} from "../../../store/common/customTabsSlice";
import { deleteTabDialogClosed } from "../../../store/common/dialogs/deleteTabSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import i18n from "../../../i18n";

const StyledDeleteIcon = styled(DeleteIcon)(({ theme }) => ({
  marginRight: "0.5rem",
  fill: "#FFFFFF",
  minHeight: 30,
  minWidth: 30,
}));

interface DeleteTabDialogComponentProps {}

const DeleteTabDialogComponent: FunctionComponent<
  DeleteTabDialogComponentProps
> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch() as AppThunkDispatch;
  const openDeleteTabDialog = useAppSelector(
    (state) => state.dialogs.deleteTabDialog
  );
  const actualTabs = useAppSelector(selectCustomTabComponents);
  const location = useLocation();

  const handleCloseDialog = () => {
    dispatch(deleteTabDialogClosed());
  };

  const tabName = useMemo(() => {
    const selectedTab = actualTabs.find((e) => e.path === location.pathname);
    return i18n.language === "pl" ? selectedTab?.titlePl : selectedTab?.titleEn;
  }, [actualTabs, location.pathname]);

  const handleDeleteTab = () => {
    dispatch(
      deleteCustomTabAsync({
        path: location.pathname,
      })
    );
    dispatch(deleteTabDialogClosed());
  };

  return (
    <Dialog open={openDeleteTabDialog} maxWidth={false}>
      <DialogTitle>
        <Box display="flex">
          <StyledDeleteIcon />
          <div>{t("Delete tab") + ": " + tabName}</div>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography>
          {t("Are you sure you want to permanently delete the selected tab?")}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="success"
          onClick={handleDeleteTab}
          disableElevation
        >
          {t("Yes")}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleCloseDialog}
          disableElevation
        >
          {t("No")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteTabDialogComponent;
