import { useCallback, useState } from "react";
import { executeProcedure } from "../../services/main/extensionService";

/**
 
 * Executes database command using stored procedure in MSSQL or function in PostgreSQL.
 
 * 
 
 * @param {string} procedureName - MSSQL - name of procedure, PostgreSQL - name of function. In Both cases schema 'extension' is required.

 * @param {{ [key: string]: string; }} parameters - Dictionary of parameters passed to the procedure/function. In case of PostgreSQL it is important to keep order of the parameters the same as the function have in database.

 * @returns {[(parameters?: { [key: string]: string }) => Promise<number>, boolean]} Command handler that accepts list of parameters and flag indicating pending submit. Parameters - dictionary of parameters passed to the procedure/function. In case of PostgreSQL it is important to keep order of the parameters the same as the function have in database.
 
 * 
 
 * @example
 
 * const [insertUserAsync, pending] = useDatabaseCommand("imfactory_sp_I_User");
 * 
 * const handleUserInsert = async () => {
 *  await insertUserAsync({userId: "admin"});
 * }

*/

const useDatabaseCommand = <T>(
  procedureName: string
): [
  (parameters?: { [key: string]: string }) => Promise<Array<T> | undefined>,
  boolean
] => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleDatabaseCommand = useCallback(
    async (parameters?: { [key: string]: string }) => {
      setIsSubmitting(true);
      const response = await executeProcedure<T>(procedureName, parameters);
      setIsSubmitting(false);
      return response;
    },
    [procedureName]
  );

  return [handleDatabaseCommand, isSubmitting];
};

export default useDatabaseCommand;
