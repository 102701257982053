import { styled } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { FunctionComponent, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { SnackbarUtilsConfigurator } from "../../helpers/snackbarHelper";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectAppStarted } from "../../store/setup/setupSlice";
import AppSuspense from "../common/AppSuspense";
import { tabChanged } from "../../store/user/tab-selection/tabSelectionSlice";

const Root = styled("div")(({ theme }) => ({
  height: "100vh",
  width: "100vw",
  backgroundColor: theme.palette.background.default,
}));

interface MainLayoutProps {}

const MainLayout: FunctionComponent<MainLayoutProps> = () => {
  const appStarted = useAppSelector(selectAppStarted);
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(tabChanged(`${location.pathname}`));
  }, [location.pathname, dispatch]);

  return (
    <Root id="root">
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        autoHideDuration={5000}
      >
        <SnackbarUtilsConfigurator />
        {appStarted ? <Outlet /> : <AppSuspense />}
      </SnackbarProvider>
    </Root>
  );
};

export default MainLayout;
