import { env } from "../../env";
import { handleExceptionMessage } from "../../helpers/handleExceptionMessage";
import { instance } from "../axiosInstance";
import {
  AuthenticationClient,
  LoginUserDto,
  LoginUserRequest,
  RefreshTokenDto,
} from "../httpService";

const http = new AuthenticationClient(env.REACT_APP_MES_API_URL, instance);

export const logIn = async (
  userName: string,
  password: string,
  throwError: boolean = true
): Promise<LoginUserDto | undefined> => {
  try {
    const connectionType = 0; // TODO
    const request = new LoginUserRequest({
      connectionType,
      userName,
      password,
    });
    const result = await http.logIn(request);
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const refreshToken = async (
  throwError: boolean = true
): Promise<RefreshTokenDto | undefined> => {
  try {
    const result = await http.refreshToken();
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const logOut = async (throwError: boolean = true): Promise<void> => {
  try {
    await http.logOut();
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};
