import {
  PayloadAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { AsyncValue, RootState } from "../..";
import { ITaskLogonDTO } from "../../../services/httpService";
import { getTaskLogons } from "../../../services/main/taskLogonsService";
import { TaskLogoffRegisteredSocketDto } from "../../../services/sockets/hub-services/access-management/taskLogonAllService";

export type TaskLogon = Omit<ITaskLogonDTO, "logId">;
export interface TaskLogonsState {
  taskLogons: AsyncValue<Array<TaskLogon>>;
}

export const initialState: TaskLogonsState = {
  taskLogons: {
    value: [],
    pending: false,
  },
};

export const loadTaskLogonsAsync = createAsyncThunk(
  "taskLogons/loadTaskLogonsAsync",
  async (_, { getState, dispatch }) => {
    const result = await getTaskLogons(undefined, undefined, true);
    return result;
  }
);

export const taskLogonsSlice = createSlice({
  name: "taskLogons",
  initialState,
  reducers: {
    taskLogOnRegistered: (state, action: PayloadAction<TaskLogon>) => {
      state.taskLogons.value.push(action.payload);
    },
    taskLogOffRegistered: (
      state,
      action: PayloadAction<TaskLogoffRegisteredSocketDto>
    ) => {
      const filteredTaskLogons = state.taskLogons.value.filter(
        (el) =>
          !(
            el.taskId === action.payload.taskId &&
            el.userId === action.payload.userId &&
            el.connectionId === action.payload.connectionId
          )
      );
      state.taskLogons.value = filteredTaskLogons;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadTaskLogonsAsync.pending, (state, action) => {
        state.taskLogons.pending = true;
      })
      .addCase(loadTaskLogonsAsync.fulfilled, (state, action) => {
        state.taskLogons.value = action.payload!;
        state.taskLogons.pending = false;
      })
      .addCase(loadTaskLogonsAsync.rejected, (state, action) => {
        state.taskLogons.value = [];
        state.taskLogons.pending = false;
      });
  },
});

export const { taskLogOnRegistered, taskLogOffRegistered } =
  taskLogonsSlice.actions;

export const selectTaskLogons = createSelector(
  (state: RootState) => state.main.taskLogons.taskLogons.value,
  (taskLogons) => taskLogons
);

// export const selectAssetLogonsByAssetId = createSelector(
//   [selectAssetsLogons, (state, assetId: string) => assetId],
//   (assetsLogons, assetId) => assetsLogons.filter((al) => al.assetId === assetId)
// );

export default taskLogonsSlice.reducer;
