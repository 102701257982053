import { Packet } from "mqtt";
import { AnyAction, Dispatch, Middleware } from "redux";
import { REHYDRATE } from "redux-persist";
import { RootState } from "..";
import { env } from "../../env";
import { handleExceptionMessage } from "../../helpers/handleExceptionMessage";
import { getAssetIdFromTopic, getAssetTopic } from "../../helpers/mqttUtils";
import { mqttService } from "../../services/mqtt/mqttInstance";
import {
  connectedToMqtt,
  disconnectedFromMqtt,
  mqttTopicValueChanged,
  reconnectingMqtt,
  selectMqttDefinitionsByAssetId,
  topicsDefinitionsLoaded,
} from "../common/mqttSlice";
import {
  assetsSelected,
  selectChildrenFlatAssets,
  selectSelectedAssets,
} from "../user/assets-selection/assetsSelectionSlice";

const initHandlers = (
  dispatch: Dispatch<AnyAction>,
  getState: () => RootState
) => {
  const handleConnect = () => dispatch(connectedToMqtt());
  const handleDisconnect = () => dispatch(disconnectedFromMqtt());
  const handleError = (err: Error) => handleExceptionMessage(err);
  const handleReconnect = () => dispatch(reconnectingMqtt());
  const handleMessage = (topic: string, payload: Buffer, packet: Packet) => {
    const assetId = getAssetIdFromTopic(topic);
    const assetTopic = getAssetTopic(topic);
    const extTopic = selectMqttDefinitionsByAssetId(getState(), assetId);

    if (extTopic?.topics?.some((et) => et.value === assetTopic)) {
      const extTopicName = extTopic.topics.find(
        (et) => et.value === assetTopic
      )?.name;
      if (extTopic !== undefined && extTopicName !== undefined) {
        const value = new TextDecoder("utf-8").decode(payload);
        value !== undefined &&
          dispatch(
            mqttTopicValueChanged({
              assetId: assetId,
              propName: extTopicName,
              value: value,
            })
          );
      }
    } else {
    }
  };
  return {
    handleConnect,
    handleDisconnect,
    handleError,
    handleReconnect,
    handleMessage,
  };
};

const mqttMiddleware: Middleware =
  ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    if (
      action.type === topicsDefinitionsLoaded.type ||
      action.type === REHYDRATE
    ) {
      if (env.REACT_APP_MQTT_URL) {
        const {
          handleConnect,
          handleDisconnect,
          handleError,
          handleReconnect,
          handleMessage,
        } = initHandlers(dispatch, getState);

        mqttService.connect(
          handleConnect,
          handleDisconnect,
          handleReconnect,
          handleError,
          handleMessage
        );
      }
    } else if (action.type === assetsSelected.type) {
      const newSelectedAssets = action.payload;
      const oldSelectedAssets = selectSelectedAssets(getState() as RootState);
      const unsubscribeAssets = oldSelectedAssets.filter(
        (a) =>
          newSelectedAssets.find((na) => na.assetId === a.assetId) === undefined
      );
      const subscribeAssets = newSelectedAssets.filter(
        (a) =>
          oldSelectedAssets.find((na) => na.assetId === a.assetId) === undefined
      );
      unsubscribeAssets.map((a) => {
        const children = selectChildrenFlatAssets(getState() as RootState, [a]);
        children &&
          children.map((c) => {
            const oldAssetDefinition = selectMqttDefinitionsByAssetId(
              getState() as RootState,
              c.id
            );
            oldAssetDefinition?.topics.forEach((t) => {
              t.errors === undefined &&
                mqttService.unsubscribe(
                  env.REACT_APP_MQTT_PREFIX +
                    "/" +
                    oldAssetDefinition.assetId +
                    t.value
                );
            });
          });
      });

      subscribeAssets.map((a) => {
        const children = selectChildrenFlatAssets(getState() as RootState, [a]);
        children &&
          children.map((c) => {
            const newAssetDefinition = selectMqttDefinitionsByAssetId(
              getState() as RootState,
              c.id
            );
            newAssetDefinition?.topics.forEach((t) => {
              t.errors === undefined &&
                mqttService.subscribe(
                  env.REACT_APP_MQTT_PREFIX +
                    "/" +
                    newAssetDefinition.assetId +
                    t.value
                );
            });
          });
      });
    }
    return next(action);
  };

export default mqttMiddleware;
